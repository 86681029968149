import { API } from './jsonpi';
import { Zarrpi } from './zarrpi';

let currentApi: API | Zarrpi;

export const init = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
}) => {
  if (!currentApi) {
    currentApi = new API(args);
  }
  return currentApi;
};

export const initZarrApi = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
}) => {
  if (!currentApi) {
    currentApi = new Zarrpi(args);
  }
  return currentApi;
};

export const getCurrentApi = (): API | Zarrpi => {
  if (!currentApi) {
    // eslint-disable-next-line no-throw-literal
    throw 'api not initialized';
  }
  return currentApi;
};
