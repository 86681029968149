/* eslint-disable func-names */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import { WaterlevelTable } from './WaterlevelTable';
import style from './ki-ww-start.css?inline';
import nls from '../../locales';

@customElement('ki-ww-start')
export default class KiWwStart extends Mix(LitElement, [i18nMixin, { nls }]) {
  // language=CSS
  static styles = style;

  @property({ type: String })
  htmlPath = './html';

  // Waterlevel LayerName for Table
  @property({ type: String })
  layerName;

  // HTML-File containing announcement content (do not provide file ending)
  @property({ type: String })
  announcementFilename;

  @property({ type: Object })
  waterlevelTable: WaterlevelTable = new WaterlevelTable();

  render() {
    return html`
      <div class="box">
        <fieldset>
          <legend>Water Level</legend>
          <div>
            <h3>Situation Report</h3>
            <table class="kennzahlen-table">
              <tr>
                <th>Alarm</th>
                <th class="val">Count</th>
              </tr>
              <tr title=${this.getStationNames(this.waterlevelTable.Alarm3)}>
                <td>Above Alarm Level 3</td>
                <td>${this.waterlevelTable.Alarm3.length}</td>
              </tr>
              <tr title=${this.getStationNames(this.waterlevelTable.Alarm2)}>
                <td>Above Alarm Level 2</td>
                <td>${this.waterlevelTable.Alarm2.length}</td>
              </tr>
              <tr title=${this.getStationNames(this.waterlevelTable.Alarm1)}>
                <td>Above Alarm Level 1</td>
                <td>${this.waterlevelTable.Alarm1.length}</td>
              </tr>
              <tr title=${this.getStationNames(this.waterlevelTable.NoAlarm)}>
                <td>No Alarm Level</td>
                <td>${this.waterlevelTable.NoAlarm.length}</td>
              </tr>
              <tr
                title=${this.getStationNames(this.waterlevelTable.Unclassified)}
              >
                <td>Unclassified Stations</td>
                <td>${this.waterlevelTable.Unclassified.length}</td>
              </tr>
            </table>
          </div>
          <p class="maplink">
            <a href="./#/overview/${this.layerName}">Show on map</a>
          </p>
        </fieldset>
      </div>

      <div class="box">
        <fieldset>
          <legend>Announcements</legend>
          <div style="height: 500px;">
            <iframe
              title="AdditionalInfoIframe"
              frameborder="0"
              height="100%"
              width="100%"
              src="${this.htmlPath}/${this.announcementFilename}.html"
            ></iframe>
          </div>
        </fieldset>
      </div>
    `;
  }

  onAfterEnter(location) {
    this.api = getCurrentApi();
    const params = getRouteOptionsAndParams(location, ['layerName']);
    this.layerName = params.options?.layerName;
    this.announcementFilename = params.options?.announcementFilename;
    this.fetchData(this.layerName);
  }

  async fetchData(layerName) {
    const layers = await this.api.getLayers();
    this.waterlevelTable = new WaterlevelTable();
    this.waterlevelTable.Stations = await this.api.getLayerData(
      layers[layerName],
    );
    this.waterlevelTable.Stations.forEach(e => {
      // Check if Station has AlarmLevels
      if (e.Alarm_Level_1 === '')
        this.waterlevelTable.Unclassified.push(e.station_name);
      else if (this.isSmaller(e.ts_value, e.Alarm_Level_1))
        this.waterlevelTable.NoAlarm.push(e.station_name);
      else if (this.isSmaller(e.ts_value, e.Alarm_Level_2))
        this.waterlevelTable.Alarm1.push(e.station_name);
      else if (this.isSmaller(e.ts_value, e.Alarm_Level_3))
        this.waterlevelTable.Alarm2.push(e.station_name);
      else if (this.isSmaller(e.Alarm_Level_3, e.ts_value))
        this.waterlevelTable.Alarm3.push(e.station_name);
    });

    // console.log(this.waterlevelTable);

    this.requestUpdate();
  }

  /** 
   * Compare two strings |
   true: a < b |
   false: a > b or if any parameter is undefined (e.g. station does not habve "Alarm_Level_*")
   */
  isSmaller(a: string | undefined, b: string | undefined) {
    if (a && b) return Number(a) < Number(b);
    console.warn('Undefined parameters');
    return false;
  }

  getStationNames(stationList) {
    const showMax = 8;
    let result = '';
    stationList.slice(0, showMax).forEach(s => {
      result += `${s}, `;
    });
    // Remove trailing comma
    if (stationList.length < showMax)
      result = result.substring(0, result.length - 2);
    else result += ' ...';
    return result;
  }
}
