/* eslint-disable func-names */
import { debounce as ldb } from 'lodash-es';
import getPropertyDescriptor from './helper/getPropertyDescriptor';

// TODO should bind with this;

export const debounce = function (delay = 0) {
  return (_target, _key, descriptor) => {
    const fn = descriptor.value;
    descriptor.value = ldb(fn, delay);
    return descriptor;
  };
};

// eslint-disable-next-line default-param-last
export const debounceMixin = function (targetProperty, delay = 0, clz) {
  return class extends clz {
    constructor() {
      super();

      const [, propertyDescriptor] = getPropertyDescriptor(
        this,
        targetProperty,
      );
      debounce(delay)(this, targetProperty, propertyDescriptor);
    }
  };
};
