import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement } from 'lit/decorators.js';

import Mix from '../../common/Mix';
import { responsiveMixin } from '../../decorators/responsive';
// TODO consider use sub element?

// @ts-expect-error
@customElement('ki-switcher')
export default class KiSwitcher extends Mix(LitElement, responsiveMixin) {
  static styles = [
    css`
      :host {
        display: flex;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        font-size: 0;
        overflow: visible;
      }
      .tab {
        background: white;
        color: var(--theme-text-color-primary, #1d1d1b);
        fill: var(--theme-text-color-primary, #1d1d1b);
        display: flex;
        padding: 8px 10px;
        min-width: 40px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        position: relative;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        justify-content: center;
      }

      ki-icon {
        margin-right: 5px;
        font-size: 1.3em;
      }
      .tab.selected {
        color: #ffffff;
        fill: #ffffff;
        background: var(--theme-color-primary, #0056a0);
      }

      :host.round .tab.last {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
      }
      :host(.round).tab.first {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
      }
      :host(.sm-screen) span.label {
        display: none;
      }
      :host(.sm-screen) ki-icon {
        font-size: 1.5em;
        margin-right: 0;
      }

      :host(.sm-screen) .tab {
        min-width: initial;
        padding: 9px 10px;
      }
      .badgesContainer {
        position: absolute;
        top: 0;
        display: flex;
        transform: translateY(130%);
        right: 0;
      }
      .badge {
        display: block;
        position: relative;
        outline: none;
        z-index: 999;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        color: white;
        padding: 0 10px;
        border-radius: 30px;
        font-size: 0.8em;
      }
    `,
  ];

  static get properties() {
    return {
      options: { type: Array },
      value: { type: String },
    };
  }

  get selected() {
    const preselected = this.options.filter(opt => opt.selected);
    return preselected.length ? preselected[0].value : this.options[0].value;
  }

  render() {
    this.value = this.value || this.selected;
    // language=html
    return html`
      ${this.options.map(
        (option, i) => html`
          <div
            id="switcher_item_${option.value}"
            class="tab ${option.value === this.value ? 'selected' : ''} ${i ===
            0
              ? 'first'
              : ''} ${this.options.length - 1 === i ? 'last' : ''}"
            tabindex="0"
            part="tab${option.value === this.value ? ' selected' : ''}"
            alt="View in ${option.value}"
            @keydown="${e => {
              e.key === 'Enter' && this.switchTo(option.value);
            }}"
            @click="${() => {
              this.switchTo(option.value);
            }}"
          >
            <div class="badgesContainer">
              ${option?.badges?.map(
                badge =>
                  html`<div
                    title="${badge.label || ''}"
                    class="badge"
                    style="background: ${badge.bgcolor ||
                    'white'};color: ${badge.color}"
                  >
                    ${badge.count}
                  </div>`,
              )}
            </div>
            ${option.icon
              ? html`<ki-icon icon="${option.icon}"></ki-icon>`
              : ''}
            <span class="label">${option.label}</span>
          </div>
        `,
      )}
    `;
  }

  switchTo(value) {
    if (this.value !== value) {
      this.value = value;
      this.dispatchEvent(new CustomEvent('changed', { detail: { value } }));
    }
  }
}
