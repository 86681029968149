/* eslint-disable func-names */
import { EventEmitter } from 'events';
import { debounce } from 'lodash-es/function';
import aspect from '../common/aspect';

export const SM = 'sm-screen';
export const MD = 'md-screen';
export const LG = 'lg-screen';
export const LANDSCAPE = 'landscape';

const emitter = new EventEmitter();
const XSM_BREAKPOINT = 800;
const MD_BREAKPOINT = 1280;
export const ViewPort = {
  size: LG,
  landscape: true,
};

let detect = function () {
  // console.log("responsive detection")
  let _newSize;
  let hasChaged = false;
  const w = window.innerWidth;
  const h = window.innerHeight;
  if (
    w <= XSM_BREAKPOINT ||
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    _newSize = SM;
  } else if (w <= MD_BREAKPOINT) {
    _newSize = MD;
  } else {
    _newSize = LG;
  }
  const _newLandscape = w > h;

  if (_newSize !== ViewPort.size) {
    document.body.classList.remove(SM, MD, LG);
    document.body.classList.add(_newSize);
    ViewPort.size = _newSize;
    hasChaged = true;
  }
  if (ViewPort.landscape !== _newLandscape) {
    document.body.classList.toggle(LANDSCAPE, _newLandscape);
    ViewPort.landscape = _newLandscape;
    hasChaged = true;
  }
  if (hasChaged) {
    emitter.emit('changed');
  }
};

detect = debounce(detect, 50);

window.addEventListener('resize', detect);
window.addEventListener('orientationchange', detect);
window.addEventListener('DOMContentLoaded', detect);

function connectedCallbackForTarget(target) {
  target.$resizeHandler = () => {
    if (target.isConnected) {
      // console.log("responsive change sizemode", target)
      target.classList.remove(SM, MD, LG);
      target.classList.add(ViewPort.size);
      target.classList.toggle('LANDSCAPE', ViewPort.landscape);
      target.requestUpdate();
    } else {
      // console.log("responsive change sizemod for disconnceted", target)
    }
  };
  emitter.on('changed', target.$resizeHandler);
  target.$resizeHandler();
}

function disconnectedCallbackForTarget(target) {
  emitter.removeListener('changed', target.$resizeHandler);
}

export const responsive = function (target) {
  // console.log("responsive decorator for", target)
  // console.log("responsive wrapped connectedCallback")
  aspect(
    target.prototype,
    'connectedCallback',
    origin =>
      function () {
        // @ts-expect-error
        connectedCallbackForTarget(this);
        // @ts-expect-error
        origin.apply(this);
      },
  );

  aspect(
    target.prototype,
    'disconnectedCallback',
    origin =>
      function () {
        // @ts-expect-error
        disconnectedCallbackForTarget(this);
        // @ts-expect-error
        origin.apply(this);
      },
  );
};

export const responsiveMixin = function (clz) {
  return class extends clz {
    connectedCallback() {
      connectedCallbackForTarget(this);
      if (super.connectedCallback) super.connectedCallback();
    }

    disconnectedCallback() {
      disconnectedCallbackForTarget(this);
      if (super.disconnectedCallback) super.disconnectedCallback();
    }
  };
};
