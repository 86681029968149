/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { customElement } from 'lit/decorators.js';
import {
  template,
  deUmlaut,
  Mix,
  downloadFile,
  copyTextToClipboard,
} from '@kisters/wcp-base/common';

import dayjs from '@kisters/wcp-base/common/dayjsext';
import { first, keyBy, last } from 'lodash-es';
import { responsiveMixin, i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import nls from '@kisters/wcp-water/locales';

import '@ui5/webcomponents/dist/Toast';

import { downloadExcel } from '../../common/download';

@customElement('wwp-station-download')
export default class WwpStationDownload extends Mix(
  LitElement,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    }

    .content {
      margin: 15pt;
      height: auto;
    }

    :host(.sm-screen) .content,
    :host(.md-screen) .content {
      width: 100%;
      margin: 15pt 0;
      display: table;
    }

    .cellheader {
      hyphens: auto;
      text-align: center;
    }

    .cell.iconcell {
      text-align: center;
      white-space: nowrap;
    }

    .content {
      padding: 2%;
    }

    .dltable {
      display: table;
      width: 100%;
      table-layout: fixed;
      max-width: 800px;
    }
    .header {
      padding: 10px;
    }
    .row {
      display: table-row;
    }
    .cell {
      display: table-cell;
      padding: 10px 2%;
      vertical-align: middle;
      hyphens: auto;
      overflow-x: hidden;
      word-wrap: break-word;
    }
    .innerCell {
      display: inline;
      font-size: 1.5em;
    }

    .label,
    ki-icon {
      fill: gray;
      padding: 5px;
    }
    ki-icon:hover {
      cursor: pointer;
    }
    .descr {
      font-weight: 900;
      padding: 30px 10px;
    }

    .hidden {
      display: none;
    }
  `;

  static get properties() {
    return {
      station: { type: Object },
    };
  }

  _showUTCDates = false;

  csvSeparator = '\t';

  dataColumn = ['Timestamp', 'Value', 'Quality Code Name'];

  dataColumnLabels = ['Zeitpunkt', 'Messwert', 'Status'];

  dataColumnLabelsAggr = [
    'Tag',
    'Tages Minimum',
    'Tages Mittel',
    'Tages Maximum',
    'Status',
  ];

  api = getCurrentApi();

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.station = await this.api.getStation(params.stationId);
    Object.assign(this, params.options);
  }

  getFile(
    filename: string,
    param: string,
    mimeType: string = 'application/json',
  ) {
    return this.station.timeseries.filter(
      item =>
        item.station_parameter === param &&
        item.href.includes(filename) &&
        item.mime_type === mimeType,
    );
  }

  getParameter(param) {
    return this.parameterMapping[param] || param;
  }

  getPeriod(period, resolution = null) {
    if (!period) {
      return resolution;
    }
    let ret = dayjs.duration(period).humanize();
    if (period === 'PoR') {
      ret = this.i18n.t('complete');
    }
    if (resolution) {
      ret += ` ${this.i18n.t('as')} ${resolution}`;
    }
    // Return with Capital letter
    return ret.charAt(0).toUpperCase() + ret.slice(1);
  }

  render() {
    return this.station
      ? html`<div class="content">
            <div class="descr">${unsafeHTML(this.descr)}</div>
            <div class="dltable">
              ${this._renderHeader()}
              ${this.periods.map(per => html`${this._renderRow(per)}`)}
            </div>
            ${this.additionalDownloads()}
          </div>
          <ui5-toast id="wcToastShort"
            >${this.i18n.t('copytoclipboard')}</ui5-toast
          >`
      : html`<div>Loading...</div>`;
  }

  // eslint-disable-next-line class-methods-use-this
  additionalDownloads() {
    return html``;
  }

  _renderHeader() {
    return html` <div class="row header">
      <div class="cell cellheader label"></div>
      ${this.params
        .filter(item =>
          this.station.timeseries.some(
            ts => ts.station_parameter === item.parameter,
          ),
        )
        .map(
          field =>
            html`<div class="cell cellheader label">${field.label}</div>`,
        )}
    </div>`;
  }

  _checkVisibility(per, parameter) {
    const list =
      per.type === 'file' ? this.station.zips : this.station.timeseries;
    const token = per.file[parameter] || per.file;

    if (Array.isArray(token)) {
      return list.some(
        ts =>
          ts.station_parameter === parameter &&
          token.some(t => ts.href.endsWith(t)),
      );
    }
    return list.some(
      ts => ts.station_parameter === parameter && ts.href.endsWith(token),
    );
  }

  _getCSVDownloadIcon(per, field) {
    const format = 'csv';
    if (per.type !== 'file') {
      return html`<div class="innerCell">
        <ki-icon
          style="fill:var(--theme-color-primary);display:${this._checkVisibility(
            per,
            field.parameter,
          )
            ? 'inital'
            : 'none'}"
          title="${this.i18n.t('downloadFile')}"
          icon="ki ki-file-csv"
          .fileformat="${'csv'}"
          .value="${field}"
          .period="${per}"
          @click="${this.download}"
        ></ki-icon>
      </div>`;
    }
    const filehref = this.getDownloadFileHref(
      per.file,
      field.parameter,
      format,
    );
    if (!filehref) {
      return html``;
    }
    return html`<div class="innerCell">
        <a href="${filehref}">
          <ki-icon
            title="${this.i18n.t('downloadFile')}"
            style="fill:var(--theme-color-primary);"
            icon="ki ki-file-csv"
            .fileformat="${'csv'}"
            .value="${field}"
            .period="${per}"
          ></ki-icon
        ></a>
      </div>
      <div class="innerCell">
        <ki-icon
          style="fill:var(--theme-color-primary);display:${this._checkVisibility(
            per,
            field.parameter,
          )
            ? 'inital'
            : 'none'}"
          icon="ki ki-link"
          title="Copy link to clipboard"
          .fileformat="${'csv'}"
          .value="${field}"
          .period="${per}"
          @click="${() => {
            const link = document.createElement('a');
            link.href = filehref;
            copyTextToClipboard(
              `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`,
            );
            this.renderRoot.querySelector('#wcToastShort').show();
          }}"
        ></ki-icon>
      </div>`;
  }

  _renderRow(per) {
    return html` <div class="row">
      <div class="cell">${this.getPeriod(per.period, per.resolution)}</div>
      ${this.params
        .filter(item =>
          this.station.timeseries.some(
            ts => ts.station_parameter === item.parameter,
          ),
        )
        .map(
          field => html`<div class="cell iconcell">
            ${this._getCSVDownloadIcon(per, field)}
            <div class="innerCell">
              <ki-icon
                title="${this.i18n.t('downloadFile')}"
                style="fill:green;display:${per.type !== 'file' &&
                this._checkVisibility(per, field.parameter)
                  ? 'inital'
                  : 'none'}"
                icon="ki ki-file-excel"
                .fileformat="${'xls'}"
                .value="${field}"
                .period="${per}"
                @click="${this.download}"
              ></ki-icon>
            </div>
          </div>`,
        )}
    </div>`;
  }

  _getAggrData(data, minDate) {
    let minTs;
    let meanTs;
    let maxTs;
    data.forEach(ts => {
      if (ts.ts_shortname.includes('Min')) {
        minTs = keyBy(ts.data, o => o[0]);
      } else if (ts.ts_shortname.includes('Max')) {
        maxTs = keyBy(ts.data, o => o[0]);
      } else if (ts.ts_shortname.includes('Mean')) {
        meanTs = keyBy(ts.data, o => o[0]);
      }
    });

    const cols = data[0].columns.split(',');
    const releaseStatusIndex = cols.indexOf('Release State');
    const qualityCodeNameIndex = cols.indexOf('Quality Code');

    const rows = [];
    Object.keys(meanTs).forEach(k => {
      if (new Date(k).getTime() > minDate) {
        const row = [
          this._showUTCDates
            ? dayjs(k).utc().format('L')
            : dayjs(k).format('L'),
          minTs[k] ? this.numberFormatter.format(minTs[k][1]) : '',
          meanTs[k] ? this.numberFormatter.format(meanTs[k][1]) : '',
          maxTs[k] ? this.numberFormatter.format(maxTs[k][1]) : '',
        ];

        if (releaseStatusIndex !== -1) {
          const releaseState =
            this.releaseStates[meanTs[k][releaseStatusIndex]] ||
            this.releaseStates.default;
          row.push(releaseState);
        }

        if (qualityCodeNameIndex !== -1) {
          row.push(meanTs[k][qualityCodeNameIndex]);
        }

        rows.push(row);
      }
    });
    return rows;
  }

  _getData(data, minDate) {
    const cols = data[0].columns.split(',');

    const releaseStatusIndex = cols.indexOf('Release State');
    const qualityCodeNameIndex = cols.indexOf('Quality Code');

    const rows = [];
    console.time('start download parsing');
    data[0].data.forEach(item => {
      const datems = new Date(item[0]).getTime();
      if (datems > minDate) {
        const row = [
          this._showUTCDates
            ? dayjs(datems).utc().format('L LT')
            : dayjs(datems).format('L LT'),
          item[1] === null ? '' : this.numberFormatter.format(item[1]),
        ];

        if (releaseStatusIndex !== -1) {
          const releaseState =
            this.releaseStates[item[releaseStatusIndex]] ||
            this.releaseStates.default;
          row.push(releaseState);
        }

        if (qualityCodeNameIndex !== -1) {
          row.push(item[qualityCodeNameIndex]);
        }
        rows.push(row);
      }
    });
    console.timeEnd('start download parsing');
    return rows;
  }

  getDownloadFileHref(
    file: string | Array<string> | Object,
    parameter: string,
    format: 'csv' | 'xlsx' | 'xls',
  ): string {
    let dlfiles: Array<{
      href: string;
      mime_type: string;
      period_alias: string;
      purpose: Array<string>;
      station_parameter: string;
      type: string;
    }> = this.station.zips.filter(item => {
      if (item.station_parameter !== parameter) {
        return false;
      }
      const fileParam = file[parameter];
      if (fileParam) {
        return item.href.endsWith(file[parameter]);
      }
      if (Array.isArray(file)) {
        return file
          .map(f => item.href.endsWith(f))
          .reduce((prev, curr) => prev || curr);
      }
      return item.href.endsWith(file);
    });

    if (dlfiles.length > 1) {
      dlfiles = dlfiles.filter(file =>
        format === 'xls'
          ? file.href.endsWith('xls') || file.href.endsWith('xlsx')
          : file.href.endsWith(format),
      );
    }

    if (dlfiles.length < 1) {
      return null;
    }

    return this.api.getLink(`/${dlfiles[0].href}`);
  }

  async download(evt) {
    const format = evt.target.fileformat;
    const { period, file, type, resolution } = evt.target.period;
    const { parameter, label } = evt.target.value;

    const tsShortname =
      evt.target.period.ts_shortname || evt.target.value.ts_shortname;
    if (type === 'file') {
      const dlfile = this.getDownloadFileHref(file, parameter, format);
      if (dlfile) {
        window.open(dlfile);
      }
    }
    const datafile = first(
      typeof file === 'object'
        ? this.getFile(file[parameter], parameter)
        : this.getFile(file, parameter),
    );
    if (datafile && type !== 'file') {
      let tsdata = await this.api.getTsData(datafile.href);
      if (tsShortname && type !== 'agg') {
        tsdata = tsdata.filter(item => item.ts_shortname === tsShortname);
      }
      if (tsdata[0] && tsdata[0].data[0]) {
        const mdList = { ...this.station, ...tsdata[0] };
        mdList.creation = dayjs().tz().format('L LT');
        mdList.resolution = resolution;
        const minDate =
          period === 'PoR'
            ? -Infinity
            : dayjs().tz().subtract(dayjs.duration(period)).valueOf();
        const data =
          type === 'agg' && tsdata.length > 1
            ? this._getAggrData(tsdata, minDate)
            : this._getData(tsdata, minDate);
        // eslint-disable-next-line prefer-destructuring
        mdList.from = data[0]?.[0];
        mdList.to = last(data)?.[0];
        const filename = this.filename
          ? `${template(this.filename, mdList)}_${this.getPeriod(period)}`
          : `${dayjs().tz().format('L')}_${
              this.station.station_longname
            }_${label}_${this.getPeriod(period)}`;
        const mdfields = this.mdfields.map(item => [
          this.mdlabels[item] || this.i18n.t(`kiwis:${item}`),
          this.mdValues[item] || mdList[item],
        ]);

        const header =
          type === 'agg' && tsdata.length > 1
            ? [this.dataColumnLabelsAggr]
            : [this.dataColumnLabels];

        if (format === 'xls') {
          downloadExcel(
            {
              metadata: mdfields.concat(header),
              data,
            },
            {
              sheetName:
                type === 'agg' && tsdata.length > 1
                  ? this.i18n.t('dailydata')
                  : this.i18n.t('complete'),
              name: deUmlaut(filename),
            },
          );
        } else {
          let csvResult = mdfields
            .map(item => `#${item.join(': ')}`)
            .join('\r\n');
          csvResult += `\r\n${header
            .map(item => `${item.join(this.csvSeparator)}`)
            .join('\r\n')}`;
          csvResult += `\r\n${data
            .map(item => `${item.join(this.csvSeparator)}`)
            .join('\r\n')}`;
          downloadFile(csvResult, deUmlaut(filename));
        }
      }
    }
  }
}
