import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { first, last, orderBy } from 'lodash-es';
import { Mix, template } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCurrentApi } from '@kisters/wiski-web/api';
import Highcharts from 'highcharts';
import serieslabel from 'highcharts/modules/series-label';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { AttributeThreshold } from './AttributeThreshold';
import nls from '../../locales';

serieslabel(Highcharts);
@customElement('ki-ww-cross-section')
export default class KiWwCrossSection extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      height: 100%;
      overflow-y: auto;
      width: 100%;
      display: flex;
    }
    #chart-node {
      display: table-cell;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    #chart-wrapper {
      display: table-cell;
      position: relative;
      flex: 1;
    }
  `;

  @property({ attribute: false }) highcharts = Highcharts;

  attributeThresholds: Array<AttributeThreshold> = [];

  static get properties() {
    return {
      longTermValues: { type: Array, attribute: false },
      crossSection: { type: Object, attribute: false },
      weekData: { type: Object, attribute: false },
    };
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();

    this.longTermValues = [];
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );

    window.addEventListener('resize', () =>
      setTimeout(() => {
        this.chart && this.chart.reflow();
      }, 2200),
    );
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    Object.assign(this, params.options);

    this.station = await this.api.getStation(this.stationId);
    this.fetchData();
  }

  createChart() {
    this.chart = this.highcharts.chart(
      this.renderRoot.querySelector('#chart-node'),
      this.chartOptions(),
      this.chartCreated.bind(this),
    );
  }

  _formatNumber(val) {
    return val === undefined || val === null || val === '' || Number.isNaN(val)
      ? ''
      : this.numberFormatter.format(val);
  }

  // eslint-disable-next-line class-methods-use-this
  chartOptions() {
    const self = this;
    return {
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          label: {
            enabled: true,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: this.i18n.t('crossSectionView'),
      },
      tooltip: {
        enabled: true,
        valueSuffix: ' m',
        formatter(tooltip) {
          if (self.waterlevelUnit === 'cm') {
            return `${self.i18n.t('distance')} ${
              this.x
            } m<br><span style="color:${
              this.series.color
            }; font-size: 1.5em;">&#9679</span> ${
              this.series.name
            }:  <b>${parseInt(this.y * 100, 10)} cm </b>`;
          }
          return tooltip.defaultFormatter.call(this, tooltip);
        },
        headerFormat: `${self.i18n.t('distance')} {point.key} m<br>`,
        valueDecimals: 1,
      },
      yAxis: {
        title: {
          text: this.yAxisLabel || this.i18n.t('height'),
        },
      },
      legend: {
        title: {
          text: '',
        },
      },
      xAxis: {
        title: {
          text: `${this.i18n.t('distance')} [m]`,
        },
        labels: {
          formatter() {
            return `${self._formatNumber(this.value)}`;
          },
        },
      },
    };
  }

  // name, data, color, z, show
  addNewLineToChart(newSeries) {
    newSeries.type = 'line';
    newSeries.shortName = newSeries.label;
    newSeries.name = newSeries.template
      ? `${newSeries.ts_name} - ${template(
          newSeries.description,
          newSeries.station,
        )}`
      : `${newSeries.ts_name} - ${newSeries.description}`;
    newSeries.showInLegend = true;
    newSeries.label = {
      enabled: true,
      style: { color: '#000000' },
    };
    newSeries.visible = true; // Add all default visible ts here

    newSeries.data = [
      [0, newSeries.ts_value / 100],
      [last(this.chart.series[0].data).x, newSeries.ts_value / 100],
    ];
    newSeries.zIndex = 50;

    // Do not show data points
    newSeries.marker = {
      enabled: false,
      states: { hover: { enabled: false }, inactive: { opacity: 1 } },
    };
    // newSeries.states = {"hover": {"enabled": false, "opacity": 1}};

    this.chart.addSeries(newSeries);
  }

  addNewAreaToChart(name, data, color, z) {
    const newSeries = {};
    newSeries.type = 'area';
    newSeries.id = name;
    newSeries.name = name;
    newSeries.label = {
      enabled: false,
    };

    if (Array.isArray(data)) newSeries.data = data;
    else {
      // Span line over whole chart
      const x_max = last(this.chart.series[0].data).x;

      const level_data = [];
      level_data.push({ x: 0, y: data });
      level_data.push({ x: x_max, y: data });
      newSeries.data = level_data;
    }
    newSeries.color = color;
    newSeries.fillColor = color;
    newSeries.zIndex = z;
    newSeries.threshold = null;

    // Do not show data points
    newSeries.marker = {
      enabled: false,
      states: { hover: { enabled: false }, inactive: { opacity: 1 } },
    };
    // newSeries.states = {"hover": {"enabled": false, "opacity": 1}};

    this.chart.addSeries(newSeries);
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated() {
    if (this.chart) {
      this.chart.reflow();
    }
  }

  _fillLineData(level) {
    const crossSectionData = this.crossSection[0].polygon.point;
    return crossSectionData.map(dp => ({ x: dp.x, y: level }));
  }

  render() {
    return html`<div id="chart-wrapper">
      <div id="chart-node"></div>
    </div>`;
  }

  async fetchData() {
    // Get profile polygon
    const _files = {};
    this.crossSection = await this.api.getTsData(
      `internet/stations/${this.station.site_no}/${this.station.station_no}/cross_section/cross_section.json`,
    );

    // Get waterlevel week.json
    await Promise.all(
      this.station.timeseries
        .filter(ts => ts.station_parameter === this.station_parameter)
        .map(async ts => {
          _files[last(ts.href.split('/'))] = await this.api.getTsData(ts.href);
        }),
    );
    this.weekData = first(_files['week.json'])?.data;

    this.createChart();
    const crossSectionData = first(this.crossSection)?.polygon?.point;
    // Plot profile
    this.addNewAreaToChart(
      this.i18n.t('terrain'),
      crossSectionData,
      'rgba(164, 100, 30, 1)',
      15,
    );

    // Add current waterlevel
    const lastlevel = last(this.weekData)[1]; // Access latest data point
    this.addNewAreaToChart(
      this.i18n.t('currentWaterlevel'),
      this._fillLineData(lastlevel / 100),
      'rgba(10, 67, 119, 0.5)',
      3,
    ); // cm -> m

    // Extract additional timeseries which should be display
    if (this.attributeThresholds) {
      const stationObj = first(_files['week.json']);
      this.attributeThresholds.forEach(at => {
        if (Object.keys(stationObj).includes(at.field)) {
          this.longTermValues.push({
            station: stationObj,
            ts_name: at.field,
            ts_value: parseFloat(stationObj[at.field]),
            ...at,
          });
        }
      });
    }

    // Add additional timeseries
    orderBy(this.longTermValues, 'ts_value', ['desc']).forEach(i => {
      this.addNewLineToChart(i); // cm -> m
    });
  }
}
