/* eslint-disable max-classes-per-file */
import { Mix, template } from '@kisters/wcp-base/common';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { WwpStationInfo } from '@kisters/wiski-web/views';
import { customElement } from 'lit/decorators.js';

@customElement('wwp-station-info-albg')
export default class WwpStationInfoAlbg extends Mix(WwpStationInfo) {
  getValue(field) {
    let ret;
    const val = this.station[field.field];
    if (!val) {
      ret = '-';
    } else if (field.type === 'number') {
      ret = new Intl.NumberFormat(
        this.i18n.language,
        field.numberFormat || this.numberFormat || {},
      ).format(parseFloat(val));
    } else if (field.type === 'textnumber') {
      ret = new Intl.NumberFormat(
        this.i18n.language,
        field.numberFormat || this.numberFormat || {},
      ).format(parseFloat(val.replace(',', '.')));
    } else if (field.type === 'html') {
      ret = unsafeHTML(
        this.station[field.field].replace('./html', './public/html'),
      );
    } else if (field.type === 'ATS') {
      ret = `${this.station[field.ATS[0]]}-${this.station[field.ATS[1]]}-${
        this.station[field.ATS[2]]
      }-${this.station[field.ATS[3]]}-${this.station[field.ATS[4]]}`;
    } else if (field.type === 'link') {
      ret = unsafeHTML(
        `<a href="https://environment.extranet.gov.ab.ca/apps/GIC/Report/ViewReport.aspx?wellid=${
          this.station[field.field]
        }">${this.station[field.field]}</a>`,
      );
    } else {
      ret = this.station[field.field];
    }

    if (ret !== '-' && field.translate) {
      ret = this.i18n.t(ret);
    }

    if (ret !== '-' && field.subst) {
      if (Array.isArray(field.subst)) {
        let tmpret = val;
        field.subst.forEach(item => {
          tmpret = tmpret.replace(item.token, item.with);
        });
        ret = tmpret;
      } else {
        ret = val.replace(field.subst.token, field.subst.with);
      }
    }
    if (ret !== '-' && field.suffix) {
      ret = template(`${ret}${field.suffix}`, this.station);
    }
    return ret;
  }
}
