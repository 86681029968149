import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('ww-mobile-tabs')
export class MobileFromToTabs extends LitElement {
  static styles = [
    css`
      :host {
        flex: 1;
        display: flex;
        flex-direction: row;
      }

      .tabSection {
        display: none;
      }

      /* Media queries */
      @media (max-width: 1024px) {
        .tabSection {
          display: flex;
          height: 40px;
          width: 100%;
        }
        .tabSection .tab {
          display: flex;
          flex: 1;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .tabSection .tab.tab-selected {
          border-bottom: 4px solid #1093cd;
        }
      }
    `,
  ];

  @property({ type: String })
  selectedTab: 'from' | 'to' | null = null;

  @property({ type: String })
  fromValue: string = '';

  @property({ type: String })
  toValue: string = '';

  protected render(): TemplateResult {
    return html`
      <div class="tabSection">
        <div
          class="tab from-tab${this.selectedTab === 'from'
            ? ' tab-selected'
            : ''}"
          @click=${this.tabClicked('from-clicked')}
        >
          ${this.fromValue}
        </div>
        <div
          class="tab to-tab${this.selectedTab === 'to' ? ' tab-selected' : ''}"
          @click=${this.tabClicked('to-clicked')}
        >
          ${this.toValue}
        </div>
      </div>
    `;
  }

  tabClicked(eventName: string) {
    return (e: Event) => {
      e.stopPropagation();
      this.dispatchEvent(new Event(eventName));
    };
  }
}
