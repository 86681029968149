import deCommon from './de/common.json';
import deKiwis from './de/kiwis.json';
import enCommon from './en/common.json';
import enKiwis from './en/kiwis.json';
import isCommon from './is/common.json';
import isKiwis from './is/kiwis.json';
import frCommon from './fr/common.json';
import frKiwis from './fr/kiwis.json';
import zhCommon from './zh/common.json';

export default {
  de: {
    common: deCommon,
    kiwis: deKiwis,
  },
  en: {
    common: enCommon,
    kiwis: enKiwis,
  },
  is: {
    common: isCommon,
    kiwis: isKiwis,
  },
  fr: {
    common: frCommon,
    kiwis: frKiwis,
  },
  zh: {
    common: zhCommon,
  },
};
