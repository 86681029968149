import { css, html } from 'lit';
import { customElement, queryAsync, query, property } from 'lit/decorators.js';
import WwpOverview from '@kisters/wiski-web/views/wwp-overview/wwp-overview';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import '../../components/ki-ww-raster/ww-raster-player';

@customElement('ww-raster-overview')
export default class WwRasterOverview extends WwpOverview {
  @property({ type: Boolean })
  playeropen = false;

  @property({ type: Boolean })
  timelineropen = false;

  @property({ type: String })
  selectedView: 'map' | 'table' | 'overview' | 'multigraph' = 'map';

  @queryAsync('#map')
  map;

  @query('#comparemap')
  comparemap;

  @query('#rasterplayer')
  rasterplayer;

  @query('#map')
  _map;

  @property({ type: Boolean })
  showCompareMap = false;

  layersTreeList = true;

  static styles = [
    WwpOverview.styles,
    css`
      ww-raster-player {
        position: absolute;
        right: 20px;
        height: 80px;
        max-width: 80%;
        z-index: 111;
        bottom: 0;
      }

      ww-raster-player.open {
        left: 20%;
      }

      :host(.sm-screen) ww-raster-player {
        display: none !important;
      }

      ki-station-map.map {
        width: auto;
        flex: 1;
      }

      #comparemap {
        border-right: 2px solid gray;
      }

      ki-stack > div.map-container {
        display: flex;
      }
    `,
  ];

  updated(changedProperties) {
    if (this._map && changedProperties.has('showCompareMap')) {
      this._map.resize();
      this.rasterplayer.comparemap = this.comparemap;
      setTimeout(() => {
        this.rasterplayer.createRasterLayer();
        this.rasterplayer._renderNext();
      }, 200);
    }
  }

  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('table', this.selectedView === 'table');
    // TODO rename ki-station-layer to ki-maker-layer or value layer ?
    // language=html
    if (this.currentLayer !== this.layerName) {
      this.getDataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        ${this.additionalContent()}

        <ki-searchbox
          placeholder="${this.i18n.t('stationsearch')}"
          .value="${this.searchContent}"
          @search="${e => {
            this._enableAutoZoom = true;
            this.searchContent = e.detail.value;
          }}"
        >
        </ki-searchbox>
        ${this.showCsvExport && this.selectedView === 'table'
          ? this.renderExportButton()
          : html``}
        <ki-wcp-wwp-filter
          .favorites="${this.__favouriteStationIds}"
          .activeFilter="${this.filterContent}"
          .allStations="${this._dataLayer.data}"
          .stations="${this.filteredStations}"
          .filters="${this.quickFilters}"
          @change="${e => {
            this._enableAutoZoom = true;
            this.filterContent = JSON.stringify(e.detail.value);
          }}"
        >
        </ki-wcp-wwp-filter>
        <ki-switcher
          id="map-table-switcher"
          .options="${this._switcherOptions}"
          .value="${this.selectedView}"
          @changed="${e => {
            this._enableAutoZoom = true;
            this.selectedView = e.detail.value;
          }}"
        ></ki-switcher>
        <ki-stack class="slide-left" .selected="${this.selectedView}">
          <div class="station-list" key="table">
            ${this.selectedView === 'table' ? this.renderTable() : html``}
          </div>
          <div class="map-container" key="map">
            ${this.selectedView === 'map' ? this.renderMap() : html``}
          </div>
        </ki-stack>
        ${this.showCompareMap
          ? html``
          : html`<ki-legend
              closeable
              .options="${this.legendOptions}"
              @changed="${this.legendChanged}"
              id="overview-legend"
            ></ki-legend>`}

        <div class="lastUpdate" title="Version: ${this.version}">
          ${this.i18n.t('lastupdated')}:
          ${dayjs.tz(this._dataLayer.creationDateInMillis).format('L LT')}
          ${this.i18n.exists(encodeURIComponent(dayjs().tz().format('zzz')))
            ? this.i18n.t(encodeURIComponent(dayjs().tz().format('zzz')))
            : dayjs().tz().format('zzz')}
        </div>
      `
    );
  }

  renderMap() {
    return html`
      <ki-station-map
        id="map"
        class="map"
        .view="${this._view}"
        .layers="${this.mapConfig?.layers || []}"
        persistancetimeout="3600"
        @extentchange=${e => {
          if (this.comparemap) {
            this.comparemap.zoomTo(e.detail.extent, 0, 0);
          }
        }}
        cachePrefix="${this.cachePrefix}"
      >
        ${this._dataLayer.config.popupControl
          ? html`<ki-popup-control></ki-popup-control>`
          : html``}
        ${this.layerName === 'Rainfall'
          ? html`<ki-precip-analysis
              .classification="${this._dataLayer.config.classification}"
              ?disabled="${this.playeropen}"
              @change="${e => {
                this.timelineropen = !e.detail.active;
                this.refreshTimer = e.detail.active;
              }}"
            ></ki-precip-analysis>`
          : ''}
        ${this._dataLayer.config?.timeActive
          ? html`<ki-time-control
              ?disabled="${this.playeropen}"
              @change="${e => {
                this.timelineropen = !e.detail.active;
                this.refreshTimer = e.detail.active;
              }}"
            ></ki-time-control>`
          : ''}
        <ki-stations-layer
          ?displayStationLabelsOnStart="${this._dataLayer.config
            ?.displayStationLabelsOnStart}"
          .displayInLayerControl="${this.displayStationsInLayerControl}"
          .infoTag="${this.popup}"
          .tagMarkers="${this.legendFactory.markers}"
          .layerAlias="${this.layerName}"
          .labelPositions="${this._dataLayer.config?.labelPositions}"
          .labelTemplate="${this._dataLayer.config?.labelTemplate}"
          .trendKey=${this._dataLayer.config?.trendKey}
          .tagSelectedMarkers="${this.legendFactory.selectedMarkers}"
          .config="${this._dataLayer.config}"
          .stations="${this.filteredStations}"
          .dynamicMarkerSize="${this._dataLayer.config?.dynamicMarkerSize}"
          ?noDeclutter="${this._dataLayer.config?.noDeclutter}"
          ?timeActive="${this._dataLayer.config?.timeActive}"
          @station-click="${this.showStation}"
          @sourceChange="${e => {
            if (this._enableAutoZoom || this._firstWWVisit !== 'no') {
              this.stationMap.zoomTo(
                e.detail.extent,
                this._enableAutoZoom ? 500 : 0,
              );
              this._firstWWVisit === 'no';
              sessionStorage.setItem('firstWWVisit', 'no');
            }
          }}"
        >
        </ki-stations-layer>
        <ki-layers-control
          .treeList="${this.layersTreeList}"
          .toggleAll="${this.layersToggleAll}"
          .context="${this.layerName}"
          .i18n=${this.i18n}
          .groups="${this.layerGroups}"
          cachePrefix="${this.cachePrefix}"
        ></ki-layers-control>
        <ki-station-map-zoom-control
          style="right:20px;left:auto;"
          label="map zoom"
          .homeExtent="${this.homeExtent}"
        ></ki-station-map-zoom-control>
        <ki-station-map-position-scale-control
          .projections="${this.positionProjections}"
          label="position and scale"
        ></ki-station-map-position-scale-control>
        <ki-station-map-overview-control
          label="overview"
          .layerOptions="${this.mapConfig?.overViewLayer || { layer: [] }}"
        ></ki-station-map-overview-control>
        <!--     <ki-station-map-raster-control></ki-station-map-raster-control> -->
      </ki-station-map>
      ${this.showCompareMap
        ? html`<ki-station-map
            style="display:${this.showCompareMap ? 'block' : 'none'}"
            id="comparemap"
            class="map"
            .view="${this._view}"
            .layers="${this.mapConfig?.compareMap || []}"
            persistancetimeout="0"
            cachePrefix="${this.cachePrefix}"
            @extentchange=${e => {
              this._map.zoomTo(e.detail.extent, 0, 0);
            }}
          ></ki-station-map>`
        : html``}
    `;
  }

  additionalContent() {
    return html`<ww-raster-player
      ?disabled="${this.timelineropen}"
      id="rasterplayer"
      class="${this.playeropen ? 'open' : ''}"
      style="display:${this.selectedView === 'table' ? 'none' : 'flex'}"
      .map=${this.map}
      .comparemap=${this.comparemap}
      @clearselection=${() => {
        this.showCompareMap = false;
      }}
      @opensecondary=${() => {
        this.showCompareMap = true;
      }}
      @playeropen=${e => {
        this.playeropen = e.detail.value;
      }}
    ></ww-raster-player>`;
  }
}
