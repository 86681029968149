import Control from 'ol/control/Control';
import { groupBy } from 'lodash-es/collection';
import { unByKey } from 'ol/Observable';
import { setWithExpiry, getWithExpiry } from '@kisters/wcp-base/common';

export default class LayersControl extends Control {
  constructor(options) {
    options.element = document.createElement('ki-layers-control-element');
    super(options);
    this.toggleAll = options.toggleAll;
    this.treeList = options.treeList;
    this.i18n = options.i18n;
    this.groups = options.groups;
    this.style = options.style;
    this.context = options.context;
    this.cachePrefix = options.cachePrefix;
    this._listenLanguageChange();
  }

  setMap(map) {
    super.setMap(map);
    if (map) {
      this._buildOptions();
      this.element.addEventListener('changed', e => {
        this._setLayersVisible(e.detail.values);
      });
      this.__layerChangeListenerKey = map
        .getLayers()
        .on('change:length', () => {
          this._buildOptions();
        });
    } else {
      unByKey(this.__layerChangeListenerKey);
    }
  }

  set style(style) {
    this.element.style = style;
  }

  // TODO
  _buildOptions() {
    const items = this._getItems();
    const layerGroups = groupBy(items, l => l.get('group'));
    this.element.toggleAll = this.toggleAll;
    this.element.treeList = this.treeList;
    this.element.layers = this.groups.map(k => {
      const groupName = typeof k === 'string' ? k : k.name;
      const ls = layerGroups[groupName] || [];
      return {
        label: this.i18n?.t(groupName) || groupName,
        type: k.type || 'checkbox',
        options: ls.map(l => ({
          label: this.i18n?.t(l.get('name')),
          value: l.get('name'),
          legendColor: l.get('legendColor'),
          visible: l.get('visible'),
        })),
      };
    });

    const values = getWithExpiry(
      `${this.cachePrefix}_LayersControl-visibleLayers`,
      true,
    );
    if (values) {
      this._setLayersVisible(values);
      this.element.values = values;
    } else {
      this.element.values = items
        .filter(l => l.getVisible())
        .map(l => l.get('name'));
    }
  }

  _setLayersVisible(values) {
    setWithExpiry(
      `${this.cachePrefix}_LayersControl-visibleLayers`,
      values,
      24 * 3600 * 1000,
      true,
    );
    this._getItems().forEach(item => {
      const name = item.get('name');
      const viewFilter = item.get('viewFilter');

      item.setVisible(values.indexOf(name) >= 0);
      if (item.linkedlayers) {
        item.linkedlayers.forEach(layer =>
          layer.setVisible(values.indexOf(name) >= 0),
        );
      }
      if (viewFilter) {
        item.setVisible(
          viewFilter.length && !viewFilter.includes(this.context),
        );
      }
    });
  }

  _getItems() {
    return [
      ...this.getMap().getLayers().getArray(),
      ...this.getMap().getControls().getArray(),
    ]
      .filter(l => {
        const viewFilter = l.get('viewFilter');
        if (
          viewFilter &&
          viewFilter.length &&
          !viewFilter.includes(this.context)
        ) {
          l.setVisible(false);
          return false;
        }
        if (!viewFilter) {
          return true;
        }
        l.setVisible(true);
        return true;
      })
      .filter(l => l.get('displayInLayerControl') || l.displayInLayerControl);
  }

  _listenLanguageChange() {
    if (this.i18n) {
      this.i18n.on('initialized', () => {
        this._buildOptions();
      });
      this.i18n.on('languageChanged', () => {
        this._buildOptions();
      });
    }
  }
}
