/* eslint-disable max-classes-per-file */
import { css, html } from 'lit';
import { findIndex } from 'lodash-es';
import { getQueryObject } from '../../decorators/query';
import { debounceMixin } from '../../decorators/debounce';
import { computedMixin } from '../../decorators/computed';

function _inlectLazyRendering(clz) {
  return class extends clz {
    // language=CSS
    static styles = css`
      .scroller {
        overflow: auto;
        height: 100%;
        overflow-anchor: none;
      }

      .cell {
        white-space: nowrap;
      }

      .scroller-content {
        box-sizing: border-box;
      }

      .scroller-content::after {
        display: block;
        padding: 10px;
        color: gray;
        text-align: center;
      }
    `;

    static get properties() {
      return {
        startIndex: {
          type: Number,
          default: 0,
        },
      };
    }

    rowHeight = 30;

    get renderingData() {
      return this.data.slice(
        this.startIndex,
        Math.min(this.data.length, this.__lastIndex),
      );
    }

    get __scroller() {
      return getQueryObject(this, '.scroller');
    }

    render() {
      // TODO
      // try to just render visible part.
      // language=html
      return html`
        <div class="scroller" @scroll="${this._onScroll}">
          <div
            class="scroller-content"
            style="height: ${this.data.length *
            this.rowHeight}px; padding-top: ${this.startIndex *
            this.rowHeight}px;"
          >
            ${super.render()}
          </div>
        </div>
      `;
    }

    get __lastIndex() {
      return (
        this.startIndex +
        Math.max(50, Math.round(this.offsetHeight / this.rowHeight))
      );
    }

    _onScroll() {
      const n = Math.round(this.__scroller.scrollTop / this.rowHeight);
      if (Math.abs(n - this.startIndex - 5) > 5) {
        this.startIndex = Math.min(n, this.data.length - 50);
        this.startIndex = Math.max(this.startIndex, 0);
      }
    }

    _resetScroll() {
      if (this.__scroller) {
        this.__scroller.scrollTop = 0;
      }
    }

    // scroll to the specific row with idproperty
    scrollTo(idProperty = '') {
      const counter =
        this.data.length &&
        findIndex(this.data, row => row[this.idproperty] === idProperty);
      if (counter !== -1 && this.__scroller) {
        this.__scroller.scrollTop = counter * this.rowHeight;
      }
    }
  };
}

export default function LazyRendering(clz) {
  return class extends debounceMixin(
    '_onScroll',
    100,
    computedMixin('__lastIndex', 'startIndex', {}, _inlectLazyRendering(clz)),
  ) {};
}
