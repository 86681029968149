import { css } from 'lit';
// language=CSS
export default css`
  :host {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    height: 45px;
  }

  .display-none {
    disblay: none;
  }

  /* START custom style datepickers */
  .qs-datepicker-container {
    display: flex;
    flex-direction: row;
    width: 580px;
    font-size: 1rem;
    font-family: sans-serif;
    color: #000;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    z-index: 9001;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #ccc;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .qs-datepicker-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .qs-datepicker-container.qs-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .qs-datepicker-container.qs-hidden {
    display: none;
  }

  .qs-datepicker,
  .qs-datepicker .qs-overlay {
    padding: 0.5em;
    border-left: 1px solid #ccc;
  }

  .qs-datepicker {
    width: 70%;
  }

  .qs-datepicker .qs-overlay {
    width: 100%;
  }

  .qs-datepicker .qs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    height: 100%;
    padding: 0.5em;
    z-index: 1;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .qs-datepicker .qs-overlay.qs-hidden {
    opacity: 0;
    z-index: -1;
  }

  .qs-datepicker .qs-overlay .qs-overlay-year {
    background: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    font-size: 0.875em;
    padding: 0.25em 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  .qs-datepicker .qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .qs-datepicker .qs-overlay .qs-close {
    padding: 0.5em;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .qs-datepicker .qs-overlay .qs-submit {
    border: 1px solid #fff;
    border-radius: 0.26392em;
    padding: 0.5em;
    margin: 0 auto auto;
    cursor: pointer;
    background: hsla(0, 0%, 50.2%, 0.4);
  }

  .qs-datepicker .qs-overlay .qs-submit.qs-disabled {
    color: grey;
    border-color: grey;
    cursor: not-allowed;
  }

  .qs-datepicker .qs-overlay .qs-overlay-month-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .qs-datepicker .qs-overlay .qs-overlay-month {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% / 3);
    cursor: pointer;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s;
    transition: opacity 0.15s;
  }

  .qs-datepicker .qs-overlay .qs-overlay-month.active,
  .qs-datepicker .qs-overlay .qs-overlay-month:hover {
    opacity: 1;
  }

  .qs-datepicker .qs-controls {
    height: 35px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transition: -webkit-filter 0.3s;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
  }

  .qs-datepicker .qs-controls.qs-blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  .qs-datepicker .qs-arrow {
    height: 1.7em;
    width: 1.7em;
    position: relative;
    cursor: pointer;
    border-radius: 0.26392em;
    -webkit-transition: background 0.15s;
    transition: background 0.15s;
  }

  .qs-datepicker .qs-arrow:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .qs-datepicker .qs-arrow:hover.qs-left:after {
    border-right-color: #000;
  }

  .qs-datepicker .qs-arrow:hover.qs-right:after {
    border-left-color: #000;
  }

  .qs-datepicker .qs-arrow:after {
    content: '';
    border: 0.39062em solid rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
  }

  .qs-datepicker .qs-arrow.qs-left:after {
    border-right-color: grey;
    right: 50%;
    -webkit-transform: translate(25%, -50%);
    -ms-transform: translate(25%, -50%);
    transform: translate(25%, -50%);
  }

  .qs-datepicker .qs-arrow.qs-right:after {
    border-left-color: grey;
    left: 50%;
    -webkit-transform: translate(-25%, -50%);
    -ms-transform: translate(-25%, -50%);
    transform: translate(-25%, -50%);
  }

  .qs-datepicker .qs-month-year {
    font-weight: 700;
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .qs-datepicker .qs-month-year:hover {
    border-bottom: 1px solid grey;
  }

  .qs-datepicker .qs-month-year:active:focus,
  .qs-datepicker .qs-month-year:focus {
    outline: none;
  }

  .qs-datepicker .qs-month {
    padding-right: 0.5ex;
  }

  .qs-datepicker .qs-year {
    padding-left: 0.5ex;
  }

  .qs-datepicker .qs-squares {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transition: -webkit-filter 0.3s;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
  }

  .qs-datepicker .qs-squares.qs-blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  .qs-datepicker .qs-square {
    margin-top: 0.7em;
    width: calc(100% / 7);
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: background 0.1s;
    transition: background 0.1s;
    border-radius: 50px;
  }

  .qs-datepicker .qs-square.qs-current {
    font-weight: 700;
  }

  .qs-datepicker .qs-square.qs-current span {
    text-decoration: underline;
  }

  .qs-datepicker .qs-square.qs-active {
    background: #1093cd;
    color: #fff;
  }

  .qs-datepicker .qs-square.qs-range-date-start:not(.qs-range-date-6) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .qs-datepicker .qs-square.qs-range-date-middle {
    background: #e5eef5;
  }

  .qs-datepicker .qs-square.qs-range-date-middle {
    border-radius: 0;
  }

  .qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .qs-datepicker .qs-square.qs-range-date-end:not(.qs-range-date-0),
  .qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-6 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .qs-datepicker .qs-square.qs-disabled span {
    opacity: 0.2;
  }

  .qs-datepicker .qs-square.qs-empty {
    cursor: default;
  }

  .qs-datepicker .qs-square.qs-disabled {
    cursor: not-allowed;
  }

  .qs-datepicker .qs-square.qs-day {
    cursor: default;
    color: grey;
  }

  .qs-datepicker
    .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    background: #69b0e7;
    color: #fff;
  }

  .qs-datepicker .qs-event {
    position: relative;
  }

  .qs-datepicker .qs-event:after {
    content: '';
    position: absolute;
    width: 0.46875em;
    height: 0.46875em;
    border-radius: 50%;
    background: #07f;
    bottom: 0;
    right: 0;
  }

  .qs-datepicker .qs-event.qs-disabled:after,
  .qs-datepicker .qs-event.qs-empty:after {
    background: #cce4ff;
  }

  /* END custom style datepickers */

  .input-group {
    display: flex;
    align-items: center;
  }

  input[type='text'],
  a.input-button {
    border: 1px solid #ccc;
    box-sizing: border-box;
    height: 36px;
  }

  input[type='text'] {
    width: 8em;
    padding: 0.5em;
    display: inline-block;
  }

  input#from,
  input#to {
    text-align: center;
    font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
  }

  input#from {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-right: 0;
  }

  input[type='text']:focus {
    background: #e8f3fb;
  }

  a.input-button {
    text-decoration: none;
    display: block;
    padding: 0 10px;
    border-left: 0;
    cursor: pointer;
    align-self: center;
    justify-content: center;
    line-height: 1;
  }

  a.input-button ki-icon-btn {
    border: 0;
    display: flex;
    box-shadow: none;
    height: 100%;
    width: auto;
    align-items: center;
  }

  ki-icon-btn {
    box-shadow: none;
  }

  ki-icon-btn.selected,
  ki-icon-btn:hover {
    box-sizing: border-box;
  }

  ki-icon-btn.selected,
  ki-icon-btn.selected:hover {
    fill: #ffffff;
    background: #1093cd;
  }

  ki-icon-btn:hover {
    fill: #4a4a49;
  }

  #periodListBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 50px 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ccc;
    border-left: 0;
    box-sizing: border-box;
    height: 36px;
  }

  #periodListSummary {
    display: none;
    border: 1px solid #9099a0;
    border-radius: 50px;
    color: #9099a0;
    cursor: pointer;
    text-align: center;
    padding: 0.5em 1em;
  }

  #periodListSummary:hover {
    color: #4a4a49;
    fill: #4a4a49;
  }

  #periodListSummary.selected {
    background-color: #3f85cc;
    color: #ffffff;
    fill: #ffffff;
    border: 1px solid #fff;
  }

  .period-list {
    display: block;
  }

  .mobile-tabs {
    display: none;
  }

  /* Media queries */
  @media (max-width: 1024px) {
    input[type='text'],
    a.input-button {
      width: 0;
      visibility: hidden;
    }

    .input-group {
      width: 0;
    }

    #periodListBtn {
      display: none;
    }

    #periodListSummary {
      display: block;
      white-space: nowrap;
    }

    .qs-datepicker-container {
      position: absolute;
      flex-direction: column;
      top: 50px !important;
      left: 0 !important;
      width: 400px;
      transform: translateX(-100px);
    }

    .qs-datepicker-container .qs-datepicker {
      width: 100%;
    }

    .qs-datepicker,
    .qs-datepicker .qs-overlay {
      width: 100%;
    }

    .period-list {
      display: none;
    }

    .mobile-tabs {
      display: block;
    }
  }
`;
