/* eslint-disable max-classes-per-file */
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import {
  KiApp,
  navigateTo,
  getSearchParamString,
  getCurrentPath,
  getNavItems,
  getLogoOptions,
} from '@kisters/wcp-base/components';
import {
  SM,
  ViewPort,
  responsiveMixin,
  i18nMixin,
} from '@kisters/wcp-base/decorators';
import { Mix } from '@kisters/wcp-base/common';
import style from './ki-app-wwp.css?inline';
import nls from '../../locales/index';
import '../ki-menu/ki-menu';

import '../wwp-station-ts-viewer/wwp-station-ts-viewer';
import '../../views/wwp-overview/wwp-overview';
import '../../views/wwp-overview/ww-raster-overview';
import '../../views/wwp-station-detail/wwp-station-detail';
import '../../views/wwp-station-detail/wwp-station-download';
import '../../views/wwp-html/wwp-html';
import '../../views/ww-report/ww-report';
import '../../views/ww-report/ww-documents';

import { getCurrentApi } from '../../api/API';
// import '@ui5/webcomponents/dist/Assets';
// import '@ui5/webcomponents-fiori/dist/Assets';
// import '@ui5/webcomponents-icons/dist/Assets';
// import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language.js';
// setLanguage('de'); // Set language directly in project app (!)

@customElement('ki-app-wwp')
export default class KiAppWWP extends Mix(KiApp, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  static styles = [KiApp.styles, style];

  constructor() {
    super();

    dayjs.locale(this.i18n.language.toLowerCase());
    this.basePath = './public';
    this.nls = `${this.basePath}/nls/route.json`;
    this.configSubPath = 'config';
  }

  static get properties() {
    return {
      basePath: { type: String },
      configSubPath: { type: String },
      _config: { type: Object },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get api() {
    return getCurrentApi();
  }

  // eslint-disable-next-line class-methods-use-this
  getNavigationItems() {
    return getNavItems();
  }

  _renderMenu() {
    const menuMode = ViewPort.size === SM ? 'vertical' : 'horizontal';
    // language=html
    return html`
      <ki-menu
        slot="nav"
        .direction="${menuMode}"
        @selected="${e => {
          const currentQuery = getSearchParamString();
          const currentViewType = getCurrentPath().replace(/[^/]+$/, '');
          const newViewType = e.detail.value.replace(/[^/]+$/, '');
          let newUrl = e.detail.value;
          if (currentViewType === newViewType) {
            newUrl = `${newUrl}${
              currentQuery.charAt(0) === '?' ? '' : '?'
            }${currentQuery}`;
          }
          navigateTo(newUrl);
        }}"
        .items="${this.getNavigationItems()}"
      ></ki-menu>
    `;
  }

  _renderDrawer() {
    return html`<div
      class="drawer-container ${this._sideBarVisible ? 'visible' : ''}"
      @click="${this.toggleSideBar}"
    >
      <div class="overlay"></div>
      <div class="drawer" style="max-width: ${this.drawerWidth}">
        ${this._renderMenu()}
      </div>
    </div>`;
  }

  /* TODO make logo as slot ? */
  _renderHeader() {
    const logoOptions = getLogoOptions() || {};
    return html`
      <slot name="header"></slot>
      <div class="header">
        <ki-icon-btn
          icon="ki ki-bars"
          class="toggle-btn"
          @click="${this.toggleSideBar}"
        ></ki-icon-btn>
        <div class="logo-left">
          <a href="${logoOptions?.left?.href || '#'}">
            <img
              id="logo-left-img"
              style="${logoOptions?.left?.style || ''}"
              src="${logoOptions?.left?.image || 'images/logo_left.png'}"
              alt="${logoOptions?.left?.alt || ''}"
          /></a>
        </div>
        <div class="nav">${ViewPort.size === SM ? '' : this._renderMenu()}</div>
        <div class="logo-right">
          <a href="${logoOptions?.right?.href || '#'}">
            <img
              id="logo-right-img"
              style="${logoOptions?.right?.style || ''}"
              src="${logoOptions?.right?.image || 'images/logo_right.png'}"
              alt="${logoOptions?.right?.alt || ''}"
          /></a>
        </div>
      </div>
    `;
  }
}
