import { css } from 'lit';
// language=CSS
export default css`
  :host {
    display: block;
    position: relative;
    height: 100%;
  }

  :host ki-searchbox {
    border-radius: 0;
    transition: none;
    flex: 0;
    position: absolute;
    max-width: 35%;
    right: 20px;
    top: 10px;
    height: 40px;
    box-shadow: none;
    align-items: center;
    z-index: 3;
  }

  :host ki-switcher:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  :host #copyToClipboardButton {
    position: absolute;
    top: 10px;
    right: 300px;
    z-index: 2;
  }

  :host(.sm-screen) .downloadButton {
    display: none;
  }

  #multiGraphDialog {
    width: 95vw;
    height: 95vh;
    top: 0px;
  }

  #multiGraphDialog::part(content) {
    padding: 0px;
  }

  #selectedStationTableDialog {
    max-width: 95vw;
    max-height: 95vh;
  }

  #selectedStationTableDialog::part(content) {
    padding: 0px;
  }

  .downloadButton {
    position: absolute;
    z-index: 2;
    right: 350px;
    top: 10px;
  }

  ki-wcp-wwp-filter {
    width: 33vw;
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 3;
  }

  :host ki-switcher {
    /*border: 1px solid var(--theme-color);*/
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    box-shadow: none;
    border-radius: 0;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  ki-stack {
    height: 100%;
  }

  ki-station-map {
    height: 100%;
    width: 100%;
    display: block;
  }

  ki-stack > .map-container {
    display: block;
  }

  ki-stack > .overview-container {
    padding-top: 60px;
    box-sizing: border-box;
  }

  ki-stack > .station-list {
    padding-top: 60px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1;
    top: 0;
    left: auto;
    right: -100%;
    display: block;
    transition: right 0.6s ease-in-out;
  }

  ki-stack > .station-list.selected {
    right: 0;
  }

  #selectedStations {
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    width: auto;
    left: 50%;
    z-index: 1;
  }

  ki-responsive-table {
    width: 100%;
    min-height: 50px;
    flex: 1;
  }

  :host(.table) ki-searchbox,
  :host(.table) ki-switcher {
    box-shadow: none;
    border: 1px solid lightgray;
    transition: all 0.3s ease-in-out 0.6s;
  }

  :host(.sm-screen) ki-searchbox {
    width: 100%;
    top: 10px;
    min-width: 33%;
    max-width: 33%;
    right: 5px;
  }

  :host(.sm-screen) #copyToClipboardButton {
    top: 12px;
    left: 120px;
  }

  :host(.sm-screen) ki-wcp-wwp-filter {
    top: 10px;
    left: 5px;
    padding: 2px;
    display: flex;
    align-items: center;
  }

  :host(.sm-screen) ki-switcher {
    top: 10px;
  }

  :host(.sm-screen) ki-stack > .station-list {
    padding-top: 50px;
  }

  ki-legend {
    bottom: 40px;
    left: 20px;
    max-width: 400px;
    transition: 0.6s all;
    z-index: 1;
  }

  ts-graph-viewer {
    background: white;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0px;
    bottom: 0px;
  }

  ts-graph-viewer::part(search) {
    display: none;
  }

  .lastUpdate {
    position: absolute;
    bottom: 10px;
    color: rgb(74, 74, 73);
    left: 23px;
    font-size: 0.8em;
    background: var(
      --ki-overview-lastUpdate-background,
      rgba(255, 255, 255, 0)
    );
    padding: 2px;
  }

  :host(.sm-screen) .lastUpdate,
  :host(.overview) .lastUpdate {
    display: none;
  }

  :host(.sm-screen) ki-legend {
    display: block;
    top: unset;
    bottom: 85px;
    right: unset;
    left: 5px;
    z-index: unset;
  }

  :host(.table) ki-legend {
    left: unset;
    right: 20px;
  }

  :host(.sm-screen.table) ki-legend,
  :host(.overview) ki-legend {
    display: none;
  }

  :host(.md-screen) ki-station-map::part(overview-map) {
    background-color: rgba(255, 255, 255, 0.5);
  }

  :host(.sm-screen) ki-wcp-wwp-filter {
    left: 5px;
    top: 10px;
    display: inline-flex;
    padding: 0px;
  }

  :host(.md-screen) ki-wcp-wwp-filter {
    left: 5px;
    top: 10px;
    display: flex;
    padding: 0px;
  }

  :host(.md-screen) ki-legend {
    left: 5px;
  }

  :host(.sm-screen) ki-switcher {
    left: 50%;
  }
`;
