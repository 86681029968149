import { css } from 'lit';

export default css`
  :host {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .box {
    margin: 15pt;
    width: 45vw;
    max-width: 450px;
  }

  fieldset {
    height: 400px;
  }

  .kennzahlen-table {
    margin: 10px;
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    .box {
      width: 95vw;
      max-width: 900px;
      font-size: 0.9em;
      margin: 5px;
    }

    fieldset {
      height: auto;
    }

    .kennzahlen-table {
      margin: 0px;
    }
  }

  h3 {
    color: #383838;
  }

  table,
  th,
  td {
    border-bottom: 1px solid #686868;
    border-collapse: collapse;
    color: #383838;
  }

  th {
    text-align: left;
    padding: 10px;
  }

  th.val {
    text-align: right;
    padding: 10px;
  }

  td {
    padding: 10px;
  }

  td:nth-child(2) {
    text-align: right;
  }

  a:link {
    text-decoration: none;
    color: rgba(0, 158, 224, 1);
  }

  a:visited {
    text-decoration: none;
    color: #005596;
  }

  a:hover {
    text-decoration: none;
    font-weight: bold;
    color: #005596;
  }

  legend {
    color: #686868;
  }

  fieldset {
    border: 1px #686868 solid;
  }

  a:active {
    text-decoration: none;
  }

  .maplink {
    width: 100%;
    float: right;
  }
`;
