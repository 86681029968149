import { filter, first } from 'lodash-es';
import { TsDataPoint } from './TsDataPoint';

/**
 * Checks if a string matches a Wildcard-Pattern
 * @param str string to evaluate
 * @param rule to apply on provided string
 * @returns true if strign matches rule
 */
function _matchRuleShort(str: string, rule: string): boolean {
  /* eslint-disable no-useless-escape */
  const escapeRegex = unescapedStr =>
    unescapedStr.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  /* eslint-enable no-useless-escape */
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`).test(
    str,
  );
}

function _findTsByString(
  pattern: string,
  array: TsDataPoint[],
  matchWith?: string,
): TsDataPoint[] {
  if (pattern && array) {
    return filter(array, ts => {
      if (matchWith) return _matchRuleShort(ts[matchWith], pattern);
      return (
        _matchRuleShort(ts.ts_shortname, pattern) ||
        _matchRuleShort(ts.ts_name, pattern) ||
        _matchRuleShort(ts.ts_path, pattern)
      );
    });
  }
  return [];
}

function _findTsByProperty(property, array, value): TsDataPoint[] {
  return filter(array, ts => (ts[property] === value ? ts : null));
}

/**
 * @param tsName
 * @returns true if ends with ".O"
 */
export function isOriginalTsName(tsName: string) {
  return tsName.endsWith('.O');
}

/**
 * @param tsShortname System
 * @returns true if includes ".O."
 */
export function isOriginalTsShortname(tsShortname: string) {
  return tsShortname.includes('.O.');
}

/**
 * Removes all orignal timeseries from provided array
 * @param tsArray Input with all timeseries
 * @param evaluateTsName Evaluate ony human-readable name
 * @remark ts_shortname is evaluated by default
 * @returns Filtered array without original timeseries
 * */
export function filterOriginalTs(
  tsArray: Array<Object>,
  evaluateTsName: boolean = true,
): Array<Object> {
  const result = tsArray.filter(ts =>
    evaluateTsName
      ? !isOriginalTsName(ts.ts_name)
      : !isOriginalTsShortname(ts.ts_shortname),
  );
  return result;
}

// Array contains tsList
export function findTsByString(pattern, array, matchWith) {
  return _findTsByString(pattern, array, matchWith);
}
export function findFirstTsByString(pattern, array, matchWith) {
  return first(_findTsByString(pattern, array, matchWith));
}
// Find ts by providing a property and the corresponding value
export function findTsByProperty(property, array, value) {
  return _findTsByProperty(property, array, value);
}
export function findFirstTsByProperty(property, array, value) {
  return first(_findTsByProperty(property, array, value));
}
