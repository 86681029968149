/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  last,
  merge,
  find,
  head,
  groupBy,
  flatten,
  orderBy,
  compact,
  first,
} from 'lodash-es';
import {
  SM,
  ViewPort,
  responsiveMixin,
  i18nMixin,
  queryParamMixin,
  localStorageMixin,
} from '@kisters/wcp-base/decorators';
import './ki-wwp-switcher-bottom';
import {
  LoaderMixin,
  template,
  copyTextToClipboard,
  Mix,
  downloadFile,
} from '@kisters/wcp-base/common';

import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import './ki-wwp-ts-table';
import hclocales from '@kisters/wcp-water/common/highcharts-locales';
import Highcharts from 'highcharts/highstock';
import nodata from 'highcharts/modules/no-data-to-display';
import exporting from 'highcharts/modules/exporting';
import boost from 'highcharts/modules/boost';
import more from 'highcharts/highcharts-more';
import offlineexporting from 'highcharts/modules/offline-exporting';

import serieslabel from 'highcharts/modules/series-label';
import { getCsvDelimiter } from '@kisters/wcp-base/components/ki-app/services/ki-app-config-service';
import { getCurrentApi } from '../../api/API';
import nls from '../../locales/index';

import style from './ki-wwp-graph.css?inline';

import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { GraphHelper } from './GraphHelper';
import { AttributeThreshold } from './AttributeThreshold';

@customElement('ki-wwp-graph')
export default class KiWwpGraph extends Mix(
  LitElement,
  LoaderMixin,
  [i18nMixin, { nls }],
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    {
      targetProperty: '_selectedPeriod',
      selector: 'period',
      defaultValue: '',
    },
  ],
  responsiveMixin,
) {
  // language=CSS

  @property({ type: Object })
  station;

  @property({ type: Object })
  options = {};

  @property({ type: Boolean })
  miniGraph = false;

  /** Reduce loading time for popups; only fetch specified ranges
   * @remark Usually just 'week.json'
   */
  @property({ type: Boolean })
  onlyFetchSpecifiedRanges = false;

  @property({ type: Boolean })
  favButton = false;

  @property({ type: String })
  stationId;

  forcePeriodRange = true;

  maincolor = '#0056a0';

  qCodeExclude = [];

  showUntilEndOfDay = true;

  imageShareAutoOpen = false;

  zoomType = 'x';

  boost = true;

  __selectedPeriod;

  hydroYearStart = '01-01';

  hydroYearEnd = '01-01';

  attributeThresholds: Array<AttributeThreshold> = [];

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  get _selectedPeriod() {
    return this.__selectedPeriod;
  }

  set _selectedPeriod(value) {
    this.__selectedPeriod = value;
    this.requestUpdate();
  }

  static get styles() {
    return [style];
  }

  constructor() {
    super();
    serieslabel(Highcharts);
    exporting(Highcharts);
    offlineexporting(Highcharts);
    nodata(Highcharts);
    more(Highcharts);
    boost(Highcharts);
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    let resize;
    window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        if (this.chart) {
          this.chart.update({
            legend: this.legendOptions,
            credits: this.creditOptions,
          });
          this.chart.reflow();
        }
      }, 220);
    });
  }

  firstUpdated() {
    if (!this.miniGraph) {
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 300000);
    }
    Object.assign(this, this.options);
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
    this.api = getCurrentApi();
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );
    if (this.api) {
      this.fetchData();
    }
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    Object.assign(this, JSON.parse(JSON.stringify(params.options)));
    this.layerName = params.layerName;
    this.apppath = location.route;
    this.stationId = params.stationId;
  }

  isFavorite() {
    return this.__favouriteStationIds.indexOf(this.station?.station_id) >= 0;
  }

  _toggleFavourite() {
    if (this.__favouriteStationIds.indexOf(this.station.station_id) === -1) {
      this.__favouriteStationIds = [
        ...this.__favouriteStationIds,
        this.station.station_id,
      ];
    } else {
      this.__favouriteStationIds = this.__favouriteStationIds.filter(
        id => id !== this.station.station_id,
      );
    }
  }

  createChart() {
    if (!this.chart) {
      if (hclocales[this.i18n.language.substring(0, 2)]) {
        Highcharts.setOptions({
          lang: hclocales[this.i18n.language.substring(0, 2)],
        });
      }
      this.chart = new Highcharts.StockChart(
        this.renderRoot.querySelector('#chart-node'),
        this.defaultOptions(),
        this.chartCreated.bind(this),
      );
    }
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated(changedProperties) {
    if (changedProperties.get('stationId')) {
      //   console.log(this.stationId, changedProperties.get('stationId'))
      this.station = null;
      this.fetchData();
    }
    if (this.chart) {
      //   console.log("resize bfeore", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
      this.chart.reflow();
      //    console.log("resize", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
    }
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    clearInterval(this.refreshInterval);
  }

  handleResize = (): void => {
    this.requestUpdate();
  };

  // eslint-disable-next-line class-methods-use-this
  get legendOptions() {
    let legendOptions = {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
      alignColumns: false,
      itemMarginBottom: 10,
      itemDistance: ViewPort.size === SM ? 15 : 35,
      width: '90%',
      symbolRadius: 0,
      squareSymbol: false,
      enabled: !this.miniGraph,
    };

    if (ViewPort.size === SM && ViewPort.landscape) {
      legendOptions = {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        alignColumns: false,
        itemMarginBottom: 5,
        enabled: !this.miniGraph,
        symbolPadding: 5,
        itemDistance: ViewPort.size === SM ? 15 : 35,
        width: ViewPort.size === SM ? '20%' : '80%',
        symbolRadius: 0,
        squareSymbol: false,
      };
    }
    return legendOptions;
  }

  get creditOptions() {
    const credits = this.credits ? { ...this.credits } : { enabled: false };
    if (credits?.text) {
      credits.text = template(credits.text, this.station);
    }
    if (this.credits && ViewPort.size === SM) {
      credits.position = credits.positionMobile || credits.position;
    }
    credits.enabled = !this.miniGraph;
    return credits;
  }

  // eslint-disable-next-line class-methods-use-this
  getTableValuesFromTemplate(cfg, data, minDate, maxDate = Infinity) {
    const tsIndex = {};
    cfg.tableTemplate.forEach(tsshortname => {
      const ts = find(data, { ts_shortname: tsshortname });
      const tsdata = groupBy(ts.data, head);
      tsIndex[ts.ts_shortname] = tsdata;
    });

    const firstts = find(data, { ts_shortname: cfg.tableTemplate[0] });
    const tsdata = [];
    firstts.data.forEach(dp => {
      const tzoffset =
        (dayjs().utcOffset() - dayjs().tz().utcOffset()) * 60 * 1000;
      const ts = dp[0];
      const tsms = new Date(ts).getTime() - tzoffset;
      if (tsms >= minDate && tsms <= maxDate) {
        const row = [tsms];
        cfg.tableTemplate.forEach(item => {
          const tempts = tsIndex[item];
          const val = tempts[ts] ? tempts[ts][0][1] : null;
          row.push(val);
        });
        tsdata.push(row);
      }
    });
    return { data: tsdata };
  }

  // eslint-disable-next-line consistent-return
  _getSeries(cfg, data, minDate, maxDate = Infinity) {
    const tzoffset =
      (dayjs().utcOffset() - dayjs().tz().utcOffset()) * 60 * 1000;
    const dCommentIndex = data[0].columns
      ? data[0]?.columns.split(',').indexOf('Data Comment')
      : null;
    if (cfg.timeseries) {
      const tslist: any[] = [];
      cfg.timeseries.forEach((tscfg, i) => {
        if (tscfg.ts_shortname_min && tscfg.ts_shortname_max) {
          tscfg.type = 'range';
          const minTs = find(data, {
            ts_shortname: tscfg.ts_shortname_min,
          });
          const maxTs = find(data, {
            ts_shortname: tscfg.ts_shortname_max,
          });
          const minIdx = groupBy(minTs.data, head);
          const tsdata = [];

          maxTs.data.forEach(dp => {
            const ts = dp[0];
            const tsms = new Date(ts).getTime() - tzoffset;
            const max = dp[1];
            const min = minIdx[ts] ? minIdx[ts][0][1] : null;
            if (tsms >= minDate && tsms <= maxDate) {
              tsdata.push([tsms, min, max]);
            }
          });

          tslist.push(
            merge(
              {
                data: tsdata,
                id: `data_${i}`,
                name: this.parameter_label,
                color: 'rgba(0, 86, 160, 0.3)',
                type: 'arearange',
                fillOpacity: 0.3,
                xAxis: 'mainXAxis',

                //   gapSize: 2,
                selected: true,
                marker: {
                  enabled: false,
                },
                tooltip: {
                  valueSuffix: ` ${
                    minTs.ts_unitsymbol !== '---' ? minTs.ts_unitsymbol : ''
                  }`,
                  xDateFormat: this.dateFormat ?? '%d.%m.%Y',
                },
              },
              tscfg.options,
            ),
          );
        } else {
          /** Compare available data with requested data in ranges-option; e.g. CWC with multiple parameters */
          const ts = tscfg.stationparameter_name
            ? find(data, {
                ts_shortname: tscfg.ts_shortname,
                stationparameter_name: tscfg.stationparameter_name,
              })
            : find(data, { ts_shortname: tscfg.ts_shortname });
          if (ts) {
            if (tscfg.minDate) {
              minDate =
                dayjs().tz().subtract(dayjs.duration(tscfg.minDate)).valueOf() -
                tzoffset;
            }
            tslist.push(
              merge(
                {
                  columns: ts.columns,
                  data: this._formatGraphData(ts.data, minDate, maxDate, ts),
                  id: `data_${i}`,
                  name: this.parameter_label,
                  color: this.maincolor,
                  xAxis: 'mainXAxis',
                  selected: true,
                  marker: {
                    radius: 1,
                    enabled: true,
                  },
                  tooltip: {
                    pointFormatter() {
                      return `${this.series.name}: <b>${this.y} ${
                        ts.ts_unitsymbol !== '---' ? ts.ts_unitsymbol : ''
                      }</b><br /> ${
                        dCommentIndex && ts.data[this.index][dCommentIndex]
                          ? `Data Comment: <b>${
                              ts.data[this.index][dCommentIndex]
                            }</b>`
                          : ''
                      }`;
                    },
                  },
                },
                tscfg.options,
              ),
            );
          }
        }
      });
      return tslist;
    }
    if (data[0]) {
      const options = cfg.options || {};
      const ts = data[0];
      return [
        merge(
          {
            data: this._formatGraphData(ts.data, minDate, maxDate, ts),
            id: 'data',
            color: '#0056a0',
            name: this.parameter_label,
            xAxis: 'mainXAxis',
            selected: true,
            marker: {
              radius: 1,
              enabled: true,
            },
            tooltip: {
              pointFormatter() {
                return `${this.series.name}: <b>${this.y} ${
                  ts.ts_unitsymbol !== '---' ? ts.ts_unitsymbol : ''
                }</b><br /> ${
                  dCommentIndex && ts.data[this.index][dCommentIndex]
                    ? `Data Comment: <b>${
                        ts.data[this.index][dCommentIndex]
                      }</b>`
                    : ''
                }`;
              },
              xDateFormat: this.dateFormat
                ? `${this.dateFormat} %H:%M`
                : '%d.%m.%Y %H:%M',
            },
          },
          options,
        ),
      ];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphData(
    data = [],
    minDate = -Infinity,
    maxDate = Infinity,
    timeseries = {},
  ) {
    const tsData = [];
    //  const start = dayjs().valueOf()
    const tzoffset =
      (dayjs().utcOffset() - dayjs().tz().utcOffset()) * 60 * 1000;

    const qCodeIndex = timeseries.columns
      ? timeseries?.columns.split(',').indexOf('Quality Code')
      : null;

    data.forEach(dp => {
      const ts = new Date(dp[0]).getTime() - tzoffset;
      if (this.qCodeExclude.length) {
        if (
          ts >= minDate &&
          ts <= maxDate &&
          !this.qCodeExclude.includes(dp[qCodeIndex])
        ) {
          tsData.push([ts, ...dp.slice(1)]);
        }
      } else if (ts >= minDate && ts <= maxDate) {
        tsData.push([ts, ...dp.slice(1)]);
      }
    });
    // console.log("duration", tsData.length,  dayjs().valueOf()-start)
    return tsData;
    // return data.map(dp => [new Date(dp[0]).getTime(), dp[1]]);
  }

  _formatNumber(val) {
    return val === undefined || val === null || val === '' || Number.isNaN(val)
      ? ''
      : this.numberFormatter.format(val);
  }

  drawForecastDeterministic() {
    const ts_list = this.files[this.forecast];

    if (!ts_list) {
      return;
    }

    const { data } = last(ts_list);
    const firstDataPoint = data[0];
    if (!firstDataPoint) {
      return;
    }
    const t0 = dayjs(firstDataPoint[0]).tz().valueOf(); // this.station[this.progTs] ? dayjs(this.station[this.progTs]+"Z").valueOf() : dayjs().valueOf();

    const xAxis = this.chart.get('mainXAxis');
    xAxis.addPlotLine({
      id: 'pz',
      zIndex: 2,
      color: 'gray',
      width: 2,
      value: t0,
      label: {
        //   rotation:270,
        x: 5,
        y: this.miniGraph ? 80 : 120,
        verticalAlign: 'top',
        text: 't0',
      },
    });

    /** Add Vorhersage */
    this.chart.addSeries({
      name: this.i18n.t('FORECAST'),
      selected: true,
      color: 'green',
      id: 'data_forecast',
      data: GraphHelper.transformForecast(data),
      events: {
        hide: () => {
          xAxis.removePlotLine('pz');
        },
        show: () => {
          xAxis.addPlotLine({
            id: 'pz',
            zIndex: 2,
            color: 'gray',
            width: 2,
            value: t0,
            label: {
              //   rotation:270,
              x: 5,
              y: this.miniGraph ? 80 : 120,
              verticalAlign: 'top',
              text: 't0',
            },
          });
        },
      },
    });
  }

  drawForecast() {
    const ts = this.files[this.forecast];
    if (!ts) {
      return;
    }

    this.chart.get('mainXAxis').setExtremes(null, null, false, false);

    const { data, columns } = ts[0];
    const colArray = columns.split(',');
    const firstDataPoint = data[0];
    if (!firstDataPoint) {
      return;
    }
    const progTs = dayjs(firstDataPoint[0]).tz().valueOf(); // this.station[this.progTs] ? dayjs(this.station[this.progTs]+"Z").valueOf() : dayjs().valueOf();

    const trendAb = this.station[this.trendAb]
      ? Number(this.station[this.trendAb])
      : null;
    const progDur = this.station[this.progDuration]
      ? Number(this.station[this.progDuration])
      : null;

    const prognoseBisTs = dayjs(progTs).tz().add(progDur, 'minutes').valueOf();
    const trendAbTs = dayjs(progTs).tz().add(trendAb, 'hours').valueOf();

    const prognoseBranches = this.station.web_prognose_branches;
    const indexMin = colArray.indexOf('Min');
    const indexMax = colArray.indexOf('Max');
    const indexMittel = colArray.indexOf('Mittel');
    const indexTUMin = colArray.indexOf('TUMin');
    const indexTUMax = colArray.indexOf('TUMax');
    const indexTUMittel = colArray.indexOf('TUMittel');

    const dataTrend = [];
    const dataTrendVB = [];
    const dataProg = [];
    const dataProgVB = [];

    let actualMin;
    let actualMax;
    let actualMean;

    if (prognoseBranches === 'Alle-Ext' || prognoseBranches === 'Min/Max-Ext') {
      actualMin = indexMin;
      actualMax = indexMax;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Min/Max-Int'
    ) {
      actualMin = indexTUMin;
      actualMax = indexTUMax;
    }
    if (
      prognoseBranches === 'Alle-Ext' ||
      prognoseBranches === 'Prognose-Ext'
    ) {
      actualMean = indexMittel;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Prognose-Int'
    ) {
      actualMean = indexTUMittel;
    }

    data.forEach(row => {
      const tsValue = dayjs(row[0]).tz().valueOf();
      if (tsValue < prognoseBisTs) {
        if (trendAb && tsValue >= trendAbTs) {
          dataTrend.push([tsValue, row[actualMean]]);
          dataTrendVB.push([tsValue, row[actualMin], row[actualMax]]);
        } else {
          dataProg.push([tsValue, row[actualMean]]);
          dataProgVB.push([tsValue, row[actualMin], row[actualMax]]);
        }
      }
    });

    const xAxis = this.chart.get('mainXAxis');
    if (this.station.web_prognose_publish !== 'no') {
      xAxis.addPlotLine({
        id: 'pz',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: progTs,
        label: {
          //   rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
        },
      });
    }

    if (
      trendAb &&
      this.station.web_prognose_trendanzeige !== 'no' &&
      this.station.web_prognose_publish !== 'no'
    ) {
      xAxis.addPlotLine({
        id: 'trend',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: trendAbTs,
        label: {
          // rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
        },
      });
      this.chart.addSeries(
        {
          name: 'Trend',
          selected: true,
          color: 'green',
          id: 'data_trend',
          dashStyle: 'dot',
          data: dataTrend,
          events: {
            hide: () => {
              xAxis.removePlotLine('trend');
            },
            show: () => {
              xAxis.addPlotLine({
                id: 'trend',
                zIndex: 2,
                color: 'gray',
                width: 2,
                value: trendAbTs,
                label: {
                  // rotation:270,
                  x: 5,
                  y: this.miniGraph ? 80 : 120,
                  verticalAlign: 'top',
                  text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
                },
              });
            },
          },
        },
        false,
      );
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich Trend',
          type: 'arearange',
          fillOpacity: 0.5,
          linkedTo: 'data_trend',
          id: 'data_trendarea',
          showInLegend: false,
          selected: true,
          dashStyle: 'dot',
          fillColor: 'rgba(124,181,236,0.3)',
          data: dataTrendVB,
        },
        false,
      );
    }

    if (this.station.web_prognose_publish !== 'no') {
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich',
          type: 'arearange',
          fillOpacity: 0.5,
          id: 'data_forecastarea',
          selected: true,
          color: 'rgba(124,181,236,1)',
          showInLegend: false,
          fillColor: 'rgba(124,181,236,0.5)',
          data: dataProgVB,
          linkedTo: 'data_forecast',
        },
        false,
      );

      this.chart.addSeries({
        name: 'Vorhersage',
        selected: true,
        color: 'green',
        id: 'data_forecast',
        data: dataProg,
        events: {
          hide: () => {
            xAxis.removePlotLine('pz');
          },
          show: () => {
            xAxis.addPlotLine({
              id: 'pz',
              zIndex: 2,
              color: 'gray',
              width: 2,
              value: progTs,
              label: {
                //   rotation:270,
                x: 5,
                y: this.miniGraph ? 80 : 120,
                verticalAlign: 'top',
                text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
              },
            });
          },
        },
      });
    }
  }

  _getTimestampFromDuration(dur) {
    const tzoffset =
      (dayjs().utcOffset() - dayjs().tz().utcOffset()) * 60 * 1000;
    if (!dur) {
      return dayjs().tz().valueOf() - tzoffset;
    }
    if (dur === 'PoR') {
      return dayjs(this.coverage.min).tz().valueOf() - tzoffset;
    }
    if (dur.charAt(0) === '+') {
      return dayjs().add(dayjs.duration(dur)).valueOf() - tzoffset;
    }
    if (dur === 'PT24H') {
      return dayjs().tz().subtract(dayjs.duration(dur)).valueOf() - tzoffset;
    }
    const until = this.showUntilEndOfDay
      ? dayjs().endOf('day').tz()
      : dayjs().tz();
    return until.subtract(dayjs.duration(dur)).valueOf() - tzoffset;
  }

  drawSeries(period, min, max) {
    const cfg = find(this.ranges, { value: period }) || this.ranges[0];
    if (cfg.yearSelector) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    if (
      this.station.BODY_RESPONSIBLE &&
      this.station.BODY_RESPONSIBLE === 'WSV' // TODO: Remove LANUV?! artifact
    ) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    this.chart.series
      .filter(ser => ser.userOptions.id && ser.userOptions.id.includes('data'))
      .forEach(ser => ser.remove(false));
    const minDate = min || this._getTimestampFromDuration(period);
    let maxDate = max || this._getTimestampFromDuration();
    const xAxis = this.chart.get('mainXAxis');
    this.chart.zoomOut();
    if (cfg.yAxisLabel) {
      this.yAxisLabel = cfg.yAxisLabel;
      this.shadowRoot.getElementById('dataTable').columns = this.tablecolumns;
    }
    cfg.timeseries &&
      cfg.timeseries.forEach(ts => {
        if (ts.maxDate) {
          maxDate = this._getTimestampFromDuration(ts.maxDate);
        }
      });

    if (cfg.plotLines) {
      cfg.plotLines.forEach(pl => {
        const _pl = { ...pl };
        _pl.id = `dynamic_${pl.id}`;
        _pl.value = this._getTimestampFromDuration(pl.value);
        xAxis.removePlotBandOrLine(_pl.id);
        if (pl.filter) {
          cfg.timeseries.some(item => item.ts_shortname === pl.filter) &&
            xAxis.addPlotLine(_pl);
        } else {
          xAxis.addPlotLine(_pl);
        }
      });
    } else {
      xAxis.plotLinesAndBands.forEach(
        pl => pl.id.includes('dynamic_') && xAxis.removePlotLine(pl.id),
      );
    }

    xAxis.removePlotLine('pz');
    xAxis.removePlotLine('trend');
    if (period === 'PT24H') {
      xAxis.setExtremes(minDate, dayjs(maxDate), false, false);
    } else if (this.forcePeriodRange) {
      xAxis.setExtremes(
        minDate,
        this.showUntilEndOfDay ? dayjs(maxDate).endOf('day') : dayjs(maxDate),
        false,
        false,
      );
    }

    /** Legacy variant with confidence interval + trend
     * Keep the "typo" in config this for backward compatibility
     * @TODO Choose forecast visualization in config file; e.g. type: "deterministic", "conf_interval"
     */
    if (cfg.showforcast) {
      this.drawForecast();
    }

    /** Deterministic: Single line with t0 */
    if (cfg.showForecastDeterministic) {
      this.drawForecastDeterministic();
    }

    this.chart.get('data') && this.chart.get('data').remove(false);

    if (GraphHelper.rangeObjectContains(this.files, cfg.data)) {
      const series = this._getSeries(
        cfg,
        GraphHelper.getFile(this.files, cfg),
        minDate,
        maxDate,
      );
      this.tableColumns = cfg.tableColumns ?? this.tableColumns;
      const tabledata = cfg.tableTemplate
        ? this.getTableValuesFromTemplate(
            cfg,
            GraphHelper.getFile(this.files, cfg),
            minDate,
            maxDate,
          )
        : Array.isArray(series)
        ? series[0]
        : series;
      if (tabledata) {
        // Sort and format
        // console.time('start');
        this.orderedTableData = orderBy(tabledata.data, [0], ['desc']).map(
          item => {
            const vals = [];
            item.forEach((_val, i) => {
              if (i > 0 && typeof _val === 'number') {
                vals.push(this._formatNumber(_val));
              }
            });
            // Use separate col for each value
            if (this.separateCol) {
              return {
                timestamp: dayjs(item[0]).format(cfg.dateformat || 'L LT'),
                value: vals, // Return array
              };
            }
            // Show in common value col with " - "
            if (vals.length === 3) {
              vals[1] = `<b>${vals[1]}</b>`;
            }
            const val = vals.join(' - ');

            if (tabledata.columns) {
              const retObj = {
                timestamp: dayjs(item[0]).format(cfg.dateformat || 'L LT'),
                value: val, // Return single value
              };
              tabledata.columns.split(',').forEach((col, i) => {
                if (i > 1 && item[i] !== undefined) {
                  retObj[col] = item[i];
                }
              });
              return retObj;
            }

            return {
              timestamp: dayjs(item[0]).format(cfg.dateformat || 'L LT'),
              value: val, // Return single value
            };
          },
        );
        // console.timeEnd('start');
        this.shadowRoot.getElementById('dataTable').data =
          this.orderedTableData;
      }
      series.forEach((ts, i) => {
        this.chart.addSeries(ts, i === series.length - 1);
      });
    }
  }

  getRangeOptions() {
    return this.ranges || [];
  }

  showDescription() {
    this.descriptionVisible = !this.descriptionVisible;
    this.chart.series.forEach(ser => {
      if (ser.options.description) {
        ser.update(
          {
            name:
              this.descriptionVisible && ser.options.description
                ? `<b>${ser.options.shortName}</b>: ${ser.options.description}`
                : ser.options.shortName,
            selected: ser.options.visible, // Sync checkbox in legend
          },
          false,
        );
      }
    });
    this.chart.redraw();
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${
          this.station.station_id
        }&component=ki-wwp-graph-ext&key=${encodeURIComponent(
          this.apppath?.path,
        )}&period=${this._selectedPeriod}`
      : '';
  }

  render() {
    // language=html
    const title =
      this.graphTitle && this.station
        ? template(this.graphTitle, this.station)
        : '';
    return html`<div id="nav-node" class="${this.miniGraph ? 'hidden' : ''}">
        <div class="label">${title}</div>
        <ki-wwp-switcher-bottom
          @changed="${e => {
            if (this.chart) {
              this.drawSeries(e.detail.value);
            }
            this._selectedPeriod = e.detail.value;
          }}"
          .options="${this.getRangeOptions()}"
          class="buttons"
          .value=${this._selectedPeriod}
        >
        </ki-wwp-switcher-bottom>
        <div class="actions">
          <ki-icon-btn
            class="ripple clipboard-btn ${this.imageShare &&
            ViewPort.size !== SM
              ? ''
              : 'hideButton'}"
            title="${this.i18n.t('shareLink')}"
            icon="ki ki-share"
            id="link-btn"
            @click=${() => {
              this.renderRoot.querySelector(
                '#embeddedgraph',
              ).value = `<iframe src="${this.getImageShare()}" style="width:80%;height:700px;border:0;"></iframe>"`;
              const popup = this.renderRoot.querySelector('#download-popup');
              if (this.imageShareAutoOpen) {
                window.open(this.getImageShare(), '_blank');
              }
              popup.visible ? popup.hide({}) : popup.show({});
            }}
          ></ki-icon-btn>
          <ki-popup
            for="link-btn"
            style="margin-right: 20px;"
            top="40px"
            left="-100"
            id="download-popup"
          >
            <div
              style="padding: 10px;font-size: 1.2em;border-bottom: 1px solid gray;display:flex;justify-content:space-between;align-items: center;"
            >
              ${this.i18n.t('embedGraph')}<ki-icon-btn
                title="schließen"
                @click="${() => {
                  this.renderRoot.querySelector('#download-popup').hide({});
                }}"
                style="margin:0;height: 20px;width: 20px;line-height: 20px;"
                icon="ki ki-times"
              ></ki-icon-btn>
            </div>
            <textarea
              disabled
              id="embeddedgraph"
              style="border: none;outline: none;resize:none;padding:10px;background:white;width: 90%;"
              rows="12"
            >
            </textarea>
            <ui5-button
              @click="${() => {
                window.open(this.getImageShare(), '_blank');
              }}"
              title="${this.i18n.t('previewTitle')}"
              >${this.i18n.t('preview')}</ui5-button
            >
            <ui5-button
              @click="${() => {
                copyTextToClipboard(
                  this.renderRoot.querySelector('#embeddedgraph').value,
                );
              }}"
              title="${this.i18n.t('copyToClipboard')}"
              >${this.i18n.t('copy')}</ui5-button
            >
          </ki-popup>
          <ki-icon-btn
            class="fav-btn ripple ${this.favButton ? '' : 'hidden'}"
            @click="${this._toggleFavourite}"
            title="${this.isFavorite()
              ? this.i18n.t('removeFav')
              : this.i18n.t('addFav')}"
            icon="ki ${this.isFavorite() ? 'ki-star' : 'ki-star-o'}"
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            id="descriptionButton"
            class="${this.hasAlarms && ViewPort.size !== SM
              ? ''
              : 'hidden'} ${this.descriptionVisible ? 'selected' : ''}"
            title="${this.i18n.t('descriptions')}"
            icon="ki ki-question"
            @click="${this.showDescription}"
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple clipboard-btn ${this.tableOpen ? '' : 'hideButton'}"
            id="table-clipboard-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            ,
            title="${this.i18n.t('copyTableTitle')}"
            icon="ki ki-clipboard"
            .value=${this.orderedTableData}
            @click=${() => {
              copyTextToClipboard(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV(getCsvDelimiter())
                  .replace(/(<([^>]+)>)/gi, ''),
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple clipboard-btn ${this.tableOpen ? '' : 'hideButton'}"
            id="table-download-csv-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            ,
            title="${this.i18n.t('downloadTableAsCSVTitle')}"
            icon="ki ki-file"
            .value=${this.orderedTableData}
            @click=${() => {
              downloadFile(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV(getCsvDelimiter())
                  .replace(/(<([^>]+)>)/gi, ''),
                `${this.station.station_no}_${this.station.station_name}_graph_table.csv`,
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            title="${this.i18n.t('tableTitle')}"
            icon="ki ki-table"
            ?active="${this.tableOpen}"
            @click="${this.showTable}"
          ></ki-icon-btn>
        </div>
      </div>
      <div class="contentnode" id="contentnode">
        <div id="chart-wrapper">
          <ui5-select
            class="hideButton"
            id="yearselector"
            @change="${this._handleYearChange}"
          >
            ${this._years.map(
              year =>
                html`<ui5-option
                  ?selected=${year.value === 'YTD'}
                  value="${year.value}"
                  >${year.label}</ui5-option
                >`,
            )}
          </ui5-select>
          <div id="chart-node"></div>
        </div>
        <div id="table-wrapper" class="${this.tableOpen ? '' : 'hidden'}">
          <ki-wwp-ts-table
            id="dataTable"
            .columns="${this.tablecolumns}"
            sort='[{"field":"timestamp", "ascending":false}]'
            idproperty="timestamp"
          >
          </ki-wwp-ts-table>
        </div>
      </div>`;
  }

  get _yAxisLabel() {
    return template(this.yAxisLabel, this.station);
  }

  get _years() {
    if (this.coverage) {
      let max = dayjs(this.coverage.max).tz().get('year');
      const min = dayjs(this.coverage.min).tz().get('year');
      if (this.hydroYearStart !== '01-01') {
        max -= 1;
      }
      const years = [{ value: 'YTD', label: this.i18n.t('currentYear') }];
      while (max >= min) {
        years.push({ value: max, label: max.toString() });
        max -= 1;
      }
      return years;
    }
    return [{ value: 'YTD', label: this.i18n.t('currentYear') }];
  }

  get tablecolumns() {
    return (
      this.tableColumns ?? [
        {
          field: 'timestamp',
          label: this.timestampLabel || this.i18n.t('timestamp'),
          sortable: true,
          hideAble: false,
        },
        {
          field: 'value',
          label: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          sortable: false,
          format: 'html',
          css: 'text-align:right;padding-right:10px;',
        },
      ]
    );
  }

  _handleYearChange(e) {
    const { value } = e.target.selectedOption;
    const prevYear = value;
    const nextYear = (parseInt(value, 10) + 1).toString();

    const min =
      value && value !== 'YTD'
        ? dayjs(`${prevYear}${this.hydroYearStart}`, `YYYYMM-DD`).tz()
        : dayjs().tz().startOf('year');

    const max =
      value && value !== 'YTD'
        ? dayjs(`${nextYear}${this.hydroYearEnd}`, `YYYYMM-DD`).tz()
        : dayjs().tz();
    this.drawSeries('P1Y', min.valueOf(), max.valueOf());
  }

  showTable() {
    this.renderRoot.querySelector('#table-wrapper').classList.toggle('hidden');
    this.renderRoot
      .querySelector('#table-clipboard-btn')
      .classList.toggle('hideButton');
    this.renderRoot
      .querySelector('#table-download-csv-btn')
      .classList.toggle('hideButton');
    this.tableOpen = !this.tableOpen;
    this.chart && this.chart.reflow();
  }

  downloadGraph() {
    this.chart.update(
      {
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().format('LLLL')} <br>
            ${dayjs(this.chart.xAxis[0].min + 10000)
              .tz()
              .format('L')} - ${dayjs(this.chart.xAxis[0].max + 10000)
                .tz()
                .format('L')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD')}`,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
      },
      false,
    );
    this.chart.exportChartLocal();
  }

  async fetchData() {
    this.chart?.destroy();
    this.chart = null;
    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));
    const dataToFetch = [];

    this.station.timeseries.forEach(ts => {
      /**
       * Check if parameters match
       * In case of popup is requesting data: Check if @param href is specified in range */
      if (
        ts.station_parameter === this.station_parameter &&
        this.ranges.some(range => ts.href.includes(range.data))
      ) {
        dataToFetch.push(ts.href);
      }
      if (this.additionalTsFiles) {
        this.additionalTsFiles.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
      if (this?.alarms?.file) {
        this.alarms.file.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
    });

    await Promise.all(
      dataToFetch.map(async href => {
        const _path = href.split('/').reverse();
        const _id = `${_path[1]}/${_path[0]}`;
        _files[_id] = await this.api.getTsData(href);
      }),
    );

    this.files = _files;
    let selected = find(this.ranges, { selected: true });
    const yearcfg = find(this.ranges, range =>
      ['P1Y', 'PoR'].includes(range.value),
    );
    if (yearcfg && GraphHelper.rangeObjectContains(this.files, yearcfg.data)) {
      const tsdata = first(GraphHelper.getFile(this.files, yearcfg))?.data;
      if (tsdata && tsdata[0]) {
        this.coverage = {
          min: first(tsdata)[0],
          max: last(tsdata)[0],
        };
      } else console.warn('Coverage not set!');
    }
    selected = this._selectedPeriod || selected.value || this.ranges[0].value;
    if (!find(this.ranges, { value: selected })) {
      selected = this.ranges[0].value;
    }
    this._selectedPeriod = selected;
    this._setMaxValue(selected);
    if (this.station) {
      this.createChart();
      this.ranges.forEach(range => {
        if (range.timeseries) {
          if (this.additionalTsFiles) {
            this.additionalTsFiles.forEach(addf => {
              if (this.files[range.data]) {
                if (this.files[addf]) {
                  this.files[range.data] = this.files[range.data].concat(
                    this.files[addf],
                  );
                }
              }
            });
          }

          /** Filter unwanted timeseries */
          range.timeseries = range.timeseries.filter(
            ts =>
              !ts.ts_shortname ||
              GraphHelper.getFile(this.files, range).some(
                tsfile => ts.ts_shortname === tsfile.ts_shortname,
              ),
          );
        }
      });

      this.drawSeries(selected);
      this.requestUpdate();
    }
    return this.files;
  }

  _setMaxValue(period) {
    const cfg = find(this.ranges, { value: period });
    if (GraphHelper.rangeObjectContains(this.files, cfg.data)) {
      let currentMax = -Infinity;
      let currentMin = Infinity;
      GraphHelper.getFile(this.files, cfg).forEach(ts => {
        ts.data.forEach(dp => {
          currentMax = Math.max(dp[1], currentMax);
          currentMin = Math.min(dp[1], currentMin);
        });
      });
      this.maxInitalValue = currentMax;
      this.minInitalValue = currentMin;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _getLastValue(data) {
    return data && data[0] ? last(last(data)) : null;
  }

  // eslint-disable-next-line class-methods-use-this
  _splitEventValues(data) {
    const artificalTs = [];
    data.forEach(ts => {
      ts.data.forEach(dp =>
        artificalTs.push({
          ts_name: dayjs(dp[0]).tz().format('L'),
          data: [dp],
        }),
      );
    });
    return artificalTs;
  }

  _getThreshold(ts, i, key, ploptions) {
    return merge(
      merge(
        {
          id: `${key}_${i}PL`,
          value: this._getLastValue(ts.data),
          width: 2,
          color: 'rgba(19, 19, 19, 1)',
          dashStyle: 'dash',
          zIndex: 111,
          label: {
            text: ts.ts_shortname
              ? ts.ts_shortname.replace('Cmd.', '')
              : ts.ts_name,
            align: 'right',
            y: 12,
            x: 0,
          },
        },
        ploptions,
      ),
      (this.threshholdMappings && this.threshholdMappings[ts.ts_shortname]) ||
        {},
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _getLevelData(data) {
    const lastValue = last(data);
    return data.length
      ? [
          [1, lastValue[1]],
          [2, lastValue[1]],
        ]
      : [];
  }

  _getThreshholds(
    graphOptions,
    list,
    key,
    label,
    ploptions = null,
    visible = true,
  ) {
    if (visible !== 'auto' && typeof visible === 'string') {
      visible = this.layerName === visible;
    }

    let color;
    list.forEach((ts, i) => {
      const al = this._getThreshold(ts, i, key, ploptions);
      color = al.color || 'rgba(255,255,255,0)';
      graphOptions.series.push({
        label: {
          enabled: true,
          connectorAllowed: true,
          style: {
            color: 'rgb(19,19,19)',
            fontWeight: 'normal',
          },
        },
        dashStyle: al.dashStyle,
        width: al.width,
        xAxis: 'alarmAxis',
        marker: { enabled: false },
        enableMouseTracking: false,
        linkedTo: key,
        showInLegend: false,
        data: this._getLevelData(ts.data),
        description: al.label.description,
        shortName: al.label.text,
        name:
          this.descriptionVisible && al.label.description
            ? al.label.description
            : al.label.text,
        color,
      });
    });
    graphOptions.series.push({
      name: label,
      color: list.length === 1 ? color : 'rgba(255,255,255,0)',
      id: key,
      visible,
      selected: visible,
    });
  }

  createGraphOptions() {
    const alarmFileName = this.alarms?.file;
    let alarmFile = alarmFileName
      ? compact(flatten(alarmFileName.map(file => this.files[file])))
      : null;
    const graphOptions = {
      chart: {
        //  zoomType: "x",
        animation: false,
        type: this.chartType,
        pinchType: undefined,
        zoomType: this.zoomType,
        panning: {
          enabled: false,
        },
      },
      boost: {
        enabled: this.boost,
        useGPUTranslations: false,
        allowForce: false,
      },
      series: [],
      plotOptions: {
        area: {
          threshold: -Infinity,
        },
        column: {
          groupPadding: 0,
        },
        series: {
          //  boostThreshold:0,
          //   turboThreshold:0,
          animation: false,
          gapSize: this.gapSize !== undefined ? this.gapSize : 1,
          label: {
            enabled: false,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: merge(
        {
          id: 'mainYAxis',
          plotLines: [],
        },
        this.yAxis,
      ),
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          ordinal: false,
          labels: {
            formatter() {
              return this.dateTimeLabelFormat === '%e. %b' &&
                this.tickPositionInfo.unitName === 'hour'
                ? `<b>${this.axis.defaultLabelFormatter.call(this)}</b>`
                : this.axis.defaultLabelFormatter.call(this);
            },
          },
        },
        {
          visible: false,
          min: 1,
          max: 2,
          id: 'alarmAxis',
        },
      ],
    };
    this.hasAlarms = false;

    // Read alarms/thresholds provided as station/timeseries attributes (e.g. Station, week.json, year.json)
    if (this.attributeThresholds.length) {
      this._addAttributeThresholds(graphOptions);
    }

    if (this.alarms?.stationAttributes) {
      alarmFile = [];
      this.alarms.items.forEach(item => {
        item.tsList.forEach(ts => {
          if (this.station[ts]) {
            alarmFile.push({
              ts_shortname: ts,
              data: [[new Date().getTime(), parseFloat(this.station[ts])]],
            });
          }
        });
      });
    }

    if (alarmFile && alarmFile.some(ts => ts.data.length > 0)) {
      let spare = true;
      this.alarms?.items?.forEach((item, i) => {
        const alarms = alarmFile.filter(ts =>
          item.tsList.includes(ts.ts_shortname),
        );
        if (alarms.some(ts => ts.data.length)) {
          let { active } = item;
          if (item.active === 'auto') {
            if (alarms[0].data[0][1] > this.maxInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          } else if (item.active === 'automin') {
            if (alarms[0].data[0][1] < this.minInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          }
          this._getThreshholds(
            graphOptions,
            alarms,
            `alerts${i}_`,
            item.label || this.i18n.t('alarmlevels'),
            null,
            active,
          );
          this.hasAlarms = true;
        }
      });
    }

    if (this.files[this.events]) {
      const defaultPlOptions = {
        width: 2,
        color: 'rgba(0, 86, 160, 1)',
        dashStyle: 'DashDot',
        label: {
          align: 'left',
          y: 12,
          x: 0,
        },
      };
      this._getThreshholds(
        graphOptions,
        this._splitEventValues(this.files[this.events]),
        'events_',
        'HW-Ereignisse',
        defaultPlOptions,
        false,
      );
    }
    return graphOptions;
  }

  // Searches through available data structures for attributes
  _findFile(fielditem) {
    // Check for station attributes (e.g. ww-me)
    if (this.station[fielditem.field]) return this.station;
    // Check for timeseries attributes (e.g. ww-brw))
    // Specify files to look at in app.json -> options -> thresholdFiles[]
    const res = this.thresholdFiles?.map(_file => {
      if (this.files && this.files[_file]) return _file;
      return null;
    });
    if (res) return first(this.files[first(res)]);
    // Nothing found, return undefined
    return res;
  }

  _addAttributeThresholds(graphOptions) {
    const _thresholds = [];

    this.attributeThresholds.forEach(item => {
      // Find desired field in files

      const _file = this._findFile(item);
      if (
        _file &&
        _file[item.field] &&
        _file[item.field] !== '' &&
        !Number.isNaN(_file[item.field])
      ) {
        _thresholds.push({
          ts_shortname: item.field,
          data: [[new Date().toISOString(), parseFloat(_file[item.field])]],
          label: item.label?.text || item.label,
          color: item.color,
          visible: item.visible,
          selected: item.selected,
          description: item.template
            ? template(item.description, _file)
            : item.description,
          shortName: item.label?.text || item.label,
        });
      }
    });
    if (_thresholds) {
      _thresholds.forEach((thres, i) => {
        graphOptions.series.push({
          xAxis: 'alarmAxis',
          marker: { enabled: false },
          enableMouseTracking: false,
          name: thres.label,
          label: {
            text: thres.label,
            enabled: true,
          },
          color: thres.color || 'rgba(255,255,255,0)',
          dashStyle: 'dash',
          id: `thres_${i}`,
          visible: thres.selected ?? true,
          selected: thres.selected ?? true,
          data: this._getLevelData(thres.data),
          description: thres.description,
          shortName: thres.label,
        });
      });
      this.hasAlarms = true;
    }
  }

  _getExtremes(attr) {
    if (typeof this[attr] === 'number') {
      return this[attr];
    }
    if (this[attr] && this.station[this[attr]]) {
      return this.station[this[attr]] ? Number(this.station[this[attr]]) : null;
    }
    return null;
  }

  defaultOptions() {
    const timezoneOffset = -dayjs
      .tz(
        dayjs().tz(),
        dayjs.defaultZone ? dayjs.defaultZone.name : dayjs.tz.guess(),
      )
      .utcOffset();

    const conf = merge(
      {
        title: {
          text: '',
        },
        subtitle: {
          text: this.subTitle || '',
          floating: true,
        },
        credits: this.creditOptions,
        events: {},
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().tz().format('LLLL')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD_kkmmss')}`,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        navigation: {
          enabled: false,
        },
        plotOptions: {
          series: {
            //  gapSize: 1,
            //  boostThreshold:0,
            //  turboThreshold:0,
            dataGrouping: {
              enabled: false,
            },
            showCheckbox: ViewPort.size !== SM,
            events: {
              checkboxClick(event) {
                event.preventDefault();
                if (event.checked) {
                  this.show();
                } else {
                  this.hide();
                }
              },
              legendItemClick(event) {
                event.preventDefault();
                if (this.visible) {
                  if (this.checkbox) {
                    this.checkbox.checked = false;
                  }
                  this.hide();
                } else {
                  if (this.checkbox) {
                    this.checkbox.checked = true;
                  }
                  this.show();
                }
              },
            },
          },
        },
        legend: this.legendOptions,
        tooltip: {
          shared: true,
          split: false,
        },
        time: {
          // eslint-disable-next-line no-unused-vars
          getTimezoneOffset() {
            return timezoneOffset;
          },
          //    dayjs: dayjs,
          //     timezone: dayjs.defaultZone ? dayjs.defaultZone.name : dayjs.guess(),
          //  useUTC: false
        },
        yAxis: {
          allowDecimals: !this.yAxisHideDecimals,
          reversed: this.yAxisReverse || false,
          minRange: this._getExtremes('yAxisMinRange'),
          softMax: this._getExtremes('yAxisMax'),
          softMin: this._getExtremes('yAxisMin'),
          opposite: false,
          title: {
            text: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          },
        },
        series: [],
      },
      this.createGraphOptions(),
    );
    return conf;
  }
}
