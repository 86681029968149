import dayjs from '@kisters/wcp-base/common/dayjsext';

import {
  Mix,
} from '@kisters/wcp-base/common';

import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Toast';

import '@kisters/wiski-web/components/ki-responsive-table/ki-responsive-table';

import '@kisters/wiski-web/components/ki-wcp-wwp-filter/ki-wcp-wwp-filter';
import '@kisters/wiski-web/components/common/ww-map-popup';
import '@kisters/wiski-web/components/common/ww-map-popup-base';
import '@kisters/wiski-web/components/common/ww-map-popup-precip';

import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-sparkline';

import {WwpOverview} from '@kisters/wiski-web/views';

@customElement('wwp-overview-albg')
export default class WwpOverviewAlbg extends Mix(WwpOverview
) {
  
  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('table', this.mapOrTable === 'table');
    // TODO rename ki-station-layer to ki-maker-layer or value layer ?
    // language=html
    if (this.currentLayer !== this.layerName) {
      this.getdataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        ${this.additionalContent()}

        <ki-searchbox
          placeholder="${this.i18n.t('stationsearch')}"
          .value="${this.searchContent}"
          @search="${e => {
            this._enableAutoZoom = true;
            this.searchContent = e.detail.value;
          }}"
        >
        </ki-searchbox>
        ${this.showCsvExport && this.mapOrTable === 'table'
          ? this.renderExportButton()
          : html``}

        ${this.mapOrTable === 'table'
        ? html`
            <ki-wcp-wwp-filter
                .favorites="${this.__favouriteStationIds}"
                .activeFilter="${this.filterContent}"
                .allStations="${this._dataLayer.data}"
                .stations="${this.filteredStations}"
                .filters="${this.quickFilters}"
                @change="${e => {
                this._enableAutoZoom = true;
                this.filterContent = JSON.stringify(e.detail.value);
                }}"
            >
            </ki-wcp-wwp-filter>`
        : html``}
        
        <ki-switcher
          id="map-table-switcher"
          .options="${this._switcherOptions}"
          .value="${this.mapOrTable}"
          @changed="${e => {
            this._enableAutoZoom = true;
            this.mapOrTable = e.detail.value;
          }}"
        ></ki-switcher>
        <ki-stack class="slide-left" .selected="${this.mapOrTable}">
          <div class="station-list" key="table">
            ${this.mapOrTable === 'table' ? this.renderTable() : html``}
          </div>
          <div class="map-container" key="map">
            ${this.mapOrTable === 'map' ? this.renderMap() : html``}
          </div>
        </ki-stack>
        ${this.mapOrTable === 'table'
        ? html``
        : html`
            <ki-legend
                closeable
                .options="${this.legendOptions}"
                @changed="${this.legendChanged}"
                id="overview-legend"
                >
            </ki-legend>`
        }
        <div class="lastUpdate" title="Version: ${this.version}">
          ${this.i18n.t('lastupdated')}:
          ${dayjs.tz(this._dataLayer.creationDateInMillis).format('L LT')}
          ${this.i18n.exists(encodeURIComponent(dayjs().tz().format('zzz')))
            ? this.i18n.t(encodeURIComponent(dayjs().tz().format('zzz')))
            : dayjs().tz().format('zzz')}
        </div>
      `
    );
  }

}
