import { TsDataPoint } from '../../common/timeseries';

export class WaterlevelTable {
  Stations: TsDataPoint[];

  NoAlarm: string[];

  Alarm1: string[];

  Alarm2: string[];

  Alarm3: string[];

  Unclassified: string[];

  constructor() {
    this.Stations = [];
    this.NoAlarm = [];
    this.Alarm1 = [];
    this.Alarm2 = [];
    this.Alarm3 = [];
    this.Unclassified = [];
  }
}
