/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Stroke, Style } from 'ol/style';
import Chart from 'ol-ext/style/Chart';
import dayjs from 'dayjs';
import StationsLayer from './StationsLayer';

export default class StationsChartLayer extends StationsLayer {
  set currentStation(val) {
    this.__currentStation = val;
    //  this.reload();
  }

  /** Set in layercfg -> chartConfig -> remarkAttribute */
  static hasRemarkOrIsSelected(attr, station, selected) {
    return selected || !!(station[attr] && station[attr] !== '');
  }

  /** Border color for MultiTs/piechart icon */
  static getBorderColor(
    remarkAttribute: String,
    station: Object,
    selected: Boolean,
  ): '#000' | 'rgb(0, 0, 224)' | '#333' {
    if (selected) return 'rgb(0, 0, 224)';
    if (
      StationsChartLayer.hasRemarkOrIsSelected(
        remarkAttribute,
        station,
        selected,
      )
    )
      return '#000';
    return '#333';
  }

  /** TODO: Apply layerconfig (LANUV-103) */
  static handleThreshold(item, config) {
    return Math.abs(item.ts_value) < config.chartConfig.threshold
      ? 'rgba(155, 229, 100, 1)'
      : 'rgba(209, 60, 38, 1)';
  }

  static isOutdated(outdatdedCfg, timestamp) {
    const outdated = dayjs(timestamp)
      .add(outdatdedCfg.time, outdatdedCfg.unit)
      .valueOf();
    const now = dayjs().valueOf();
    return outdated < now;
  }

  static getStationStyle(station, config, selected: Boolean) {
    const colors = Object.values(
      station.ts_values[config.chartConfig.parameter],
    ).map(item => {
      /** Inactive > Outdated > Threshold */
      if (config.chartConfig.inactive && station.INTERNET_AKTIV === 'no') {
        return '#ffe4e1';
      }
      if (
        config.chartConfig.outdated &&
        !StationsChartLayer.isOutdated(
          config.chartConfig.outdated,
          item.timestamp,
        ) &&
        !config.chartConfig.threshold
      )
        return 'rgba(52,164,235, 1)';
      if (config.chartConfig.outdated && !config.chartConfig.threshold)
        return 'rgba(209, 60, 38, 1)';
      if (
        config.chartConfig.outdated &&
        StationsChartLayer.isOutdated(
          config.chartConfig.outdated,
          item.timestamp,
        ) &&
        config.chartConfig.threshold
      )
        return `rgba(128, 128, 128, 1)`;
      if (config.chartConfig.threshold) {
        return StationsChartLayer.handleThreshold(item, config);
      }
      if (station.__tag === 'none') {
        return 'rgba(128, 128, 128, 1)';
      }
    });

    const remarkAttribute = config?.chartConfig?.remarkAttribute;

    const currentStationStyle = new Style({
      image: new Chart({
        colors,
        type: 'pie',
        radius: 10,
        data: colors.map(() => 1),
        rotateWithView: true,
        stroke: new Stroke({
          color: StationsChartLayer.getBorderColor(
            remarkAttribute,
            station,
            selected,
          ), // hasRemarkOrIsSelected(remarkAttribute, station) ? '#000' : '#333',
          width: StationsChartLayer.hasRemarkOrIsSelected(
            remarkAttribute,
            station,
            selected,
          )
            ? 3
            : 1,
        }),
      }),
      zIndex: 100,
    });

    currentStationStyle.sortPriority = colors.filter(
      x => x === 'rgba(209, 60, 38, 1)',
    ).length;

    return currentStationStyle;
  }

  // eslint-disable-next-line class-methods-use-this
  _getStationStyle(station, selected = false) {
    return StationsChartLayer.getStationStyle(station, this.config, selected);
  }

  set tagMarkers(val) {
    this.__tagMarkers = val;
    this.reload();
  }

  get tagMarkers() {
    return this.__tagMarkers;
  }
}
