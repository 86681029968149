import { getCurrentLang } from '../decorators/i18n';

interface SymbolOptions {
  decimalSymbol: String;
  thousandSymbol: String;
}

const formatterMap = new Map<string, Intl.NumberFormat>();

const vanillaMap = new Map<string, SymbolOptions>();

const customSymbols = {
  decimalSymbol: '.',
  thousandSymbol: ',',
};

const decimalPlaceholder = '#';

let enforceCustomSymbols = false;

const injectThousandSymbol = (
  formatted: String,
  vanillaSymbol: String | undefined,
  customSymbol: String,
  decimalSymbol: String,
) => {
  if (vanillaSymbol !== undefined) {
    return formatted.replaceAll(vanillaSymbol, customSymbol);
  }
  const split = formatted.split(decimalSymbol);
  let formatTemp = split.length() > 1 ? `${decimalSymbol}${split[1]}` : '';

  const integerFormat = split[0];
  const { length } = integerFormat;

  // eslint-disable-next-line no-plusplus
  for (let i = length - 1; i >= 0; i--) {
    formatTemp = `${(length - i) % 3 === 0 ? customSymbol : ''}${
      integerFormat[i]
    }${formatTemp}`;
  }

  return formatTemp;
};

const getVanillaFormat = (locale: String): SymbolOptions => {
  if (vanillaMap.has(locale)) {
    return vanillaMap.get(locale);
  }
  const numberWithSeperators = 1000.5;
  const vanillaFormat = new Intl.NumberFormat(locale).format(
    numberWithSeperators,
  );
  const { length } = vanillaFormat;

  const vanillaDecimalSymbol = vanillaFormat.substring(length - 2, length - 1);

  const vanillaThousandSymbol = vanillaFormat.substring(1, 2);

  const vanillaSymbols = {
    decimalSymbol: vanillaDecimalSymbol,
    thousandSymbol:
      vanillaThousandSymbol !== '0' ? vanillaThousandSymbol : undefined,
  };

  vanillaMap.set(locale, vanillaSymbols);

  return vanillaSymbols;
};

const getKey = (language: string, options: Intl.NumberFormatOptions): string =>
  `${language}${options.compactDisplay}${options.currency}${options.currencyDisplay}${options.currencySign}${options.localeMatcher}${options.maximumFractionDigits}${options.minimumFractionDigits}${options.maximumSignificantDigits}${options.minimumIntegerDigits}${options.minimumSignificantDigits}${options.notation}${options.signDisplay}${options.style}${options.unit}${options.unitDisplay}${options.useGrouping}`;

export const setCustomSymbols = (options: {
  decimalSymbol: String | undefined;
  thousandSymbol: String | undefined;
}) => {
  if (options.decimalSymbol !== undefined) {
    customSymbols.decimalSymbol = options.decimalSymbol;
    enforceCustomSymbols = true;
  }
  if (options.thousandSymbol !== undefined) {
    customSymbols.thousandSymbol = options.thousandSymbol;
    enforceCustomSymbols = true;
  }
};

export const formatNumberForLocale = (
  number: number | null | undefined | string,
  locale: string,
  options: Intl.NumberFormatOptions | undefined = undefined,
): string => {
  if (
    number === undefined ||
    number === null ||
    number === '' ||
    Number.isNaN(number)
  ) {
    return '';
  }
  const key = getKey(locale, options ?? {});
  let formatter: Intl.NumberFormat;
  if (formatterMap.has(key)) {
    formatter = formatterMap.get(key)!;
  } else {
    formatter = new Intl.NumberFormat(locale, options);
    formatterMap.set(key, formatter);
  }

  let formatted = formatter.format(number);

  if (enforceCustomSymbols) {
    const vanillaSymbols = getVanillaFormat(locale);

    formatted = formatted.replace(
      vanillaSymbols.decimalSymbol,
      decimalPlaceholder,
    );

    formatted = injectThousandSymbol(
      formatted,
      vanillaSymbols.thousandSymbol,
      customSymbols.thousandSymbol,
      decimalPlaceholder,
    );

    formatted = formatted.replace(
      decimalPlaceholder,
      customSymbols.decimalSymbol,
    );
  }

  return formatted;
};

export const formatNumber = (
  number: number | null | undefined | string,
  options: Intl.NumberFormatOptions,
): string => formatNumberForLocale(number, getCurrentLang(), options);
