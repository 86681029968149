export const getDefaultJsDatepickerOptions = (
  id: string,
  i18n: { t: (i: string) => string },
  dateFormatter: (input: { value: string }, date: number | string) => void,
  onHide: () => void,
) => ({
  id,
  startDay: 1, // 0 = Sunday, 1 = Monday
  showAllDates: true,
  position: 'br',
  customDays: [
    i18n.t('sundayAbbr'),
    i18n.t('mondayAbbr'),
    i18n.t('tuesdayAbbr'),
    i18n.t('wednesdayAbbr'),
    i18n.t('thursdayAbbr'),
    i18n.t('fridayAbbr'),
    i18n.t('saturdayAbbr'),
  ],
  customMonths: [
    i18n.t('January'),
    i18n.t('February'),
    i18n.t('March'),
    i18n.t('April'),
    i18n.t('May'),
    i18n.t('June'),
    i18n.t('July'),
    i18n.t('August'),
    i18n.t('September'),
    i18n.t('October'),
    i18n.t('November'),
    i18n.t('December'),
  ],
  overlayButton: i18n.t('Submit'),
  overlayPlaceholder: i18n.t('year'),
  formatter: dateFormatter,
  onHide,
});
