import get from 'lodash-es/get';

/** TODO: Unit test */
export function getFromItem(
  item: object,
  field: Array<string> | string,
): string {
  if (!Array.isArray(field)) {
    return get(item, field);
  }
  return field.map(f => get(item, f)).filter(i => i !== undefined)[0];
}
