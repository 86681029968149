/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable max-classes-per-file */

import { merge, first, flattenDeep, last } from 'lodash-es';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix, PropertyDefaultValue } from '@kisters/wcp-base/common';
import {
  i18nMixin,
  loaderMixin,
  getQueryObject,
} from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { KIWIS } from '@kisters/wcp-water/kiwis';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import nls from '../../locales/index';
import '@kisters/wcp-water/components/ki-ts-viewer/ki-ts-viewer';
import '@kisters/wcp-water/components/ki-ts-viewer/ki-ts-list-select';
import {
  findTsByString,
  findFirstTsByString,
  filterOriginalTs,
} from '../../common/timeseries';

let KiWISInst;
const graphRange = {
  min: dayjs().subtract(dayjs.duration('P1Y')).valueOf(),
  max: dayjs().valueOf(),
};

@customElement('wwp-station-ts-viewer')
export default class WwpStationTsViewer extends Mix(
  LitElement,
  PropertyDefaultValue,
  loaderMixin,
  [i18nMixin, { nls }],
) {
  static styles = css``;
  // kiwis = new KIWIS({ basePath: 'http://localhost:3003/services/KiWIS/KiWIS', datasource: 1 });

  static get properties() {
    return {
      stationNo: String,
      stationId: String,
      parameterNo: String,
      parameterName: String,
      groupBy: { type: String, default: 'stationparameter_no' },
      // mainTs, alarmTs and presetTs are provided as patterns via presetTsFilter supporting wildcards
      // patterns will be resolved to available timeseries
      // only available timeseries will be displayed
      mainTs: Array,
      // Preselected timeseries, only loaded on first init
      presetTs: Array,
      alarmTs: Array,
      periods: {
        type: Array,
        default: [
          ['last1month', 'last6month', 'last1year', 'last5year', 'last10year'],
        ],
      },
      // Buffer for selected timeseries
      selectedTs: { type: Array, default: [] },
    };
  }

  onAfterEnter(location) {
    console.warn(
      'Component is OBSOLETE and will be removed in future releases!',
    );
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
      'remarkAttr',
    ]);
    console.log(params);
    this.title = params.options?.title || '';
    this.parameterName = params.options?.parameter;
    this.parameterNo = params.options?.parameterNo;
    this.groupBy = params.options?.groupBy;
    this.dynMenu = params.options?.dynMenu;
    this.mainTs = params.options?.mainTs;
    this.periods = params.options?.periods;
    this.alarmTs = params.options?.alarmTs;
    this.tsFilter = params.options?.tsFilter;
    this.presetTs = params.options?.presetTs;
    this.stationLabel = params.options?.stationLabel;
    this.alarmTs = params.options?.alarmTs || [];
    this.stationId = params.stationId;

    if (params?.options?.defaultPeriod) {
      graphRange.min = dayjs()
        .subtract(
          dayjs.duration(params.options.defaultPeriod || 'P1Y').asMinutes(),
          'minute',
        )
        .valueOf();
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    KiWISInst = new KIWIS({ ...this.dynMenu.kiwis });
    await this.fetchTsList();
  }

  async fetchTsList() {
    const queryparams = {
      metadata: true,
      station_id: this.stationId,
    };
    queryparams[this.groupBy] = this.parameterNo;
    let list = await KiWISInst.getTimeseriesList(queryparams, [
      'ts_id',
      'ts_name',
      'ts_shortname',
      'coverage',
      'ts_path',
      'ca_ts',
      'stationparameter_name',
      'stationparameter_longname',
      'stationparameter_no',
      'ts_unitsymbol',
    ]);

    // Remove empty ts and filter by provided definition
    list = list.filter(ts => ts.to !== '');
    if (this.tsFilter) {
      list = flattenDeep(
        this.tsFilter?.map(pattern => {
          const res = findTsByString(pattern, list);
          // Filter all timeseries which are original timeseries (ending with .O)
          if (this.filterOriginal) return filterOriginalTs(res);
          return res;
        }),
      );
    }
    // Resolve timeseries objects from patterns
    this._resolveTsFromPatterns(list);

    // Further checks
    list.map(item => {
      // Detect alarmTs

      item.isBinary = item.ts_shortname.includes('.Binary.');

      if (
        this.thresholdTsList &&
        Array.isArray(this.thresholdTsList) &&
        this.thresholdTsList
          .flatMap(t => t.ts_shortname)
          .includes(item.ts_shortname)
      )
        item.isThreshold = true;
      // Mark as preselected to keep ts-selector in sync with chart
      if (
        this.presetTsList
          .flatMap(p => p.ts_shortname)
          ?.includes(item.ts_shortname)
      )
        item.preselected = true;
    });
    this.tsList = list;
    this.requestUpdate();
  }

  // Convert provided patterns to available timeseries
  _resolveTsFromPatterns(l = this.tsList) {
    // Find TS
    this.mainTsObj =
      first(flattenDeep(this.mainTs?.map(ts => findTsByString(ts, l)))) ||
      first(l) ||
      {};
    this.thresholdTsList = flattenDeep(
      this.alarmTs?.map(ts => findTsByString(ts, l)),
    );
    this.presetTsList = flattenDeep(
      this.presetTs?.map(ts => findTsByString(ts, l)),
    );
    console.log(
      'Filter Resolving Results (Main/Alarm/Preset): ',
      this.mainTsObj,
      this.thresholdTsList,
      this.presetTsList,
    );
  }

  render() {
    // language=html
    return this.tsList?.length
      ? html`
          <ki-ts-viewer
            .getTsData="${this.getTsData}"
            .chartOptions="${this.chartOptions}"
            .range="${graphRange}"
            .customAttributes="${this.mainTsObj}"
            .tsList="${this.tsList}"
            .periods="${this.periods}"
          >
            <div slot="title">${this.title}</div>
            <div slot="actions">
              <ki-ts-list-select
                id="ts-selctor"
                .tsList="${this.tsList}"
                .presetTs="${this.presetTsList}"
                .mainTs="${this.mainTsObj?.ts_path}"
                @change="${this.updateTs}"
              ></ki-ts-list-select>
            </div>
          </ki-ts-viewer>
        `
      : // TODO: Loading spinner
        html`Loading...`;
  }

  get _tsSelect() {
    return getQueryObject(this, '#ts-selctor');
  }

  // eslint-disable-next-line class-methods-use-this
  get chartOptions() {
    return merge(
      {
        chart: {
          zoomType: 'x',
        },
        boost: {
          useGPUTranslations: true,
          seriesThreshold: 5,
        },
        credits: {
          enabled: false,
        },

        exporting: {
          chartOptions: {
            title: {
              text: `${this.parameterName}`,
            },
            subtitle: {
              text: `${this.stationNo}-${this.parameterName}`,
            },
          },
          filename: `${this.stationLabel}-${this.parameterName}`,
          fallbackToExportServer: false,
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
            // Disable hover animation
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        series: this.selectedTs?.map(ts => ({
          label: {
            enabled: findFirstTsByString(ts, this.tsList).isThreshold ?? false,
          },
          ts,
          id: ts,
          mainTs: ts === this.mainTsObj.ts_path,
          name: findFirstTsByString(ts, this.tsList).ts_name || ts,
          step: findFirstTsByString(ts, this.tsList).isThreshold
            ? 'left'
            : undefined,
          dashStyle: findFirstTsByString(ts, this.tsList).isThreshold
            ? 'Dash'
            : 'Solid',
        })),
        title: {
          text: '',
        },
        yAxis: {
          title: {
            // Hide Y-Axis-label if no information is available
            text: this.mainTsObj
              ? `${
                  this.mainTsObj.stationparameter_longname ||
                  this.mainTsObj.stationparameter_name
                } [${this.mainTsObj.ts_unitsymbol}]`
              : '',
          },
        },
      },
      {},
    );
  }

  async getTsData({ range, ts }) {
    // Keep selected timeframe
    const tsObj = findFirstTsByString(ts, this.tsList);

    graphRange.min = range.min;
    graphRange.max = range.max;

    const { ts_path } = tsObj;
    let data;
    // Get whole dataset if ts is Alarm
    // or just selected timeframe if timeseries is regular
    if (tsObj.isThreshold) {
      data = await KiWISInst.getTimeseriesValues(
        {
          ts_path,
          period: 'complete',
        },
        null,
        'json',
      );
      // Add pivot point for displaying alarmTs
      this._transformAlarmTs(data);
    } else if (tsObj.isBinary) {
      data = await KiWISInst.getTimeseriesValues(
        {
          metadata: true,
          md_returnfields: 'ts_id,ensembledate,ensembledispatchinfo',
          ts_path,
        },
        null,
        'json',
      );
      if (data[0]?.ensembledate) {
        const ensTs = new Date(data[0]?.ensembledate).getTime();
        if (ensTs > graphRange.max) {
          graphRange.max = ensTs + 7 * 24 * 60 * 60 * 1000;
        }
      }
    } else {
      data = await KiWISInst.getTimeseriesValues(
        {
          ts_path: `${ts_path};compress(2000)`,
          from: range.min,
          to: range.max,
        },
        null,
        'json',
      );
    }
    this.dispatchEvent(
      new CustomEvent('changedate', {
        bubbles: true,
        detail: {
          fromTime: range.min,
          toTime: range.max,
        },
      }),
    );
    return data[0].data;
  }

  _transformAlarmTs(data) {
    // Add left-side limit
    data[0].data.unshift([
      new Date('1950-1-1').toISOString(),
      data[0].data[0][1],
    ]);
    // Add right-side limit
    data[0].data.push([
      new Date(Date.now()).toISOString(),
      last(data[0].data)[1],
    ]);
  }

  updateTs(evt) {
    this.selectedTs = [...evt.detail.value];
  }
}
