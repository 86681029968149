/* eslint-disable max-classes-per-file */
// @ts-nocheck
// view stack
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './ki-breadcrumbs';
import { getCurrentLocation } from './routing/ki-router';
import { getNavItemLabel } from './services/ki-app-config-service';

const getPreviousSiblings = route => {
  if (!route.parent || !route.parent.children) {
    return [];
  }
  const siblings = route.parent.children.filter(
    child =>
      child.path.length < route.path.length &&
      route.path.startsWith(child.path),
  );
  siblings.sort((a, b) => a.path.length > b.path.length);
  return siblings;
};

const getFullPath = route => {
  if (!route || !route.path || route.path === '' || route.path === '/') {
    return '';
  }
  return `${getFullPath(route.parent)}${route.path}`;
};

const getCrumb = (route, params, search) => {
  let fullPath = getFullPath(route);
  let { label } = route;
  Object.entries(params).forEach(([key, value]) => {
    fullPath = fullPath.replace(`:${key}`, value);
    label = getNavItemLabel(fullPath) || label.replace(`\${${key}}`, value);
  });
  return {
    path: `${fullPath}${search || ''}`,
    label,
  };
};

const recursivelyGetCrumbs = (route, params, search) => {
  if (route.path === '') {
    return [];
  }
  const siblingsCrumbs = getPreviousSiblings(route)
    .filter(sibling => sibling.label)
    .map(sibling => getCrumb(sibling, params, search));
  const parentCrumbs = recursivelyGetCrumbs(route.parent, params, search);

  if (route.label) {
    return [
      ...parentCrumbs,
      ...siblingsCrumbs,
      getCrumb(route, params, search),
    ];
  }
  return [...parentCrumbs, ...siblingsCrumbs];
};

const calculateBreadCrumbs = location => {
  const { route, params, search } = location;

  return recursivelyGetCrumbs(route, params, search);
};

@customElement('ki-app-breadcrumbs')
export default class KiAppBreadcrumbs extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
    }
  `;

  @property({ type: Number })
  maxitems = Infinity;

  @property({ type: Array })
  breadCrumbs = [];

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    window.addEventListener(
      'vaadin-router-location-changed',
      this.handleVaadinRouterEvent,
    );
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener(
      'vaadin-router-location-changed',
      this.handleVaadinRouterEvent,
    );
  }

  handleVaadinRouterEvent = event => {
    this._setBreadCrumbs(event.detail.location);
  };

  _setBreadCrumbs(location) {
    this.breadCrumbs = calculateBreadCrumbs(location).slice(0, this.maxitems);
  }

  render() {
    // language=html
    return html`<ki-breadcrumbs .items="${this.breadCrumbs}"></ki-breadcrumbs>`;
  }

  firstUpdated() {
    this._setBreadCrumbs(getCurrentLocation());
  }
}
