/* eslint-disable max-classes-per-file */
import { KiWwpGraph } from '@kisters/wiski-web/components';
import { customElement } from 'lit/decorators.js';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import {
  last,
  merge,
  find,
  head,
  groupBy,
  flatten,
  orderBy,
  compact,
  first,
} from 'lodash-es';
import {
  SM,
  ViewPort,
  responsiveMixin,
  i18nMixin,
  queryParamMixin,
} from '@kisters/wcp-base/decorators';
import {
  LoaderMixin,
  template,
  copyTextToClipboard,
  Mix,
} from '@kisters/wcp-base/common';

@customElement('ki-wwp-graph-albg')
export default class KiWwpGraphAlbg extends Mix(KiWwpGraph) {
  async fetchData() {
    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));

    const dataToFetch = [];

    this.station.timeseries.forEach(ts => {
      if (ts.station_parameter === this.station_parameter) {
        dataToFetch.push(ts.href);
      } else if (this.additionalTsFiles) {
        this.additionalTsFiles.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
    });

    await Promise.all(
      dataToFetch.map(async href => {
        _files[last(href.split('/'))] = await this.api.getTsData(href);
      }),
    );

    this.files = _files;
    let selected = find(this.ranges, { selected: true });
    const yearcfg = find(this.ranges, range =>
      ['P1Y', 'PoR'].includes(range.value),
    );
    if (yearcfg && this.files[yearcfg.data]) {
      const tsdata = this.files[yearcfg.data][0].data;
      if (tsdata && tsdata[0]) {
        this.coverage = {
          max: last(tsdata)[0],
          min: tsdata[0][0],
        };
      }
    }
    selected = this._selectedPeriod || selected.value || this.ranges[0].value;
    if (!find(this.ranges, { value: selected })) {
      selected = this.ranges[0].value;
    }
    this._selectedPeriod = selected;
    this._setMaxValue(selected);
    this.createChart();

    this.ranges.forEach(range => {
      if (range.timeseries) {
        range.timeseries = range.timeseries.filter(
          ts =>
            !ts.ts_shortname ||
            this.files[range.data].some(
              tsfile => ts.ts_shortname === tsfile.ts_shortname,
            ),
        );
      }
    });
    if (this.offset_timeseries) {
      Object.entries(this.files).forEach((file: [string, any]) => {
        file[1].forEach((ts, i) => {
          if (this.offset_timeseries.includes(ts.ts_shortname)) {
            this.files[file[0]][i].data = this.files[file[0]][i].data.map(
              item => {
                let _value;
                if (item[2] !== null) _value = parseFloat((+item[2]).toFixed(2));
                else if (item[1] !== null)
                  _value = parseFloat(
                    (this.station[this.albg_offset_value] - item[1]).toFixed(2),
                  );
                else _value = null;
                return [item[0], _value];
              },
            );
          }
        });
      });
    }

    this.drawSeries(selected);
    this.requestUpdate();
    return this.files;
  }

  // eslint-disable-next-line consistent-return
  _getSeries(cfg, data, minDate, maxDate = Infinity) {
    if (cfg.timeseries) {
      const tslist = [];
      cfg.timeseries.forEach((tscfg, i) => {
        if (tscfg.ts_shortname_min && tscfg.ts_shortname_max) {
          const minTs = find(data, {
            ts_shortname: tscfg.ts_shortname_min,
          });
          const maxTs = find(data, {
            ts_shortname: tscfg.ts_shortname_max,
          });
          const minIdx = groupBy(minTs.data, head);
          const tsdata = [];

          maxTs.data.forEach(dp => {
            const ts = dp[0];
            const tsms = new Date(ts).getTime();
            const max = dp[1];
            const min = minIdx[ts] ? minIdx[ts][0][1] : null;
            if (tsms >= minDate && tsms <= maxDate) {
              tsdata.push([tsms, min, max]);
            }
          });

          tslist.push(
            merge(
              {
                data: tsdata,
                id: `data_${i}`,
                name: this.parameter_label,
                color: 'rgba(0, 86, 160, 0.3)',
                type: 'arearange',
                fillOpacity: 0.3,
                xAxis: 'mainXAxis',
                yAxis: 0,
                //   gapSize: 2,
                selected: true,
                marker: {
                  enabled: false,
                },
                tooltip: {
                  valueSuffix: ` ${minTs.ts_unitsymbol}`,
                  xDateFormat: '%d.%m.%Y',
                },
              },
              tscfg.options,
            ),
          );
        } else {
          const ts = find(data, { ts_shortname: tscfg.ts_shortname });
          if (ts) {
            // if(tscfg.valueOffset){
            //   ts.data = ts.data.map(item=> [item[0], item[1] !== null ? parseFloat((this.station[tscfg.valueOffset]-item[1]).toFixed(2)) : null]);
            // }
            if (tscfg.minDate) {
              minDate = dayjs()
                .tz()
                .subtract(dayjs.duration(tscfg.minDate))
                .valueOf();
            }
            tslist.push(
              merge(
                {
                  data: this._formatGraphData(ts.data, minDate, maxDate),
                  id: `data_${i}`,
                  name: this.parameter_label,
                  color: '#0056a0',
                  xAxis: 'mainXAxis',
                  selected: true,
                  marker: {
                    radius: 1,
                    enabled: false,
                  },
                  tooltip: {
                    valueSuffix: ` ${ts.ts_unitsymbol}`,
                  },
                },
                tscfg.options,
              ),
            );
          }
        }
      });
      return tslist;
    }
    if (data[0]) {
      const options = cfg.options || {};
      const ts = data[0];
      return [
        merge(
          {
            data: this._formatGraphData(ts.data, minDate, maxDate),
            id: 'data',
            color: '#0056a0',
            name: this.parameter_label,
            xAxis: 'mainXAxis',
            selected: true,
            marker: {
              radius: 1,
              enabled: true,
            },
            tooltip: {
              valueSuffix: ` ${ts.ts_unitsymbol}`,
              xDateFormat: '%d.%m.%Y %H:%M',
            },
          },
          options,
        ),
      ];
    }
  }

  createGraphOptions() {
    let offsetValue: number;
    if (this.albg_offset_value)
      offsetValue = this.station[this.albg_offset_value];

    const alarmFileName = this.alarms?.file;
    const alarmFile = alarmFileName
      ? compact(flatten(alarmFileName.map(file => this.files[file])))
      : null;
    const graphOptions = {
      chart: {
        //  zoomType: "x",
        animation: false,
        type: this.chartType,
        pinchType: undefined,
        zoomType: 'x',
        panning: {
          enabled: false,
        },
      },
      boost: {
        useGPUTranslations: true,
      },
      series: [],
      plotOptions: {
        area: {
          threshold: -Infinity,
        },
        column: {
          groupPadding: 0,
        },
        series: {
          animation: false,
          gapSize: this.gapSize !== undefined ? this.gapSize : 1,
          label: {
            enabled: false,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: [
        {
          reversed: this.yAxisReverse || false,
          minRange: this._getExtremes('yAxisMinRange'),
          softMax: this._getExtremes('yAxisMax'),
          softMin: this._getExtremes('yAxisMin'),
          opposite: false,
          title: {
            text: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          },
        },
        {
          opposite: true,
          linkedTo: 0,
          labels: {
            formatter() {
              return offsetValue
                ? parseFloat(offsetValue - this.value).toFixed(2)
                : this.value;
            },
          },
          title: {
            text: this.yAxisLabel_secondary,
          },
        },
      ],
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          ordinal: false,
          labels: {
            formatter() {
              return this.dateTimeLabelFormat === '%e. %b' &&
                this.tickPositionInfo.unitName === 'hour'
                ? `<b>${this.axis.defaultLabelFormatter.call(this)}</b>`
                : this.axis.defaultLabelFormatter.call(this);
            },
          },
        },
        {
          visible: false,
          min: 1,
          max: 2,
          id: 'alarmAxis',
        },
      ],
    };
    this.hasAlarms = false;

    // Read alarms/thresholds provided as station/timeseries attributes (e.g. Station, week.json, year.json)
    if (this.attributeThresholds) {
      this._addAttributeThresholds(graphOptions);
    }

    if (alarmFile && alarmFile.some(ts => ts.data.length > 0)) {
      let spare = true;
      this.alarms?.items?.forEach((item, i) => {
        const alarms = alarmFile.filter(ts =>
          item.tsList.includes(ts.ts_shortname),
        );
        if (alarms.some(ts => ts.data.length)) {
          let { active } = item;
          if (item.active === 'auto') {
            if (alarms[0].data[0][1] > this.maxInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          }
          this._getThreshholds(
            graphOptions,
            alarms,
            `alerts${i}_`,
            item.label || this.i18n.t('alarmlevels'),
            null,
            active,
          );
          this.hasAlarms = true;
        }
      });
    }

    if (this.files[this.events]) {
      const defaultPlOptions = {
        width: 2,
        color: 'rgba(0, 86, 160, 1)',
        dashStyle: 'DashDot',
        label: {
          align: 'left',
          y: 12,
          x: 0,
        },
      };
      this._getThreshholds(
        graphOptions,
        this._splitEventValues(this.files[this.events]),
        'events_',
        'HW-Ereignisse',
        defaultPlOptions,
        false,
      );
    }
    return graphOptions;
  }

  drawSeries(period, min, max) {
    const cfg = find(this.ranges, { value: period }) || this.ranges[0];

    if (cfg.yearSelector) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    if (
      this.station.BODY_RESPONSIBLE &&
      this.station.BODY_RESPONSIBLE === 'WSV' // TODO: Remove LANUV?! artifact
    ) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    this.chart.series
      .filter(ser => ser.userOptions.id && ser.userOptions.id.includes('data'))
      .forEach(ser => ser.remove(false));
    const minDate = min || this._getTimestampFromDuration(period);
    let maxDate = max || this._getTimestampFromDuration();
    const xAxis = this.chart.get('mainXAxis');
    this.chart.zoomOut();
    if (cfg.yAxisLabel) {
      this.yAxisLabel = cfg.yAxisLabel;
      this.shadowRoot.getElementById('dataTable').columns = this.tablecolumns;
    }
    cfg.timeseries &&
      cfg.timeseries.forEach(ts => {
        if (ts.maxDate) {
          maxDate = this._getTimestampFromDuration(ts.maxDate);
        }
      });

    if (cfg.plotLines) {
      cfg.plotLines.forEach(pl => {
        const _pl = { ...pl };
        _pl.id = `dynamic_${pl.id}`;
        _pl.value = this._getTimestampFromDuration(pl.value);
        if (pl.filter) {
          cfg.timeseries.some(item => item.ts_shortname === pl.filter) &&
            xAxis.addPlotLine(_pl);
        } else {
          xAxis.addPlotLine(_pl);
        }
      });
    } else {
      xAxis.plotLinesAndBands.forEach(
        pl => pl.id.includes('dynamic_') && xAxis.removePlotLine(pl.id),
      );
    }

    xAxis.removePlotLine('pz');
    xAxis.removePlotLine('trend');
    if (cfg.showforcast) {
      xAxis.setExtremes(null, null, false, false);
      this.drawForecast();
    } else {
      xAxis.setExtremes(minDate, maxDate, false, false);
    }
    this.chart.get('data') && this.chart.get('data').remove(false);
    if (this.files[cfg.data]) {
      const series = this._getSeries(
        cfg,
        this.files[cfg.data],
        minDate,
        maxDate,
      );
      // eslint-disable-next-line no-nested-ternary
      const tabledata = cfg.tableTemplate
        ? this.getTableValuesFromTemplate(
            cfg,
            this.files[cfg.data],
            minDate,
            maxDate,
          )
        : Array.isArray(series)
        ? series[0]
        : series;
      if (tabledata) {
        // Sort and format
        console.time('start');
        this.orderedTableData = orderBy(tabledata.data, [0], ['desc']).map(
          item => {
            const vals = [];
            item.forEach((_val, i) => {
              if (i > 0) {
                vals.push(this._formatNumber(_val));
              }
            });
            if (vals.length === 3) {
              vals[1] = `<b>${vals[1]}</b>`;
            }
            const val = vals.join(' - ');

            return {
              timestamp: dayjs(item[0]).format(cfg.dateformat || 'L LT'),
              value: val,
            };
          },
        );
        console.timeEnd('start');
        this.shadowRoot.getElementById('dataTable').data =
          this.orderedTableData;
      }
      series.forEach((ts, i) => {
        this.chart.addSeries(ts, i === series.length - 1);
      });
    }
  }
}
