// eslint-disable-next-line max-classes-per-file
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Cluster } from 'ol/source';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import CanvasVectorLayerRenderer from 'ol/renderer/canvas/VectorLayer';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import Chart from 'ol-ext/style/Chart';

// support hide feature;
class _CanvasVectorLayerRenderer extends CanvasVectorLayerRenderer {
  // eslint-disable-next-line consistent-return,camelcase
  renderFeature(feature, squaredTolerance, styles, builderGroup, optTransform) {
    if (feature.hide) {
      return false;
    }
    super.renderFeature(
      feature,
      squaredTolerance,
      styles,
      builderGroup,
      optTransform,
    );
  }
}

/*
 * support hide features according legends
 * render features for stations with one function
 * */
export default class StationsLayer extends VectorLayer {
  tagProperty = '__tag'; // TODO

  constructor(options) {
    options = {
      updateWhileInteracting: true,
      updateWhileAnimating: true,
      source: new VectorSource(),
      stations: [],
      ...options,
    };
    super(options);
    this.changeCounter = 0;
    this.setProperties({
      name: options.name,
      displayInLayerControl: options.displayInLayerControl,
      timeActive: options.timeActive || false,
      group: options.group,
    });
    this.selection = [];
    Object.assign(this, options);
  }

  set stations(val) {
    this.__stations = val;
    this.reload();
  }

  get stations() {
    return this.__stations;
  }

  set legends(legends) {
    this._legends = legends.concat(['_default']);
    this.reload();
  }

  reload() {
    const { config } = this;
    const source = this.getSource();
    source.clear();
    if (this.stations && this.stations.length) {
      this.changeCounter += 1;
      if (config?.clusterStations?.enabled) {
        const _clusterMarker = config.clusterStations.marker;
        const clusterMarker = {
          backgroundColor: _clusterMarker.backgroundColor || '#aaa',
          color: _clusterMarker.color || '#000',
          fontSize: _clusterMarker.fontSize || 1,
          stroke: {
            color: _clusterMarker.stroke?.color || '#aaa',
            width: _clusterMarker.stroke?.width || 0,
          },
          radius: _clusterMarker.radius || 9,
          border: {
            color: _clusterMarker.border?.color || '#aaa',
            width: _clusterMarker.border?.width || 1,
          },
        };
        const stationSource = new VectorSource({
          features: this._createStationFeatures(this.stations),
        });
        const clusterSource = new Cluster({
          distance: 30,
          minDistance: 40,
          source: stationSource,
        });
        this.setSource(clusterSource);
        const styleCache = {};
        if (config?.clusterStations?.marker?.type === 'pie') {
          this.setStyle(feature => {
            const _features = feature.get('features');
            const size = _features.length;
            if (size === 1) return _features[0].getStyle();
            const colors = _features.map(f =>
              f.getStyle().getImage().getFill().getColor(),
            );
            const count = {};
            colors.forEach(c => {
              count[c] = (count[c] || 0) + 1;
            });
            const iconColors = Object.keys(count);
            const iconData = Object.values(count);

            const style = new Style({
              image:
                iconData.length > 1
                  ? new Chart({
                      type: 'pie',
                      radius: clusterMarker.radius,
                      data: iconData,
                      colors: iconColors,
                      stroke: new Stroke({
                        color: clusterMarker.border.color,
                        width: clusterMarker.border.width,
                      }),
                    })
                  : new CircleStyle({
                      radius: clusterMarker.radius,
                      stroke: new Stroke({
                        color: clusterMarker.border.color,
                        width: clusterMarker.border.width,
                      }),
                      fill: new Fill({
                        color: iconColors[0],
                      }),
                    }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: clusterMarker.color,
                }),
                scale: clusterMarker.fontSize,
                stroke: new Stroke({
                  color: clusterMarker.stroke.color,
                  width: clusterMarker.stroke.width,
                }),
              }),
            });
            return style;
          });
        } else {
          this.setStyle(feature => {
            const _features = feature.get('features');
            const size = _features.length;
            if (size === 1) return _features[0].getStyle();
            let style = styleCache[size];
            if (!style) {
              style = new Style({
                image: new CircleStyle({
                  radius: clusterMarker.radius,
                  stroke: new Stroke({
                    color: clusterMarker.border.color,
                    width: clusterMarker.border.width,
                  }),
                  fill: new Fill({
                    color: clusterMarker.backgroundColor,
                  }),
                }),
                text: new Text({
                  text: size.toString(),
                  fill: new Fill({
                    color: clusterMarker.color,
                  }),
                  scale: clusterMarker.fontSize,
                  stroke: new Stroke({
                    color: clusterMarker.stroke.color,
                    width: clusterMarker.stroke.width,
                  }),
                }),
              });
              styleCache[size] = style;
            }
            return style;
          });
        }
      } else {
        this.setSource(
          new VectorSource({
            features: this._createStationFeatures(this.stations),
          }),
        );
      }
      this._processFeaturesWithLegend();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _filterStations(stations) {
    return stations.filter(station => {
      const info = this._getStationInfos(station);
      const longLatMissed =
        (!info.longitude && !info.latitude) ||
        info.longitude > 180 ||
        info.longitude < -180 ||
        info.latitude < -90 ||
        info.latitude > 90;
      return !longLatMissed;
    });
  }

  _getStationInfos(station) {
    return {
      longitude: this.isSiteLayer
        ? station.site_longitude
        : station.station_longitude,
      latitude: this.isSiteLayer
        ? station.site_latitude
        : station.station_latitude,
      no: this.isSiteLayer ? station.site_no : station.station_no,
      name: this.isSiteLayer ? station.site_name : station.station_name,
    };
  }

  _processFeaturesWithLegend() {
    // why hide it not filter it ? because need keep it map for selection.
    const features = this.getSource().getFeatures();
    features.forEach(f => {
      const tag = f.station[this.tagProperty];
      if (tag && this._legends) {
        f.hide = tag.split('|').some(t => this._legends.indexOf(t) === -1);
      }
    });
  }

  _createStationFeatures(stations) {
    const ret = [];
    stations.forEach(station => {
      if (station.point) {
        const f = new Feature(new Point(station.point));
        const style = this._getStationStyle(
          station,
          this.selection.includes(station.station_id),
        );
        if (style) {
          f.setStyle(style);
        }
        f.station = station;
        ret.push(f);
      }
    });
    return ret;
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  _getStationStyle() {
    throw new Error('need implement');
  }

  createRenderer() {
    return new _CanvasVectorLayerRenderer(this);
  }
}
