import { Text, Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import { template, contrastColor } from '@kisters/wcp-base/common';
import StationsLayer from './StationsLayer';
import { FontIcon } from './FontIcon';

let currentStationStyle;

const defaultMarkerStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: '#0052cc',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 2,
    }),
  }),
});

export default class StationsMarkerLayer extends StationsLayer {
  set currentStation(val) {
    this.__currentStation = val;
    //  this.reload();
  }

  _getStationStyle(station, selected = false) {
    const dynamicMarkerSize = this.get('dynamicMarkerSize');
    currentStationStyle =
      currentStationStyle ||
      new Style({
        image: new FontIcon({
          backgroundColor: 'rgba(0,0,0,0)',
          color: 'red',
          icon: 'ki ki-map-marker',
        }),
        zIndex: 100,
      });

    const info = this._getStationInfos(station);
    if (
      this.__currentStation &&
      info.no === this._getStationInfos(this.__currentStation).no
    ) {
      return currentStationStyle;
    }

    const iconStyle =
      (this.tagMarkers && this.tagMarkers[station.__tag]) ||
      (this.tagMarkers && this.tagMarkers._default) ||
      defaultMarkerStyle;
    // iconStyle.setZIndex(this.getZIndex()+iconStyle.getZIndex())
    if (dynamicMarkerSize) {
      return (feature, resolution) => {
        iconStyle
          .getImage()
          .setScale(
            resolution > dynamicMarkerSize.maxResolution
              ? dynamicMarkerSize.size
              : 1,
          );
        return iconStyle;
      };
    }
    const image = iconStyle.getImage();
    if (image.getGlyph) {
      const glyph = image.getGlyph();
      if (glyph.iconTemplate) {
        let valueToDisplay = template(glyph.iconTemplate, station);
        if (
          glyph.iconTemplateDecimals !== undefined &&
          glyph.iconTemplateDecimals !== null
        ) {
          valueToDisplay = Number.parseFloat(valueToDisplay).toFixed(
            glyph.iconTemplateDecimals,
          );
        }
        return [
          iconStyle,
          new Style({
            zIndex: iconStyle.getZIndex() + 0.1,
            text: new Text({
              font: 'bold 10px Calibri, "sans-serif"',
              text: valueToDisplay,
              fill: new Fill({
                color: contrastColor(image.getFill().getColor(), true),
              }),
              padding: [1, 5, 0, 5],
              offsetX: 0,
              offsetY: 0,
            }),
          }),
        ];
      }
    }

    /** Keywords:  MultiSelection, MultiTs */
    if (selected) {
      return [
        iconStyle,
        new Style({
          zIndex: iconStyle.getZIndex() + 0.1,
          image: new CircleStyle({
            radius: iconStyle.getImage().getRadius(),
            fill: new Fill({
              color: 'rgba(0,0,0,0)',
            }),
            stroke: new Stroke({
              color: 'rgb(0, 0, 224)',
              width: 3,
            }),
          }),
        }),
      ];
    }
    return iconStyle;
  }

  set tagMarkers(val) {
    this.__tagMarkers = val;
    this.reload();
  }

  get tagMarkers() {
    return this.__tagMarkers;
  }
}
