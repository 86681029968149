/* eslint-disable*/
import { html, LitElement, render } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import initDatepicker from 'js-datepicker/dist/datepicker.min';
import { LoaderMixin, Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import style from './ki-time-range-picker.css?inline';
import { IColumnOption, PeriodsList } from './PeriodsList';
import nls from '../../locales/index';
import { getDefaultJsDatepickerOptions } from './js-datepicker-helper';

import { PeriodListElement } from './period-list';
import { MobileFromToTabs } from './mobile-from-to-tabs';

import './mobile-from-to-tabs';
import './period-list';

interface datepicker {
  show: () => void;
  hide: () => void;
  setDate: (date: Date, updateCalendar: boolean) => void;
  navigate: (date: Date) => void;
  getRange: () => {
    start: Date;
    end: Date;
  };
  calendarContainer: HTMLElement;
}

@customElement('ki-time-range-picker')
export class KiTimeRangePicker extends Mix(
  LitElement,
  [i18nMixin, { nls }],
  LoaderMixin,
) {
  // language=CSS
  static styles = style;

  @property({ type: Boolean })
  allowtimebox = false;

  @property({ type: Object })
  constraints = {};

  fromDate: datepicker | null = null;

  toDate: datepicker | null = null;

  @property({ type: Boolean })
  showPeriodList: boolean = false;

  _periodsListOptions: Array<Array<IColumnOption>> | null = null;

  @property({ type: Array })
  periods = [];

  @property({ type: String })
  dateFormat = 'DD/MM/YYYY';

  @property({ type: Number })
  initMin: number | null = null;

  @property({ type: Number })
  initMax: number | null = null;

  @property({ type: String, attribute: false })
  selectedPeriod = '';

  @property({ type: String, attribute: false })
  rangeSummaryValue = '';

  @property({ type: Boolean, attribute: false })
  datepickerOpen = false;

  _mobileFromToTabs: Array<MobileFromToTabs> = [];
  _periodLists: Array<PeriodListElement> = [];

  _createInputs() {
    const _t = this;
    const id = dayjs().toISOString();

    const options = getDefaultJsDatepickerOptions(
      id,
      this.i18n,
      (input, date) => {
        input.value = dayjs(date).format(_t.dateFormat);
      },
      () => {
        _t.datepickerOpen = false;
      },
    );

    const fromOptions = {
      ...options,
      onShow: () => {
        this._mobileFromToTabs.forEach(
          mobileTab => (mobileTab.selectedTab = 'from'),
        );
        _t.datepickerOpen = true;
      },
      onSelect: (instance: datepicker) => {
        _t.toDate?.show();
        _t._checkRangeAndEmitChanges(instance);
      },
    };

    const toOptions = {
      ...options,
      onShow: () => {
        this._mobileFromToTabs.forEach(
          mobileTab => (mobileTab.selectedTab = 'to'),
        );
        _t.datepickerOpen = true;
      },
      onSelect: (instance: datepicker) => {
        _t._checkRangeAndEmitChanges(instance);
      },
    };

    this.fromDate = initDatepicker(
      this.renderRoot.querySelector('#from'),
      fromOptions,
    );
    this.toDate = initDatepicker(
      this.renderRoot.querySelector('#to'),
      toOptions,
    );
  }

  _generatePeriodList(node: HTMLElement) {
    const periodList = html`
      <ww-period-list
        class="period-list"
        .options=${this._periodsListOptions}
        .selectedPeriod=${this.selectedPeriod}
        .constraints=${this.constraints}
        @period-selected=${(e: {
          detail: {
            value: string;
            from: number;
            to: number;
          };
        }) => {
          this._selectPeriod(e.detail.value);
          this.selectPeriod(e.detail.from, e.detail.to, true);
        }}
      ></ww-period-list>
    `;

    render(periodList, node, {
      renderBefore: node.childNodes[0],
    });

    const foundPeriodList = Array.from(node.childNodes.values()).find(
      child => child.nodeName === 'WW-PERIOD-LIST',
    );

    if (foundPeriodList) {
      this._periodLists.push(foundPeriodList as PeriodListElement);
    }
  }

  _createTabsForMobile(node: HTMLElement) {
    const mobileTabs = html`<ww-mobile-tabs
      class="mobile-tabs"
      @from-clicked=${() => {
        this.toDate?.hide();
        this.fromDate?.show();
      }}
      @to-clicked=${() => {
        this.fromDate?.hide();
        this.toDate?.show();
      }}
    ></ww-mobile-tabs>`;

    render(mobileTabs, node, {
      renderBefore: node.childNodes[0],
    });

    const foundMobileTab = Array.from(node.childNodes.values()).find(
      child => child.nodeName === 'WW-MOBILE-TABS',
    );

    if (foundMobileTab) {
      this._mobileFromToTabs.push(foundMobileTab as MobileFromToTabs);
    }
  }

  renderPeriodLists() {
    const _t = this;
    const periodsList = new PeriodsList({
      allowtime: this.allowtimebox,
      periods: this.periods,
    });
    this._periodsListOptions = periodsList.getPeriodsList();
    const nodes = this.renderRoot.querySelectorAll('.qs-datepicker-container');
    nodes.forEach((node: HTMLElement) => {
      _t._generatePeriodList(node);
      _t._createTabsForMobile(node);
    });
  }

  firstUpdated() {
    this._createInputs();
    this.renderPeriodLists();
    this._setFirstDates();
  }

  setMobileTabValues(from: number | null, to: number | null) {
    const fromValue = from !== null ? dayjs(from).format(this.dateFormat) : '';
    const toValue = to !== null ? dayjs(to).format(this.dateFormat) : '';

    this.rangeSummaryValue = `${fromValue} - ${toValue}`;

    this._mobileFromToTabs.forEach(mobileTab => {
      mobileTab.fromValue = fromValue;
      mobileTab.toValue = toValue;
    });
  }

  selectPeriod(from: number, to: number, emitChange: boolean) {
    this.fromDate?.setDate(new Date(from), false);
    this.toDate?.setDate(new Date(to), false);

    this._updateSelections(from, to);

    emitChange && this._emitDatesChanged(from, to);
  }

  _emitDatesChanged(from: number, to: number, ignoreHistory: boolean = false) {
    const auxFrom = dayjs(from).startOf('day');
    const auxTo = dayjs(to).endOf('day');
    this.dispatchEvent(
      new CustomEvent('changedate', {
        bubbles: true,
        detail: {
          fromTime: auxFrom,
          toTime: auxTo,
          ignoreHistory: ignoreHistory,
          fromTimeUnix: dayjs(auxFrom).valueOf(),
          toTimeUnix: dayjs(auxTo).valueOf(),
        },
      }),
    );
  }

  _updateSelections(from: number, to: number) {
    this._deselectPeriod();
    this.toDate?.navigate(new Date(to));
    this.fromDate?.navigate(new Date(from));
    this.setMobileTabValues(from, to);
    this._checkPeriodListSelection(from, to);
  }

  _selectPeriod(period: string) {
    this.selectedPeriod = period;

    this._periodLists.forEach(periodList => {
      periodList.selectedPeriod = this.selectedPeriod;
    });
  }

  _deselectPeriod() {
    this._selectPeriod('');
  }

  _checkPeriodListSelection(from: number, to: number) {
    const ONE_DAY_MS = 86400000;
    const isPeriodLess24H = to - from < ONE_DAY_MS;
    this._deselectPeriod();

    this._periodsListOptions?.forEach(column => {
      column.forEach(period => {
        const _periodStart = period.dates.from;
        const _periodEnd = period.dates.to;

        const _firstCheck = _periodStart === from && _periodEnd === to;

        const _additionalCheck = isPeriodLess24H
          ? false
          : dayjs(_periodStart).startOf('day').valueOf() === from &&
            dayjs(_periodEnd).endOf('day').valueOf() === to;

        if (_firstCheck || _additionalCheck) {
          this._selectPeriod(`from_${period.dates.from}_to_${period.dates.to}`);
        }
      });
    });
  }

  _setFirstDates() {
    if (!this.initMin || !this.initMax) {
      return;
    }

    this.selectPeriod(this.initMin, this.initMax, false);
  }

  render() {
    // language=html
    return html`
      <div class="input-group">
        <input id="from" type="text" />
        <input id="to" type="text" />
        <ki-icon-btn
          id="periodListBtn"
          class="${this.showPeriodList ? '' : 'display-none'}${this
            .datepickerOpen
            ? ' selected'
            : ''}"
          title="${this.i18n.t('selectTimePeriod')}"
          icon="ki ki-calendar"
          @click="${this.openFromCalendar}"
        ></ki-icon-btn>
      </div>
      <div
        id="periodListSummary"
        class="${this.datepickerOpen ? 'selected' : ''}"
        @click="${this.openFromCalendar}"
      >
        ${this.rangeSummaryValue}
        <ki-icon icon="ki ki-calendar"></ki-icon>
      </div>
    `;
  }

  _checkRangeAndEmitChanges(input: datepicker | null) {
    if (input === null) {
      return;
    }
    const range = input.getRange();
    const from = range.start.getTime();
    const to = range.end.getTime();
    this.setMobileTabValues(from, to);
    if (range && range.start && range.end) {
      this._deselectPeriod();
      this._emitDatesChanged(from, to);
    }
  }

  openFromCalendar(e) {
    e.stopPropagation();
    this.toDate?.hide();
    if (this.fromDate) {
      const isHidden =
        this.fromDate?.calendarContainer.classList.contains('qs-hidden');
      this.fromDate[isHidden ? 'show' : 'hide']();
    }
  }
}
