/* eslint-disable func-names */
// discussion: update when get or update when set,  update via getting is better for data logic
// why not apply to this,  pass this can reduce code size with t=>t.name  instead function(){return this.name}
// and void miss typing with ()=>this.name which is wrong
/*
  value be cached util props in watch fired.
  all watcher only check when updating element, so make it's called.
  example:
    class Demo {

      @computed([t => t.lastName, t => t.firstName,])
      get fullName() {
        return `${this.firstName} ${this.lastName}`;
      }

      @computed([t => t.fullName, t => t.classRoom])
      get info() {
        return  `${this.classRoom } ${this.fullName}`;;
      }

      @autoRun([t => t.fullName])
      fetchInfo() {
        return fetch('http://server.com/user?fullName=${this.fullName}').then((u)=>{console.log(u)})
      }

      // async
      @computed('fullName' , { default: {a:1} })
      get asyncData(){
        return fetch('http://server.com/user?fullName=${this.fullName}')
      }


    }


*/

import { isEqual } from 'lodash-es';
import getPropertyDescriptor from './helper/getPropertyDescriptor';

const getterChain: Array<string> = [];

let computeUUID = 0;

export function _preProcessWatchers(watchers) {
  if (!watchers) {
    throw new Error('must set watchers');
  }

  if (!Array.isArray(watchers)) {
    watchers = [watchers];
  }
  return watchers.map(w => {
    if (typeof w === 'string') {
      return function getter(v) {
        return v[w];
      };
    }
    return w;
  });
}

const getComputedGetter = (
  originGetter,
  key,
  watchers,
  options,
  id,
  target,
  targetname,
) =>
  function getter() {
    const fnName = `${id}_${targetname}.${key}`;

    if (getterChain.indexOf(fnName) >= 0) {
      console.debug(getterChain);
      throw new Error(`recursive getter chain with :${fnName}`);
    }
    getterChain.push(fnName);
    // console.debug(getterChain);
    try {
      const cacheKey = `__cached_${key}`;
      const cacheParamsKey = `__cached_params_${key}`;

      const values = watchers.map(w => w(target));
      // simple equal;
      if (
        !target[cacheParamsKey] ||
        !values.every((v, i) => isEqual(v, target[cacheParamsKey][i]))
      ) {
        // if watcher changes
        target[cacheParamsKey] = values;
        // recall

        const re = originGetter.call(target);

        if (re && re.then) {
          const q = re.then(v => {
            target[cacheKey] = v;
          });
          // async
          // need have loader mixin,so can call request update
          if (target.promiseLoader) {
            // avoid update loader in same loop
            setImmediate(() => {
              target.promiseLoader(q);
            });
          } else {
            q.then(() => target.requestUpdate());
          }
          if (target[cacheKey] === undefined) {
            target[cacheKey] = options.default;
          }
        } else {
          target[cacheKey] = re;
        }
      }
      getterChain.pop();
      return target[cacheKey];
    } catch (e) {
      getterChain.pop();
      console.error(e);
      throw e;
    }
  };

export const computed = function (watchers, options = {}) {
  watchers = _preProcessWatchers(watchers);
  const id = computeUUID;
  computeUUID += 1;

  return function decorator(target, key, descriptor) {
    if (descriptor.get) {
      // for getter
      const originGetter = descriptor.get;
      descriptor.get = getComputedGetter(
        originGetter,
        key,
        watchers,
        options,
        id,
        target,
        target.constructor.name,
      );
    }

    return descriptor;
  };
};

export const computedMixin = function (
  targetProperty,
  watchers,
  // eslint-disable-next-line default-param-last
  options = {},
  clz,
) {
  watchers = _preProcessWatchers(watchers);
  const id = computeUUID;
  computeUUID += 1;
  return class extends clz {
    constructor() {
      super();

      const [proto, targetDescriptor] = getPropertyDescriptor(
        this,
        targetProperty,
      );

      const wrapperFn = getComputedGetter(
        targetDescriptor.get,
        targetProperty,
        watchers,
        options,
        id,
        this,
        proto.constructor.name,
      );
      Object.defineProperty(this, targetProperty, {
        get: wrapperFn,
        configurable: true,
      });
    }
  };
};
