/* eslint-disable default-param-last */
/* eslint-disable max-classes-per-file */
import { html } from 'lit';
/* eslint-disable-next-line import/extensions */
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import dayjs from '../../common/dayjsext';
import { template } from '../../common/util';
import { getFromItem } from './GetField';

// TODO temp
//  https://jira-development.kisters.de/browse/WEBCP-48
//  https://jira-development.kisters.de/browse/WEBCP-65
// const numberFormatter = new Intl.NumberFormat();

export default function ColumnFormatter(clz) {
  return class extends clz {
    // build in formatters , also can extend with subclass.
    static formatters = {
      // TODO remove cell wrapper, after find proper way to style columns?
      dateTime(val, opt = { options: { dayjsFormat: 'L LT' } }, col) {
        const dayjsFormat = opt?.options?.dayjsFormat || 'L LT';
        return html`
          <div class="cell" style="${col.css || ''}">
            ${val ? dayjs(val).tz().format(dayjsFormat) : ''}
          </div>
        `;
      },

      html(val, _opt, col) {
        return html`
          <div class="cell" style="${col.css || ''}">
            ${val ? unsafeHTML(val.replace('./html', './public/html')) : ''}
          </div>
        `;
      },
      date(val, opt: { css: string | undefined } = { css: undefined }) {
        return html`
          <div style="${opt.css || ''}" class="cell date">
            ${val ? dayjs(val).tz().format('L') : ''}
          </div>
        `;
      },
      wiskinumber(
        val,
        opt: {
          options: any;
          locale: string | undefined;
        } = { options: undefined, locale: undefined },
        col,
        item,
      ) {
        const precisiontable = {
          'Deci,0,0,0': { minimumFractionDigits: 0, maximumFractionDigits: 0 },
          'Deci,1,0,0': { minimumFractionDigits: 1, maximumFractionDigits: 1 },
          'Deci,2,0,0': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
          'Deci,3,0,0': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
        };
        const precision = item.ts_precision || 'Deci,2,0,0';
        const options: any = precisiontable[precision] || opt.options;

        if (typeof val === 'string') {
          val = val.replace(',', '.').replace(/[^\d.-]/g, '');
        }

        const numberFormatter = new Intl.NumberFormat(opt.locale || 'en', {
          ...options,
        });
        const formatted = numberFormatter.format(val);
        return html`
          <div style="${col.css || ''}" class="cell number">
            ${col.prefix ? template(col.prefix, item) : ''}
            ${val === undefined ||
            val === null ||
            val === '' ||
            Number.isNaN(val)
              ? ''
              : formatted}
            ${val && col.suffix ? template(col.suffix, item) : ''}
          </div>
        `;
      },
      number(
        val,
        opt: {
          options: any;
          locale: string | undefined;
        } = { options: undefined, locale: undefined },
        col,
        item,
      ) {
        const numberFormatter = new Intl.NumberFormat(opt.locale || 'en', {
          ...opt.options,
        });
        const formatted = val === 0 ? val : numberFormatter.format(val);
        return html`
          <div style="${col.css || ''}" class="cell number">
            ${col.prefix ? template(col.prefix, item) : ''}
            ${val === undefined ||
            val === null ||
            val === '' ||
            Number.isNaN(val)
              ? ''
              : formatted}
            ${val && col.suffix ? template(col.suffix, item) : ''}
          </div>
        `;
      },
      textnumber(
        val,
        opt: {
          options: any;
          locale: string | undefined;
        } = { options: undefined, locale: undefined },
        col,
        item,
      ) {
        val = parseFloat(val && val.toString().replace(',', '.'));
        const numberFormatter = new Intl.NumberFormat(opt.locale || 'en', {
          ...opt.options,
        });
        const formatted = val === 0 ? val : numberFormatter.format(val);
        return html`
          <div style="${col.css || ''}" class="cell number">
            ${col.prefix ? template(col.prefix, item) : ''}
            ${val === undefined ||
            val === null ||
            val === '' ||
            Number.isNaN(val)
              ? ''
              : formatted}
            ${val && col.suffix ? template(col.suffix, item) : ''}
          </div>
        `;
      },
    };

    static get properties() {
      return {
        formatters: { type: Object },
      };
    }

    get renderingColumns() {
      // @ts-expect-error
      const formatters = { ...this.constructor.formatters, ...this.formatters };
      return super.renderingColumns?.map(c => {
        const col = { ...c };
        if (!col.renderCell) {
          if (col.format) {
            let { format } = col;
            if (typeof format === 'string') {
              format = {
                type: format,
              };
            }
            const formatter = formatters[format.type];
            if (formatter) {
              col.renderCell = item =>
                (Array.isArray(col.field) ? col.field : [col.field]).map(
                  field =>
                    formatter(
                      getFromItem(item, field),
                      { ...format, ...{ locale: this.i18n.language } },
                      col,
                      item,
                    ),
                );
            }
          }
        }
        return col;
      });
    }
  };
}
