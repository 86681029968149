/* eslint-disable max-classes-per-file */
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { WwpStationDownload } from '@kisters/wiski-web/views';

@customElement('wwp-station-download-albg')
export default class WwpStationDownloadAlbg extends Mix(WwpStationDownload) {
  csvSeparator = ',';

  dataColumnLabels = ['Time', 'Depth', 'WL Elevation', 'Status'];

  dataColumnLabelsAggr = [
    'Day',
    'Daily Minimum',
    'Daily Mean',
    'Daily Maximum',
    'Status',
  ];

  _getData(data, minDate) {
    const cols = data[0].columns.split(',');

    const absoluteValIndex = cols.indexOf('Absolute Value');
    const releaseStatusIndex = cols.indexOf('Release State');
    const qualityCodeNameIndex = cols.indexOf('Quality Code');
    const rows = [];
    console.time('start download parsing');
    const tzoffset =
      (dayjs().utcOffset() - dayjs().tz().utcOffset()) * 60 * 1000;
    data[0].data.forEach(item => {
      const datems = new Date(item[0]).getTime() - tzoffset;
      if (datems > minDate) {
        let value;
        if (this.albg_offset_timeseries?.includes(data[0].ts_shortname)) {
          value =
            item[absoluteValIndex] === null
              ? ''
              : Math.abs(
                  item[absoluteValIndex] - this.station[this.albg_offset_value],
                ).toFixed(2);
        } else {
          value = item[1] === null ? '' : item[1].toFixed(2);
        }
        const row = [dayjs(datems).format('L LT'), value];

        const absVal =
          item[absoluteValIndex] === null
            ? ''
            : item[absoluteValIndex].toFixed(2);
        row.push(absVal);

        if (releaseStatusIndex !== -1) {
          const releaseState =
            this.releaseStates[item[releaseStatusIndex]] ||
            this.releaseStates.default;
          row.push(releaseState);
        }

        if (qualityCodeNameIndex !== -1) {
          row.push(item[qualityCodeNameIndex]);
        }
        rows.push(row);
      }
    });
    console.timeEnd('start download parsing');
    return rows;
  }
}
