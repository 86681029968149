import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import nls from '../../locales/index';

@customElement('ww-period-list')
export class PeriodListElement extends i18nMixin(LitElement, { nls }) {
  static styles = [
    css`
      :host {
        flex: 1;
        display: flex;
        flex-direction: row;
      }

      .popover-column {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0.5em 0;
      }

      .popover-button {
        display: flex;
        font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial,
          sans-serif;
        cursor: pointer;
        padding: 0.5em 0.6em;
      }

      .popover-button.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }

      .popover-button .check-icon {
        display: flex;
        align-items: center;
        width: 25px;
        flex-shrink: 0;
      }

      .popover-button.selected,
      .popover-button:hover {
        fill: #ffffff;
      }

      .popover-button.selected,
      .popover-button:hover {
        fill: #1093cd;
      }
      @media (max-width: 1024px) {
        .popover-column {
          display: flex;
          position: absolute;
          background: white;
          z-index: 999;
          height: 100%;
          width: 100%;
        }

        .popover-column.hide-mobile {
          display: none;
        }
      }
    `,
  ];

  @property({ type: Array })
  options: Array<
    Array<{
      label: string | null;
      dates: {
        from: number;
        to: number;
      } | null;
    }>
  > = [];

  @property({ type: String })
  selectedPeriod = '';

  @property({ type: Object })
  constraints: {
    min: number | string;
    max: number | string;
  } | null = null;

  selectPeriod(value: string, from: number, to: number) {
    this.selectedPeriod = value;
    this.dispatchEvent(
      new CustomEvent('period-selected', {
        detail: {
          value,
          from,
          to,
        },
      }),
    );
  }

  protected render(): Array<TemplateResult> {
    return this.options.map(
      columns => html`
        <div class="popover-column">
          ${columns.map(column => {
            let optFrom: number = column.dates
              ? column.dates.from
              : dayjs().valueOf();
            let optTo: number = column.dates
              ? column.dates.to
              : dayjs().valueOf();
            const value = `from_${optFrom}_to_${optTo}`;

            const isSelected = this.selectedPeriod === value;

            if (column.label === 'periodOfRecord') {
              if (
                this.constraints &&
                this.constraints.min &&
                this.constraints.max
              ) {
                optFrom = dayjs(this.constraints.min).valueOf();
                optTo = dayjs(this.constraints.max).valueOf();
              }
            }

            return html`<span
              class="popover-button${isSelected ? ' selected' : ''}"
              .data-value=${value}
              @click=${() => {
                this.selectPeriod(value, optFrom, optTo);
              }}
            >
              <span class="check-icon">
                ${isSelected
                  ? html`<ki-icon icon="ki ki-check"></ki-icon>`
                  : ''} </span
              ><span
                >${this.i18n.t(column.label) ||
                column.label ||
                column ||
                ''}</span
              >
            </span>`;
          })}
        </div>
      `,
    );
  }
}
