import {
  has,
  get,
  find,
  filter,
  sortBy,
  merge,
  keyBy,
  groupBy,
  maxBy,
  values,
  first,
} from 'lodash-es';
import { Dictionary } from 'lodash';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { TsDataResponse, TsDataPoint } from '../common/timeseries';
import { Layer, RequestLayersResponse } from '../common/layers';
// api for file generator

const defaultLayer = {
  tablePopup: true,
  popupControl: true,
  popup: 'ww-map-popup-dynamic',
  filter: [],
  classification: {
    default: {
      marker: {
        backgroundColor: 'rgb(7, 97, 199)',
        shape: 'circle',
        color: 'rgba(255,255,255, 1)',
        icon: '',
        fontSize: 0.8,
        radius: 8,
        border: {
          color: 'rgba(29,29,27, 1)',
          width: 1,
        },
      },
    },
    tags: [
      {
        name: 'wert',
        label: 'aktuelle Werte',
        filter: {
          timestamp: {
            duration: 'PT48H',
          },
        },
        marker: {
          backgroundColor: 'rgb(7, 97, 199)',
          shape: 'circle',
        },
      },
      {
        name: 'keinwert',
        priority: 1,
        label: 'keine aktuellen Werte',
        filter: {
          timestamp: {
            not: {
              duration: 'PT48H',
            },
          },
        },
        marker: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
        },
      },
    ],
  },
  sortedBy: [{ field: 'station_longname', ascending: true }],
  columns: [
    {
      css: 'width:95px;',
      field: '__tag',
      label: 'Status',
      format: 'status',
      sortable: true,
    },
    {
      field: 'station_name',
      label: 'Station',
      sortable: true,
    },

    {
      field: 'timestamp',
      label: 'Zeitpunkt [MEZ]',
      format: 'dateTime',
      sortable: true,
      hideAble: true,
    },
    {
      field: 'ts_value',
      label: 'Wert',
      sortable: true,
      hideAble: true,
      format: {
        type: 'number',
        options: {
          minimumFractionDigits: 2,
        },
      },
    },
  ],
};

export class API {
  reportsPath: string;

  basePath: string;

  configSubPath: string;

  configPath: string;

  staticConfigPath: string;

  dataPath: string;

  reportList: any;

  _cache: any;

  imagesList: any;

  constructor({
    basePath,
    dataPath,
    configSubPath,
    reportsPath,
    staticConfigPath,
  }) {
    this.reportsPath = reportsPath || null;
    this.basePath = basePath || '.';
    this.configSubPath = configSubPath || 'config';
    this.staticConfigPath = staticConfigPath || 'static_config';
    this.configPath = `${this.basePath}/${this.configSubPath}`;
    this.dataPath = dataPath || './data';
    this._cache = new Map();
    this._requestcache = new Map();
    this.reportList = null;
    this.imagesList = null;
  }

  async getClassification(name) {
    const classes = await this.getClassifications();
    return find(classes, { name });
  }

  async getClassifications() {
    return this._requestJson('/internet/raster/classifications.json');
  }

  async getMapConfig() {
    return this._requestJson('/map.json', this.configPath);
  }

  async getSites() {
    return this._requestJson('/stations/sites.json');
  }

  async getStations(query = {}) {
    const stations = await this._requestJson(
      '/internet/stations/stations.json',
    );
    return filter(stations, query);
  }

  /**
   * Get external/static stations from file
   *
   * @param filename - Path to file in public - directory containing local stations, e.g. 'projects.json' or 'waterlevel.json'
   * @param query - Filter query on found static stations; See lodash filter method
   *
   * @returns Array of External/static Stations from a provided JSON File, optionally to be filterred by query
   *
   * @remarks
   * This function is also used in ww-worldmap to fetch the provided projects, handled as stations
   */
  async getExternalStations(filename, query = {}): Promise<Object[]> {
    const stations = await this._requestJson(filename, './');
    return filter(stations, query);
  }

  async getStationIndex(site_no, station_no) {
    const station = await this._requestJson(
      `/internet/stations/${site_no}/${station_no}/index.json`,
    );
    return station;
  }

  async getRasterList() {
    const rasterlist = await this._requestJson('/internet/raster/index.json');
    return rasterlist._links.filter(item => item.type === 'resource');
  }

  async getRessource(ahref, base) {
    return this._requestJson(ahref, base);
  }

  async getStationByNo(stationNo) {
    const station = await this.getStations({ station_no: stationNo })[0];
    return station;
  }

  async getStation(stationId: string): Promise<any | null> {
    let siteNo = '';
    let stationNo = '';
    if (siteNo.length === 0 || stationNo.length === 0) {
      const station = (await this.getStations({ station_id: stationId }))[0];
      siteNo = station?.site_no ?? '';
      stationNo = station?.station_no ?? '';
    }

    if (siteNo.length === 0 || stationNo.length === 0) {
      return null;
    }

    const data = await this._requestJson(
      `/internet/stations/${[siteNo, stationNo].join('/')}/index.json`,
    );
    const reports = await this.getReport(stationNo);
    return {
      ...data,
      reports,
      zips: sortBy(
        filter(
          data._links,
          s =>
            s.mime_type === 'application/zip' ||
            s.mime_type === 'application/msexcel' ||
            s.mime_type === 'text/comma-separated-values',
        ),
        'station_parameter',
      ),
      downloads: sortBy(
        filter(data._links, s => s.mime_type === 'application/msexcel'),
        'station_parameter',
      ),
      timeseries: sortBy(
        filter(
          data._links,
          s => s.station_parameter && s.mime_type === 'application/json',
        ),
        'station_parameter',
      ),
    };
  }

  // todo tsName for file name ?
  async getTs({ siteNo, stationNo, parameterName, tsName }) {
    return this._requestJson(
      `/internet/stations/${[siteNo, stationNo, parameterName, tsName].join(
        '/',
      )}.json`,
    );
  }

  getLink(ahref: string): string {
    return this.dataPath + ahref;
  }

  // eslint-disable-next-line class-methods-use-this
  _transformLayerData(data: never[], config) {
    if (!config) console.warn('No layer config provided!');
    data = data.map(item => {
      Object.keys(item).forEach(key => {
        if (key.startsWith('metadata_')) {
          item[key.replace('metadata_', '')] = item[key];
          delete item[key];
        }
      });
      return item;
    });
    const stations = groupBy(data, 'station_id');

    const ret = [];
    for (const [, station] of Object.entries(stations)) {
      const stobj = {
        mainParameter: config?.mainParameter,
        ts_values: {},
        ...station[0],
      };
      station.forEach(entry => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in entry) {
          if (Object.prototype.hasOwnProperty.call(entry, key)) {
            const value = entry[key];
            const param = entry.stationparameter_name;
            /** Multi-value icons/piecharts in map (e.g. LANUV Geberzeitreihen) */
            if (config?.tsreplace) {
              config.tsreplace.forEach(token => {
                entry.ts_shortname = entry.ts_shortname.replace(token, '');
              });
            }
            if (config?.multiTs) {
              const cleanShortName = entry.ts_shortname.replace(/\./g, '');
              if (!stobj.ts_values[param]) {
                stobj.ts_values[param] = {};
              }
              if (!stobj.ts_values[param][cleanShortName]) {
                stobj.ts_values[param][cleanShortName] = {};
              }

              if (
                key.includes('tsinfo_') ||
                key.includes('ts_') ||
                key.includes('param') ||
                key.includes('timestamp')
              ) {
                stobj.ts_values[param][cleanShortName][key] = value;
              } else if (key !== 'ts_values') {
                stobj[key] = value;
              }
            } else {
              if (!stobj.ts_values[param]) {
                stobj.ts_values[param] = {};
              }
              if (
                key.startsWith('tsinfo_') ||
                key.startsWith('ts_') ||
                key.includes('param') ||
                key.startsWith('timestamp')
              ) {
                stobj.ts_values[param][key] = value;
              } else {
                stobj[key] = value;
              }
            }
          }
        }
      });
      ret.push(stobj);
    }
    // console.timeEnd('transform');
    // console.log(ret);
    return ret;
  }

  async getTsData(ahref: string, nocache = true) {
    return this._requestJson(`/${ahref}`, this.dataPath, nocache);
  }

  async getLayers(): Promise<Dictionary<Layer>> {
    const response: RequestLayersResponse = await this._requestJson(
      '/internet/layers/index.json',
      undefined,
      true,
    );

    response._links.push({
      type: 'resource',
      layer_type: 'STATION_LAYER',
      alias: 'stations',
      id: 'stations',
    } as Layer);
    const config = await this._requestJson('/layercfg.json', this.configPath);
    if (!response || !config) {
      throw Error('error loading config or data');
    }
    response._links.forEach(item => {
      item.creationDateInMillis = response.creationDateInMillis;
    });
    return merge(
      keyBy(filter(response._links, { type: 'resource' }), 'alias'),
      keyBy(config, 'alias'),
    );
  }

  async getLayer(alias): Promise<TsDataResponse> {
    const l = filter(await this.getLayers(), { alias })[0];
    if (!l.config) {
      l.config = { ...defaultLayer };
      l.config.id = l.id;
      l.config.alias = l.alias;
      l.config.classification.label = l.alias;
      l.config.columns = l.config.columns.map(item => {
        if (item.field === 'ts_value') {
          item.label = `${l.label}`;
        }
        return item;
      });
    }
    if (l?.id) {
      let data = await this.getLayerData(l);

      if (l.config.parameterFilterAttribute) {
        try {
          data.forEach(station => {
            if (station[l.config.parameterFilterAttribute]) {
              const parFilter = JSON.parse(
                station[l.config.parameterFilterAttribute],
              );
              if (Array.isArray(parFilter) && parFilter.length !== 0) {
                Object.keys(station.ts_values).forEach(par => {
                  if (!parFilter.includes(par)) {
                    delete station.ts_values[par];
                  }
                });
              }
            }
          });
          data = data.filter(item => Object.keys(item.ts_values).length);
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }

      if (l.config.stationsFilter) {
        data = data.filter(station =>
          Object.keys(l.config.stationsFilter).every(key => {
            const condition = l.config.stationsFilter[key];
            if (condition === '*') {
              return has(station, key);
            }
            if (condition.startsWith('!')) {
              return (
                get(station, key) !== l.config.stationsFilter[key].substr(1)
              );
            }
            return get(station, key) === l.config.stationsFilter[key];
          }),
        );
      }
      return {
        data,
        config: l.config,
        creationDateInMillis: l.creationDateInMillis,
      } as TsDataResponse;
    }

    throw new Error(`layer ${alias} does not exist`);
  }

  async getLayerData(l: Layer): Promise<TsDataPoint[]> {
    if (l.layer_type === 'TS_VALUES') {
      return this.getTsValuesLayerData(l);
    }
    if (l.layer_type === 'STATION_LAYER') {
      return this.getStationLayerData(l);
    }
    if (l.layer_type === 'MULTI_LAYER') {
      return this.getMultiLayerData(l);
    }
    if (l.layer_type === 'TS_MAX_VALUES') {
      return this.getMaxLayerData(l);
    }
    return this.getDefaultLayerData(l);
  }

  async getMaxLayerData(l: Layer): Promise<TsDataPoint[]> {
    const ids = typeof l.id === 'string' ? [l.id] : l.id;
    let resp = [];
    await Promise.all(
      ids.map(async id => {
        try {
          const data = await this._requestJson(
            `/internet/layers/${id}/index.json`,
            this.dataPath,
            true,
          );
          resp = resp.concat(data);
        } catch {
          console.warn(
            `error loading data for layer ${l.alias}, dataId: ${id}`,
          );
        }
      }),
    );
    if (!l.parameter) {
      console.error('no parameter provided for comparison');
      return [];
    }

    const ret = Object.values(groupBy(resp, 'station_id')).map(item =>
      item.reduce((prev, current) =>
        prev.ts_values[l.parameter]?.ts_value >
        current.ts_values[l.parameter]?.ts_value
          ? prev
          : current,
      ),
    );
    return ret;
  }

  async getDefaultLayerData(l: Layer): Promise<TsDataPoint[]> {
    const ids = typeof l.id === 'string' ? [l.id] : l.id;
    let resp = [];
    await Promise.all(
      ids.map(async id => {
        try {
          let data = await this._requestJson(
            `/internet/layers/${id}/index.json`,
            this.dataPath,
            true,
          );
          if (data[0]?.metadata_station_id) {
            data = data.map(item => {
              Object.keys(item).forEach(key => {
                if (key.startsWith('metadata_')) {
                  item[key.replace('metadata_', '')] = item[key];
                  delete item[key];
                }
              });
              return item;
            });
          }
          resp = resp.concat(data);
        } catch {
          console.warn(
            `error loading data for layer ${l.alias}, dataId: ${id}`,
          );
        }
      }),
    );

    if (l?.config?.additionalData) {
      const additionaldata = await this._requestJson(
        l?.config?.additionalData,
        '.',
      );
      resp = values(
        merge(keyBy(resp, 'station_id'), keyBy(additionaldata, 'station_id')),
      ).filter(item => item.station_name);
    }

    if (l?.config?.external) {
      const externalData = await this.getExternalStations(l.config.external);
      resp = resp.concat(
        externalData.map((item, index) => ({
          ...item,
          external: true,
          station_id: item.station_id ? item.station_id : `ext_${index}`,
        })),
      );
    }

    if (l?.config?.timeActive) {
      resp = resp.map(station => {
        Object.values(station.ts_values).forEach(value => {
          if (station.ts_values[value.stationparameter_name]?.high_tides) {
            station.ts_values[value.stationparameter_name].historic =
              station.ts_values[value.stationparameter_name]?.high_tides;
          }
          const datattr =
            station.ts_values[value.stationparameter_name]?.historic;
          if (datattr) {
            datattr.data = datattr.data.reduce((map, obj) => {
              map[new Date(obj[0]).getTime()] = obj;
              return map;
            }, {});
          }
        });
        return station;
      });
    }

    return !resp[0]?.ts_values
      ? this._transformLayerData(resp, l.config)
      : Object.values(groupBy(resp, 'station_id')).map(item => {
          const ret = Object.assign({}, ...item);
          Object.values(ret.ts_values).forEach(val => {
            val.time = dayjs(val.timestamp).format('LT');
          });
          return ret;
        });
  }

  async getMultiLayerData(l: Layer): Promise<TsDataPoint[]> {
    const data = await this._requestJson(
      `/internet/layers/${l.id}/index.json`,
      this.dataPath,
      true,
    );
    return data.map(item => {
      Object.keys(item).forEach(key => {
        if (key.startsWith('metadata_')) {
          item[key.replace('metadata_', '')] = item[key];
          delete item[key];
        }
        if (key.startsWith('L1_')) {
          item[key.replace('L1_', '')] = item[key];
          delete item[key];
        }
      });
      return item;
    });
  }

  async getStationLayerData(l: Layer): Promise<TsDataPoint[]> {
    const stations = await this._requestJson(
      `/internet/stations/stations.json`,
    );
    // Filter on station level
    if (l.filter) {
      // eslint-disable-next-line consistent-return
      l.filter.forEach(f => {
        const prop = first(Object.keys(f));
        const filteredstations = stations.filter(stat =>
          stat[prop].includes(f[prop]),
        );
        // Return filtered remaining stations; Return all otherwise
        if (filteredstations.length > 0) return filteredstations;
        console.warn(
          'Station filter did not match - return all stations instead',
        );
      });
    }
    return stations;
  }

  async getTsValuesLayerData(l: Layer): Promise<TsDataPoint[]> {
    let dataLayer = await this._requestJson(
      `/internet/layers/${l.id}/index.dajson`,
      this.dataPath,
      true,
    );
    if (l?.config?.additionalData) {
      const additionaldata = await this._requestJson(
        l?.config?.additionalData,
        '.',
      );
      dataLayer = values(
        merge(
          keyBy(dataLayer, 'station_id'),
          keyBy(additionaldata, 'station_id'),
        ),
      ).filter(item => item.station_name);
    }
    dataLayer.forEach(item => {
      const maxVal = maxBy(item.data, 1);
      item.timestamp = maxVal?.[0];
      item.ts_value = maxVal?.[1];
      item.classification = maxVal?.[2];
    });
    return dataLayer;
  }

  async getImages() {
    if (this.imagesList) {
      return this.imagesList;
    }

    const list = await this._requestJson('/images/index.json');
    this.imagesList =
      list && list.all_listing
        ? list.all_listing.filter(st => st.mimeType.includes('image/'))
        : [];
    return this.imagesList;
  }

  async getReport(stationNo) {
    const list = await this.getReports();
    return list[stationNo];
  }

  async getReports() {
    if (!this.reportsPath) {
      return [];
    }
    if (this.reportList) {
      return this.reportList;
    }
    const list = await this._requestJson(`/${this.reportsPath}/index.json`);
    this.reportList =
      list && list.all_listing
        ? groupBy(
            list.all_listing.map(st => {
              const [, stationNo, reportGroup, fileName] = st.name.split('/');
              return {
                path: st.name,
                stationNo,
                reportGroup,
                fileName,
                mimeType: st.mimeType,
              };
            }),
            'stationNo',
          )
        : {};
    return this.reportList;
  }

  async getImage(stationNo) {
    const list = await this.getImages();
    const images = list.filter(st => st.name.includes(`/${stationNo}/`));
    if (images.length) {
      return images.map(img => `${this.dataPath}/${img.name}`);
    }
    return [];
  }

  async getReportInfo() {
    // TODO need better file structure.
    return this._requestJson(`/documents/index.json`, undefined, true); // todo
  }

  async requestText(path, base = this.dataPath) {
    return fetch(`${base}${path}`).then(x => x.text());
  }

  /* eslint-disable class-methods-use-this */
  /* eslint-disable no-unused-vars */

  /*
   * cache all request;
   * */
  // eslint-disable-next-line default-param-last
  async _requestJson(path, base = this.dataPath, nocache?) {
    let data;
    if (!nocache && this._requestcache.get(path)) {
      const cachedrequest = await this._requestcache.get(path);
      const cachedrequestdata = await cachedrequest.clone().json();
      return cachedrequestdata;
    }

    if (!nocache && this._cache.get(path)) {
      return this._cache.get(path);
    }
    try {
      const doFetch = fetch(`${base}${path}`);
      this._requestcache.set(path, doFetch);
      const resp = await doFetch;
      data = await resp.clone().json();
      this._cache.set(path, data);
      this._requestcache.delete(path);
    } catch (err) {
      // catches errors both in fetch and response.json
      throw path;
    }

    return data;
  }
}
