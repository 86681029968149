// view stack
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import Mix from '../../common/Mix';
import PropertyDefaultValue from '../../common/PropertyDefaultValue';

import './ki-link';

// @ts-expect-error
@customElement('ki-breadcrumbs')
export default class KiBreadcrumbs extends Mix(
  LitElement,
  PropertyDefaultValue,
) {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      padding: var(--ki-breadcrumbs-padding, 0px);
      background: var(--ki-breadcrumbs-background, white);
      border-bottom: var(
        --ki-breadcrumbs-border-bottom,
        var(--ki-breadcrumbs-border, none)
      );
      border-top: var(
        --ki-breadcrumbs-border-top,
        var(--ki-breadcrumbs-border, none)
      );
      border-radius: var(--ki-breadcrumbs-border-radius, 0px);
      box-shadow: var(--ki-breadcrumbs-box-shadow, none);
      font-size: var(--ki-breadcrumbs-font-size, 0.8em);
      line-height: var(--ki-breadcrumbs-line-height, 16px);
      white-space: var(--ki-breadcrumbs-white-space, nowrap);
      overflow: auto hidden;
    }

    .breadcrumb {
      display: inline-flex;
      position: relative;
    }
    .breadcrumb:hover {
      cursor: pointer;
    }
    .breadcrumb .label {
      padding: var(--ki-breadcrumbs-label-padding, 8px);
      display: inline-block;
      color: var(--ki-breadcrumbs-textcolor, black);
    }
    .breadcrumb:hover .label {
      background: var(--theme-color-secondary, white);
      color: var(
        --ki-breadcrumbs-textcolor-hover,
        --ki-breadcrumbs-textcolor,
        darkgray
      );
    }

    .breadcrumb::after {
      content: '\\f018';
      display: inline-block;
      text-align: center;
      font-family: Kisterswater;
      font-size: 16px;
      font-stretch: 100%;
      font-style: normal;
      font-weight: 400;
      text-rendering: auto;
      align-self: center;
      width: var(--ki-breadcrumbs-after-width, 24px);
      -webkit-font-smoothing: antialiased;
      color: var(--ki-breadcrumbs-textcolor, black);
      transform: var(--ki-breadcrumbs-after-transform, none);
    }

    .last::after {
      content: unset !important;
    }

    @media only screen and (min-width: 800px) {
      .breadcrumb.last .label {
        pointer-events: none;
      }
    }

    .breadcrumb.last .label {
      color: var(--ki-breadcrumbs-last-textcolor, black);
    }
  `;

  static get properties() {
    return {
      items: { type: Array, initial: () => [] },
    };
  }

  render() {
    // language=html
    const l = this.items.length;
    return this.items.map(
      (item, i: number) =>
        // Detect last item and do not render arrow
        // Required for Firefox as CSS-Selector solution did not work in Shadow-DOM
        html`<div class="breadcrumb ${i + 1 >= l ? `last` : ``}">
          <ki-link
            class="label"
            tabindex="0"
            .href="${item.path}"
            .label="${item.label}"
          ></ki-link>
        </div>`,
    );
  }
}
